import { FirestoreProductSubject } from "__generated__/models";
import { qualifierTypes, subjectsTypes } from "./constants";
import { SubjectSchemeIdentifier } from "./types";

export const filterQualifiers = (qualifiers: FirestoreProductSubject[]) => {
  return qualifiers.filter((s) =>
    qualifierTypes.includes(
      s.subjectSchemeIdentifier as SubjectSchemeIdentifier,
    ),
  );
};

export const filterSubjects = (subjects: FirestoreProductSubject[]) => {
  return subjects.filter((s) =>
    subjectsTypes.includes(
      s.subjectSchemeIdentifier as SubjectSchemeIdentifier,
    ),
  );
};
