import * as React from "react";

import { cn } from "utils";

const Grid = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      "flex flex-col w-full border border-secondary-200 rounded-xl overflow-hidden",
      className,
    )}
    ref={ref}
    {...props}
  />
));
Grid.displayName = "Grid";

const GridHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex items-center justify-between h-12 px-4 bg-secondary-50",
      className,
    )}
    {...props}
  />
));
GridHeader.displayName = "GridHeader";

const GridTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ ...props }, ref) => (
  <p className="font-semibold text-s text-secondary-600" ref={ref} {...props} />
));
GridTitle.displayName = "GridTitle";

const GridButtonContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn("flex items-center space-x-2", className)}
    ref={ref}
    {...props}
  />
));
GridButtonContainer.displayName = "GridButtonContainer";
const GridBody = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div className={cn("", className)} ref={ref} {...props} />
));
GridBody.displayName = "GridBody";

const GridContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 border-collapse",
      className,
    )}
    {...props}
  />
));
GridContent.displayName = "GridContent";

interface GridLoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  loading: boolean;
}

const GridLoading = React.forwardRef<HTMLDivElement, GridLoadingProps>(
  ({ className, loading, ...props }, ref) => {
    if (!loading) {
      return null;
    }
    return (
      <div
        ref={ref}
        className={cn("flex items-center justify-center p-6", className)}
        {...props}
      />
    );
  },
);
GridLoading.displayName = "GridLoading";

export {
  Grid,
  GridHeader,
  GridBody,
  GridTitle,
  GridContent,
  GridLoading,
  GridButtonContainer,
};
