import { useCallback } from "react";
import { Icon } from "components/icon/Icon";
import { useKeywords } from "hooks/useKeywords";
import { cn } from "utils";
import { useTranslation } from "react-i18next";
import { toast } from "utils/toast";
import { logError } from "shared/services/ErrorReporting";

interface KeywordItemProps {
  keyword: string;
  isSelected: boolean;
  testId: string;
}

export const KeywordItem = ({
  keyword,
  isSelected,
  testId,
}: KeywordItemProps) => {
  const { t } = useTranslation("products");

  const { handleAddKeyword } = useKeywords();

  const handleClicked = useCallback(async () => {
    try {
      await handleAddKeyword(keyword);
    } catch (e) {
      logError(e);
      toast.error(t("productDetails:errorToast.errorUpdate"));
    }
  }, [t, handleAddKeyword, keyword]);

  return (
    <button
      type="button"
      className={cn(
        "flex flex-row justify-between items-center p-4 text-left border-r border-b text-s ",
      )}
      onClick={handleClicked}
      data-testid={testId}
    >
      <p className="text-s text-secondary-900">{keyword}</p>
      <span className={isSelected ? "text-primary-500" : "text-secondary-400"}>
        <Icon
          name={isSelected ? "circle-check" : "circle-plus"}
          size="medium"
          data-testid={
            isSelected ? `check-icon-${keyword}` : `add-icon-${keyword}`
          }
        />
      </span>
    </button>
  );
};
