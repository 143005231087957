import * as React from "react";
import { cn } from "utils/cn";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus:shadow-outline-secondary disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

const TextareaExample = () => {
  return (
    <div>
      <Textarea placeholder="Some text" />
      <br />
      <p>
        For full documentation check{" "}
        <a
          className="underline"
          href="https://ui.shadcn.com/docs/components/textarea"
          target="_blank"
          rel="noreferrer"
        >
          Shadcn documentation
        </a>
      </p>
    </div>
  );
};

export { Textarea, TextareaExample };
