import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "utils";

const badgeVariants = cva(
  "inline-flex items-center justify-center rounded-full px-4 py-1 leading-tight text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 min-w-24",
  {
    variants: {
      variant: {
        default: "bg-primary-25 text-primary",
        outline: "border",
        active: "bg-primary-25 text-primary animate-pulse",
        secondary: "bg-secondary-50 text-secondary-700",
        error: "bg-badge-error-background text-badge-error",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export type BadgeVariant = VariantProps<typeof badgeVariants>["variant"];

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div
      data-testid="badge"
      className={cn(badgeVariants({ variant }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
