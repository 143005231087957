import { useEffect } from "react";
import { flexRender } from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePreHeader,
  TableRow,
} from "components/ui/table";
import { useTrends } from "contexts/TrendsContext";
import { useTable } from "hooks/useTable";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";
import { PreHeader } from "./preHeader";

const emptyArray: IProduct[] = [];

export const SelectedTable = () => {
  const { t } = useTranslation("selectedSearchTerms");
  const { currentTrendWithProducts, isLoadingTrends } = useTrends();
  const navigate = useNavigate();

  const { table } = useTable({
    columns: columns(t),
    data: currentTrendWithProducts?.products || emptyArray,
    totalRecords: currentTrendWithProducts?.products?.length || 0,
  });

  useEffect(() => {
    if (isLoadingTrends) {
      return;
    }
    if (
      !currentTrendWithProducts ||
      currentTrendWithProducts.products?.length === 0
    ) {
      navigate(RoutePath.SearchTerms);
    }
  }, [currentTrendWithProducts, isLoadingTrends, navigate]);

  return (
    <div className="space-y-4 text-left">
      <Table>
        <TablePreHeader colSpan={table.getHeaderGroups()[0].headers.length}>
          <PreHeader table={table} />
        </TablePreHeader>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t("selectedSearchTerms:noProjectsFound")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* <DataTablePagination table={table} /> */}
    </div>
  );
};
