import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { useTranslation } from "react-i18next";
import { ErrorMessageWithImage } from "components/errors/ErrorMessageWithImage";

export const NotFound: React.FC = () => {
  const { t } = useTranslation(["general", "notFound"]);

  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate(RoutePath.EMPTY);
  }, [navigate]);

  return (
    <ErrorMessageWithImage
      title={
        <>
          {t("notFound:header.one")}
          <br />
          {t("notFound:header.two")}
        </>
      }
      message={
        <>
          {t("notFound:message.one")}
          <br />
          {t("notFound:message.two")}
        </>
      }
      onButtonClick={handleBack}
    />
  );
};
