import { flexRender, OnChangeFn, PaginationState } from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TablePreHeader,
  TableRow,
} from "components/ui/table";
import { useTable } from "hooks/useTable";
import { DataTablePagination } from "components/ui/data-table-pagination";
import { useBacklist } from "contexts/BacklistContext";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";
import { PreHeader } from "./PreHeader";

import { BooksTableBody } from "./BooksTableBody";

export const BooksTable = () => {
  const { t } = useTranslation(["books"]);

  const { backlist, resultLength, pagination, setPagination } = useBacklist();

  const onPageChange: OnChangeFn<PaginationState> = (paginationState) => {
    setPagination(paginationState);
  };

  const { table } = useTable({
    columns: columns(t),
    data: backlist,
    enableRowSelection: true,
    totalRecords: resultLength || 0,
    paginationState: { pagination, setPagination: onPageChange },
  });

  return (
    <div className="space-y-4 text-left">
      <Table>
        <TablePreHeader colSpan={table.getHeaderGroups()[0].headers.length}>
          <PreHeader table={table} />
        </TablePreHeader>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <BooksTableBody columns={columns(t)} table={table} />
        </TableBody>
      </Table>
      <DataTablePagination table={table} />
    </div>
  );
};
