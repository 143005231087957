import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalOverlayLoadingSpinner from "components/loading/ModalOverlayLoadingSpinner";
import { useTranslation } from "react-i18next";
import { ErrorOverlay } from "components/errors";
import { useProduct } from "contexts/ProductContext";
import { useProject } from "contexts/ProjectContext";
import { Button } from "components/button";
import { ProductNavigationBar } from "./components/ProductNavigationBar/ProductNavigationBar";
import { NoProductFound } from "./components/NoProductFound";

export const ProductDetails: React.FC = () => {
  const { t } = useTranslation(["productDetails"]);
  const navigate = useNavigate();

  const { product, loading, error } = useProduct();
  const { project } = useProject();

  if (error) {
    return (
      <ErrorOverlay
        className="mt-20 h-[calc(100% - 118px)]"
        title={t("productDetails:notFound.title")}
        message={t("productDetails:notFound.message")}
        onButtonClick={() => navigate(`/projects/${project?.id ?? ""}`)}
        mainImage={null}
        error={new Error(error)}
      />
    );
  }

  if ((!product || !project) && !loading) {
    return <NoProductFound />;
  }

  return (
    <>
      <div className="flex flex-row justify-between items-start">
        <Link to={`/projects/${project?.id}`} data-testid="overview-link">
          <Button
            variant="ghost"
            label={t("productDetails:header.overview")}
            icon="arrow-left"
            className="-ml-4"
            analyticsId="button_goto_overview"
          />
        </Link>
      </div>

      <ModalOverlayLoadingSpinner isLoading={loading} />

      <ProductNavigationBar />
    </>
  );
};
