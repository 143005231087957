import { TFunction } from "i18next";
import { BadgeVariant } from "components/ui/badge";

export type LabelKey =
  | "keywords"
  | "description"
  | "all"
  | "none"
  | "generating"
  | "error";

interface Label {
  key: LabelKey;
  type: BadgeVariant;
  label: string;
}

export const getLabels: (t: TFunction) => Label[] = (t) => [
  {
    key: "keywords",
    label: t("components:generationStatusBadge.labels.keywords"),
    type: "default",
  },
  {
    key: "description",
    label: t("components:generationStatusBadge.labels.description"),
    type: "default",
  },
  {
    key: "all",
    label: t("components:generationStatusBadge.labels.all"),
    type: "default",
  },
  {
    key: "none",
    label: t("components:generationStatusBadge.labels.none"),
    type: "secondary",
  },
  {
    key: "generating",
    label: t("components:generationStatusBadge.labels.generating"),
    type: "active",
  },
  {
    key: "error",
    label: t("components:generationStatusBadge.labels.error"),
    type: "error",
  },
];
