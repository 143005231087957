import { Table } from "@tanstack/react-table";
import { Icon } from "components/icon/Icon";
import { IProduct } from "integrations/firebase/collections";
import { ExportModal } from "components/ExportModal";

import { formatFirebaseTimestampDayTime } from "shared/utils";
import { useProjectDetails } from "../contexts/ProjectDetailsContext";
import { GenerateAllButton } from "./GenerateAllButton";

interface PreHeaderProps {
  table: Table<IProduct>;
}

export const PreHeader = ({ table }: PreHeaderProps) => {
  const { project } = useProjectDetails();
  const created = formatFirebaseTimestampDayTime(project?.createdAt!);

  return (
    <div className="flex flex-row w-full justify-between items-center">
      <div className="flex flex-row gap-2 items-center">
        <Icon name="calendar" size="medium" />
        <p className="text-secondary-500">{created.date}</p>
        <p className="text-secondary-500 font-normal">{created.time}</p>
      </div>
      <div className="flex gap-2">
        <GenerateAllButton />

        <ExportModal
          products={table.getCoreRowModel().rows.map((row) => row.original)}
          buttonVariant="primary"
        />
      </div>
    </div>
  );
};
