import { useTranslation } from "react-i18next";
import { BacklistContextProvider, useBacklist } from "contexts/BacklistContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { TabHeader } from "pages/TabBar";
import { BooksTable } from "./table/BooksTable";

export const BooksPageInternal = () => {
  const { noOfOrgBooks, resultLength, isLoadingSearch } = useBacklist();
  const { t } = useTranslation(["books"]);

  const getSubtitle = () => {
    if (resultLength === null && noOfOrgBooks === null && isLoadingSearch) {
      return t("books:header.subtitle.loading");
    }
    if (resultLength === null && noOfOrgBooks === null && !isLoadingSearch) {
      return "";
    }
    if (resultLength !== null && noOfOrgBooks !== null) {
      return t("books:header.subtitle.booksOfTotal", {
        resultLength,
        noOfOrgBooks,
      });
    }
    if (noOfOrgBooks !== null) {
      return t("books:header.subtitle.booksInDatabase", {
        noOfOrgBooks,
      });
    }
    return t("books:header.subtitle.booksFound", { resultLength });
  };

  return (
    <div>
      <TabHeader title={t("books:header.title")} subtitle={getSubtitle()} />
      <BooksTable />
    </div>
  );
};

export const BooksPage = () => {
  const queryClient = new QueryClient();

  return (
    <BacklistContextProvider>
      <QueryClientProvider client={queryClient}>
        <BooksPageInternal />
      </QueryClientProvider>
    </BacklistContextProvider>
  );
};
