import React from "react";
import { useTranslation } from "react-i18next";
import { CircleColorWarningCounter } from "components/label/CircleColorWarningCounter";

interface CircleColorWarningCounterKeywordsCharactersCountProps {
  count: number;
}

export const CircleColorWarningCounterKeywordsCharactersCount: React.FC<
  CircleColorWarningCounterKeywordsCharactersCountProps
> = ({ count }) => {
  const { t } = useTranslation(["productDetails"]);
  return (
    <CircleColorWarningCounter
      count={count}
      maxCount={210}
      endLabelWord={t("productDetails:warningCounter.characters.label")}
      tooltipTileOk={t(
        "productDetails:warningCounter.characters.tooltip.ok.title",
      )}
      tooltipDescriptionLine1Ok={t(
        "productDetails:warningCounter.characters.tooltip.ok.description.line1",
      )}
      tooltipDescriptionLine2Ok={t(
        "productDetails:warningCounter.characters.tooltip.ok.description.line2",
      )}
      tooltipTileWarning={t(
        "productDetails:warningCounter.characters.tooltip.warning.title",
      )}
      tooltipDescriptionLine1Warning={t(
        "productDetails:warningCounter.characters.tooltip.warning.description.line1",
      )}
      tooltipDescriptionLine2Warning={t(
        "productDetails:warningCounter.characters.tooltip.warning.description.line2",
      )}
    />
  );
};
