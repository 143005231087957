import { Icon } from "components/icon/Icon";
import { Skeleton } from "components/ui/skeleton";
import { cn } from "utils";

interface SkeletonKeywordProps {
  primary?: boolean;
  className?: string;
  animated?: boolean;
}

export const SkeletonKeyword = ({
  primary = false,
  className,
  animated = true,
}: SkeletonKeywordProps) => {
  return (
    <div
      className={cn(
        `absolute transition-colors border-[1.15px] rounded-md p-4 bg-white ${primary ? "border-primary-300 shadow-[0px_6px_12px_0px_#528BFF14]" : "border-secondary-200"} flex items-center space-x-6`,
        className,
      )}
    >
      <Skeleton animated={animated} className="h-4 w-40 bg-secondary-100" />
      <Icon
        className="animate-pulse"
        name="circle-plus"
        color="rgb(228 228 231)"
        size="small"
      />
    </div>
  );
};
