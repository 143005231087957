import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { Building, User2 } from "lucide-react";
import { useTranslation } from "react-i18next";

export const PreHeader = () => {
  const { showOnlyMyProjects, setShowOnlyMyProjects } = useProjects();
  const { t } = useTranslation("projects");

  const onValueChange = (value: string) => {
    if (value === "myProjects") {
      setShowOnlyMyProjects(true);
    }
    if (value === "allProjects") {
      setShowOnlyMyProjects(false);
    }
  };

  return (
    <div className="w-full flex justify-end">
      <Tabs
        onValueChange={onValueChange}
        value={showOnlyMyProjects ? "myProjects" : "allProjects"}
      >
        <TabsList>
          <TabsTrigger value="myProjects">
            <User2 size={20} />
            <p className="text-s">{t("projects.tabs.myProjects")}</p>
          </TabsTrigger>
          <TabsTrigger value="allProjects">
            <Building size={20} />
            <p className="text-s">{t("projects.tabs.allProjects")}</p>
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  );
};
