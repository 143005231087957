import { FallbackRender } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { Icon } from "components/icon/Icon";
import { ErrorMessageWithImage } from "./ErrorMessageWithImage";

type ErrorFallbackScreenProps = {
  error: Error;
};

export const ErrorFallbackScreen: React.FC<ErrorFallbackScreenProps> = ({
  error,
}) => {
  const { t } = useTranslation(["general"]);

  return (
    <ErrorMessageWithImage
      title={t("general:errorFallbackScreen.title")}
      message={t("general:errorFallbackScreen.message")}
      error={error}
      buttonText={t("general:buttons.reload")}
      icon={<Icon name="repeat" size="medium" />}
      onButtonClick={() => window.location.reload()}
    />
  );
};

// wrap it to be able to use hooks inside ErrorFallbackScreen
export const ErrorFallback: FallbackRender = ({ error }) => {
  return <ErrorFallbackScreen error={error as Error} />;
};
