import { SkeletonFinalKeyword } from "components/skeletons/SkeletonFinalKeyword";
import { useTranslation } from "react-i18next";

export const FinalKeywordsEmpty = () => {
  const { t } = useTranslation(["general", "productDetails"]);

  return (
    <div className="flex h-full justify-center items-center p-4">
      <div className="flex flex-col items-center m-8 text-center">
        <SkeletonFinalKeyword className="scale-75 opacity-80" />
        <SkeletonFinalKeyword className="scale-75" primary />
        <SkeletonFinalKeyword className="scale-75 opacity-80" />
        <div className="flex flex-col my-8 gap-4">
          <p className="font-semibold text-secondary-900">
            {t("productDetails:finalKeywords.empty.header")}
          </p>

          <p className="text-xs text-secondary-500">
            {t("productDetails:finalKeywords.empty.description")}
          </p>
        </div>
      </div>
    </div>
  );
};
