import { useCallback } from "react";
import { useProduct } from "contexts/ProductContext";
import { FirestoreProductSubject } from "__generated__/models";
import { logError } from "shared/services/ErrorReporting";
import { useProductActions } from "./useProductActions";

export const useThema = () => {
  const { product } = useProduct();
  const { saveFinalSubjects } = useProductActions();

  const handleAddSubject = useCallback(
    async (subject: FirestoreProductSubject) => {
      if (!product) {
        logError("Product not found while adding subject");
        return;
      }
      if (subject) {
        const finalSubjects = product.subjects?.final || [];
        if (
          finalSubjects.findIndex(
            (sub) => sub.subjectCode === subject.subjectCode,
          ) === -1
        ) {
          await saveFinalSubjects(product.id, [...finalSubjects, subject]);
        } else {
          await saveFinalSubjects(
            product.id,
            finalSubjects.filter((k) => k.subjectCode !== subject.subjectCode),
          );
        }
      }
    },
    [product, saveFinalSubjects],
  );

  return { handleAddSubject };
};
