import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useRef } from "react";

import { Input } from "components/ui/input";
import { IsbnMultipleSelectChip } from "components/select/IsbnMultipleSelectChip";

import { LucideX } from "lucide-react";

import { useBacklist } from "contexts/BacklistContext";
import { useBacklistInput } from "hooks/search/useBacklistInput";

import { useErrorToast } from "hooks/useErrorToast";
import { BacklistInputType } from "../../table/types";
import { InputSelect } from "./InputSelect";
import { KeywordFilter } from "../filter/KeywordFilter";

export const BacklistInput = () => {
  const { t } = useTranslation(["books"]);

  const {
    searchInputValue,
    setSearchInputValue,
    dropdownType,
    pagination,
    filter,
    setFilter,
  } = useBacklist();

  const { search, error } = useBacklistInput();
  const timer = useRef<number | null>(null);

  useErrorToast(error);

  const handleSearchInputValueChange = useCallback(
    (value: string | string[]) => {
      setSearchInputValue(value);
    },
    [setSearchInputValue],
  );

  const handleClearSearch = useCallback(
    (selectedType: BacklistInputType) => {
      setSearchInputValue(selectedType === BacklistInputType.ISBN ? [] : "");
      setFilter(undefined);
    },
    [setSearchInputValue, setFilter],
  );

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      search(searchInputValue, filter);
    }, 500);
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [pagination, search, searchInputValue, filter]);

  return (
    <div className="flex flex-row items-center items-stretch flex-start relative w-full">
      {dropdownType === BacklistInputType.QUICK && (
        <KeywordFilter setFilter={setFilter} filter={filter} />
      )}

      <InputSelect clearSearch={handleClearSearch} />
      {(dropdownType === BacklistInputType.QUICK ||
        dropdownType === BacklistInputType.SEMANTIC) && (
        <Input
          value={searchInputValue}
          onChange={(e) => handleSearchInputValueChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              search(searchInputValue, filter);
            }
          }}
          placeholder={
            dropdownType === BacklistInputType.QUICK
              ? t(`books:search.placeholder.quick`)
              : t(`books:search.placeholder.semantic`)
          }
          className="h-full rounded-lg rounded-l-none border-l-0 w-full font-normal py-3.5"
        />
      )}

      {dropdownType === BacklistInputType.ISBN && (
        <IsbnMultipleSelectChip
          values={
            Array.isArray(searchInputValue)
              ? searchInputValue
              : searchInputValue.split(",").filter((v) => v)
          }
          onValuesChangedCallback={(chips) =>
            handleSearchInputValueChange(chips.map((c) => c.value))
          }
          className="gap-y-2 w-full px-3 rounded-xl py-1 border border-l-0 bg-white text-secondary-600 shadow-sm hover:bg-secondary-25 hover: border-secondary-300 focus:shadow-outline-secondary rounded-l-none pr-2"
          chipClassName="!my-0"
          chipInputClassName="!h-5"
        />
      )}
      {searchInputValue ? (
        <LucideX
          size={16}
          className="absolute top-4 right-4 cursor-pointer"
          onClick={() => handleClearSearch(dropdownType)}
        />
      ) : null}
    </div>
  );
};
