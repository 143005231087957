import { FirestoreProductSubject } from "__generated__/models";
import { Model } from "integrations/firebase/collections/model";

export interface ThemaTabProps {
  title: string;
  key: "generated" | "database";
  content: React.ReactNode;
}

export enum SubjectSchemeIdentifier {
  ThemaSubjectCategory = "themaSubjectCategory",
  ThemaPlaceQualifier = "themaPlaceQualifier",
  ThemaTimePeriodQualifier = "themaTimePeriodQualifier",
  ThemaStyleQualifier = "themaStyleQualifier",
  ThemaEducationalPurposeQualifier = "themaEducationalPurposeQualifier",
  ThemaInterestAgeSpecialInterestQualifier = "themaInterestAgeSpecialInterestQualifier",
}

export type ThemaItem = Model<FirestoreProductSubject> & {
  subjectSchemeIdentifier: SubjectSchemeIdentifier;
};
