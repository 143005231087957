import { ChevronDown } from "lucide-react";
import { useState } from "react";
import { cn } from "utils";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
}

export const Section = ({
  title,
  children,
  defaultOpen = true,
}: SectionProps) => {
  const [show, setShow] = useState(defaultOpen);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row bg-secondary-50 space-between items-center min-h-10">
        <button
          className="flex flex-row w-full justify-between py-2 px-4 items-center"
          onClick={() => setShow(!show)}
          aria-label="Toggle section"
          type="button"
        >
          <div className="flex flex-col">
            <p className="text-xs text-secondary-500 font-semibold">{title}</p>
          </div>
          <ChevronDown
            className={cn(
              "w-4 h-4 transform transition-transform duration-300 text-secondary-500",
              !show && "rotate-180",
            )}
          />
        </button>
      </div>
      {show && children}
    </div>
  );
};
