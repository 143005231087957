import { TooltipProvider } from "components/ui/tooltip";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useBacklist } from "contexts/BacklistContext";
import { BacklistInputType } from "../../table/types";

export const InputSelect = ({
  clearSearch,
}: {
  clearSearch: (dt: BacklistInputType) => void;
}) => {
  const { t } = useTranslation(["books"]);
  const { dropdownType, setDropdownType } = useBacklist();
  const backlistInputTypesTranslations = useMemo(
    () => ({
      [BacklistInputType.QUICK]: t("books:search.backlistTypes.quick"),
      [BacklistInputType.ISBN]: t("books:search.backlistTypes.isbn"),
      [BacklistInputType.SEMANTIC]: t("books:search.backlistTypes.semantic"),
    }),
    [t],
  );

  const handleSelect = (value: BacklistInputType) => {
    setDropdownType(value);
    clearSearch(value);
  };

  const selectOptions = Object.entries(BacklistInputType);

  return (
    <TooltipProvider delayDuration={200}>
      <Select value={dropdownType} onValueChange={handleSelect}>
        <SelectTrigger
          className="h-full border-secondary-300 rounded-lg rounded-e-none bg-secondary-50 min-w-40 py-3"
          data-testid="search-dropdown"
        >
          <SelectValue placeholder="Search type" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {selectOptions.map(([key, value]) => (
              <SelectItem
                key={key}
                value={value}
                data-testid={`search-dropdown-item-${key}`}
                analytics-id="button_backlist_input_type"
              >
                <p>{backlistInputTypesTranslations[value]}</p>
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </TooltipProvider>
  );
};
