import React from "react";
import { useTranslation } from "react-i18next";
import { CircleColorWarningCounter } from "components/label/CircleColorWarningCounter";

interface CircleColorWarningCounterWordsGeneratedBookDescriptionProps {
  count: number;
}

export const CircleColorWarningCounterWordsGeneratedBookDescription: React.FC<
  CircleColorWarningCounterWordsGeneratedBookDescriptionProps
> = ({ count }) => {
  const { t } = useTranslation(["productDetails"]);
  return (
    <div className="flex justify-end w-full border-t p-4">
      <CircleColorWarningCounter
        count={count}
        maxCount={300}
        endLabelWord={t("productDetails:warningCounter.words.label")}
        tooltipTileOk={t(
          "productDetails:warningCounter.words.tooltip.ok.title",
        )}
        tooltipDescriptionLine1Ok={t(
          "productDetails:warningCounter.words.tooltip.ok.description.line1",
        )}
        tooltipDescriptionLine2Ok={t(
          "productDetails:warningCounter.words.tooltip.ok.description.line2",
        )}
        tooltipTileWarning={t(
          "productDetails:warningCounter.words.tooltip.warning.title",
        )}
        tooltipDescriptionLine1Warning={t(
          "productDetails:warningCounter.words.tooltip.warning.description.line1",
        )}
        tooltipDescriptionLine2Warning={t(
          "productDetails:warningCounter.words.tooltip.warning.description.line2",
        )}
      />
    </div>
  );
};
