import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown, Loader, LoaderIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { toast } from "utils/toast";
import { GenerationStatus } from "integrations/firebase/collections";
import { WarningModal } from "components/modal/WarningModal";
import { useProjectDetails } from "../contexts/ProjectDetailsContext";

interface GenerateAllButtonProps {
  label: string;
  disabled?: boolean;

  onClick(): void;
}

export function GenerateAllOption({
  disabled,
  onClick,
  label,
}: GenerateAllButtonProps) {
  return (
    <DropdownMenuItem
      className="py-2.5 px-3 cursor-pointer"
      disabled={disabled}
      onClick={onClick}
    >
      <Loader className="w-5 h-5 mr-2" />
      <span className="text-s">{label}</span>
    </DropdownMenuItem>
  );
}

export function GenerateAllButton() {
  const { t } = useTranslation(["projectDetails"]);
  const {
    generateAllProductsKeywords,
    generateAllProductsDescriptions,
    products = [],
  } = useProjectDetails();

  const [
    showDescriptionsGenerationWarning,
    setShowDescriptionsGenerationWarning,
  ] = useState(false);

  const [showKeywordsGenerationWarning, setShowKeywordsGenerationWarning] =
    useState(false);

  const generateKeywords = useCallback(
    async (skipWarning = false) => {
      const hasGeneratedKeywords = products.some(
        (p) =>
          p.generated?.keywords &&
          p.generated?.keywords?.status !== GenerationStatus.IMPORTED,
      );

      if (hasGeneratedKeywords && !skipWarning) {
        setShowKeywordsGenerationWarning(true);
        return;
      }

      setShowKeywordsGenerationWarning(false);

      await generateAllProductsKeywords();

      toast.success("Pre-Generating Metadata may take a while", {
        icon: <LoaderIcon className="animate-spin duration-2000 w-5 h-5" />,
      });
    },
    [generateAllProductsKeywords, products],
  );

  const generateDescriptions = useCallback(
    async (skipWarning = false) => {
      const hasGeneratedDescriptions = products.some(
        (p) =>
          p.generated?.description &&
          p.generated?.description?.status !== GenerationStatus.IMPORTED,
      );

      if (hasGeneratedDescriptions && !skipWarning) {
        setShowDescriptionsGenerationWarning(true);
        return;
      }

      setShowDescriptionsGenerationWarning(false);

      await generateAllProductsDescriptions();

      toast.success("Pre-Generating Metadata may take a while", {
        icon: <LoaderIcon className="animate-spin duration-2000 w-5 h-5" />,
      });
    },
    [generateAllProductsDescriptions, products],
  );

  const { generateAllEnabled, isGenerating } = useMemo(() => {
    if (!products?.length) {
      return { generateAllEnabled: false, isGenerating: false };
    }

    const hasRequested = products.some(
      (p) =>
        p.generated?.description?.status === "REQUESTED" ||
        p.generated?.keywords?.status === "REQUESTED",
    );

    const hasErrors = products.some((p) => p.nonExistent);

    if (hasErrors) {
      return { generateAllEnabled: false, isGenerating: hasRequested };
    }

    return { generateAllEnabled: !hasRequested, isGenerating: hasRequested };
  }, [products]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={isGenerating || !generateAllEnabled}
          className="disabled:text-secondary-400 text-secondary-700 group"
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="rounded-lg border flex-1 flex group-aria-expanded:border-secondary-600 cursor-pointer transition-colors">
                <div className="whitespace-nowrap font-normal flex items-center px-5 text-m">
                  {isGenerating
                    ? t("projectDetails:buttons.generateAll.generating")
                    : t("projectDetails:buttons.generateAll.metadata")}
                </div>

                <div className="transition-colors w-11 h-full border-l aspect-square flex items-center justify-center group-aria-expanded:border-l-secondary-600">
                  <ChevronDown className="w-5 h-5 transition-transform group-aria-expanded:rotate-180" />
                </div>
              </div>
            </TooltipTrigger>

            <TooltipContent
              data-testid="generate-all-tooltip"
              side="bottom"
              className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
              sideOffset={6}
            >
              <TooltipArrow
                className="fill-white drop-shadow-sm"
                width={12}
                height={6}
              />
              {t("projectDetails:buttons.generateAll.tooltips.enabled")}
            </TooltipContent>
          </Tooltip>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          side="bottom"
          align="center"
          className="popover-content-width-full"
        >
          <GenerateAllOption
            onClick={() => generateKeywords(false)}
            label={t("projectDetails:buttons.generateAll.items.keywords")}
          />
          <GenerateAllOption
            onClick={() => generateDescriptions(false)}
            label={t("projectDetails:buttons.generateAll.items.descriptions")}
          />
        </DropdownMenuContent>
      </DropdownMenu>

      <WarningModal
        id="overwrite-description"
        title={t("projectDetails:modals.overwriteDescriptions.title")}
        description={t(
          "projectDetails:modals.overwriteDescriptions.description",
        )}
        confirm={t(
          "projectDetails:modals.overwriteDescriptions.actions.confirm",
        )}
        confirmIcon="loader"
        cancel={t("projectDetails:modals.overwriteDescriptions.actions.cancel")}
        open={showDescriptionsGenerationWarning}
        onOpenChange={() => setShowDescriptionsGenerationWarning(false)}
        onConfirm={() => generateDescriptions(true)}
      />

      <WarningModal
        id="overwrite-keywords"
        title={t("projectDetails:modals.overwriteKeywords.title")}
        description={t("projectDetails:modals.overwriteKeywords.description")}
        confirm={t("projectDetails:modals.overwriteKeywords.actions.confirm")}
        confirmIcon="loader"
        cancel={t("projectDetails:modals.overwriteKeywords.actions.cancel")}
        open={showKeywordsGenerationWarning}
        onOpenChange={() => setShowKeywordsGenerationWarning(false)}
        onConfirm={() => generateKeywords(true)}
      />
    </>
  );
}
