export enum FilterType {
  LESS = "less",
  GREATER = "greater",
  EQUALS = "equals",
}

export enum ComparisonOperator {
  EQUALS = "==",
  LESS = "<",
  GREATER = ">",
}

export interface IFilter {
  type: string;
  value: number;
  comparisonOperator: keyof typeof FilterType;
  variable: string;
}
