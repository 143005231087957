import { IProduct, IProject } from "integrations/firebase/collections";

export const getProjectProducts = (project: IProject, products: IProduct[]) => {
  return project.isbns.map(
    (isbn) =>
      products.find((product) => product.isbn === isbn) ?? {
        id: isbn,
        isbn: null,
      },
  );
};
