import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { useAuth } from "contexts/AuthContext";
import { Button } from "components/button/Button";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface ProfileFormProps {
  email?: string;
  firstName?: string;
  lastName?: string;
}

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
}

export function ProfileForm({ email, firstName, lastName }: ProfileFormProps) {
  const { updateUserProfile } = useAuth();
  const { t } = useTranslation("profile");

  const form = useForm<FormValues>({
    defaultValues: {
      email: email ?? "",
      firstName: firstName ?? "",
      lastName: lastName ?? "",
    },
  });

  async function onSave(values: FormValues) {
    await updateUserProfile({
      firstName: values.firstName,
      lastName: values.lastName,
    });
  }

  return (
    <form className="grid gap-y-6 gap-x-8" onSubmit={form.handleSubmit(onSave)}>
      <div className="grid grid-cols-2 gap-8">
        <div className="space-y-2">
          <Label className="text-s">
            {t("profile:setup.form.firstName.label")}
          </Label>
          <Input
            placeholder={t("profile:setup.form.firstName.placeholder")}
            {...form.register("firstName", { required: true })}
            className="text-s"
          />
        </div>

        <div className="space-y-2">
          <Label className="text-s">
            {t("profile:setup.form.lastName.label")}
          </Label>
          <Input
            placeholder={t("profile:setup.form.lastName.placeholder")}
            {...form.register("lastName", { required: true })}
            className="text-s"
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label className="text-s">{t("profile:setup.form.email.label")}</Label>
        <Input
          {...form.register("email")}
          placeholder={t("profile:setup.form.email.placeholder")}
          disabled
          className="text-s"
        />
      </div>

      <Button
        label={t("profile:setup.form.submit")}
        type="submit"
        disabled={!form.formState.isValid}
      />
    </form>
  );
}

export function ProfileModal() {
  const { userData, loading } = useAuth();
  const { t } = useTranslation("profile");

  if (!userData || loading) {
    return;
  }

  const showModal = Boolean(!userData.givenName || !userData.familyName);

  return (
    <Dialog open={showModal}>
      <DialogContent hideClose>
        <DialogHeader>
          <DialogTitle>{t("profile:setup.title")}</DialogTitle>
          <DialogDescription hidden>
            {t("profile:setup.description")}
          </DialogDescription>
        </DialogHeader>

        <ProfileForm
          email={userData.email}
          firstName={userData.givenName}
          lastName={userData.familyName}
        />
      </DialogContent>
    </Dialog>
  );
}
