import { BubbleMenu, Editor } from "@tiptap/react";
import { useCallback } from "react";
import { BoldIcon, ItalicIcon } from "lucide-react";
import { EditorAction } from "./EditorAction";

interface EditorActionsProps {
  editor: Editor | null;
}

export function EditorActions({ editor }: EditorActionsProps) {
  const onBold = useCallback(
    () => editor?.chain().focus().toggleBold().run(),
    [editor],
  );

  const onItalic = useCallback(
    () => editor?.chain().focus().toggleItalic().run(),
    [editor],
  );

  return (
    <BubbleMenu
      tippyOptions={{ placement: "top-start", offset: [0, 12] }}
      editor={editor}
      className="flex bg-white gap-1 border border-gray-100 shadow-md rounded-lg p-1 text-secondary-800 relative"
    >
      <div className="flex gap-1 z-10 bg-white">
        <EditorAction
          label="Bold"
          isActive={editor?.isActive("bold")}
          onClick={onBold}
          icon={BoldIcon}
        />
        <EditorAction
          label="Italic"
          isActive={editor?.isActive("italic")}
          onClick={onItalic}
          icon={ItalicIcon}
        />
      </div>

      <div className="bg-white border border-gray-100 w-2.5 h-2.5 rotate-45 -translate-x-1/2 absolute left-1/2 -bottom-[5px] border-l-white border-t-white shadow-editor-action z-0" />
    </BubbleMenu>
  );
}
