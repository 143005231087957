import { useEffect, useState } from "react";

import { useCurrentEditor } from "./EditorProvider";
import { countWords } from "../../utils";
import { CircleColorWarningCounterWordsGeneratedBookDescription } from "./CircleColorWarningCounterWordsGeneratedBookDescription";

export function EditorWordCount() {
  const { editor } = useCurrentEditor();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!editor) {
      return;
    }

    function setWordCount(text: string) {
      setCount(countWords(text));
    }

    editor.on("transaction", (props) => {
      if (!props.transaction.docChanged) {
        return;
      }

      const text = props.editor.getText();

      setWordCount(text);
    });

    const text = editor.getText();

    setWordCount(text);
  }, [editor]);

  return (
    <CircleColorWarningCounterWordsGeneratedBookDescription count={count} />
  );
}
