import { Timestamp, FirestoreDataConverter } from "firebase/firestore";

/**
 * Convert a unix timestamp to a Firestore Timestamp (unix = seconds)
 * @param unix
 */
export function timestampFromUnix(unix: number) {
  return Timestamp.fromMillis(unix * 1000);
}

export const converter: FirestoreDataConverter<any> = {
  fromFirestore(snapshot) {
    const data = snapshot.data();

    return {
      ...data,
      id: data.id || snapshot.id,
    };
  },
  toFirestore: (data) => data,
};
