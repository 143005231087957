import { Icon } from "./Icon";

export const Info = () => {
  return (
    <div className="relative flex justify-center items-center w-full pb-3 bg-red">
      <div className="relative h-16">
        <span className="absolute flex h-10 w-10 top-1/3 -left-[20px] items-center justify-center">
          <span className="animate-ping duration-5000 absolute inline-flex h-full w-full rounded-full bg-primary-700 opacity-15" />
          <span className=" absolute inline-flex rounded-full h-10 w-10 bg-primary-700 opacity-15" />
          <Icon
            color="rgb(0, 78, 235)"
            className="absolute"
            name="zap"
            size="large"
          />
        </span>
      </div>
    </div>
  );
};
