import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import ChainedBackend from "i18next-chained-backend";
import { initReactI18next } from "react-i18next";

const namespacesToPreload: string[] = ["general", "components"];

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: ["en"],
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          loadPath: `/locales/{{lng}}/{{ns}}.json?version=${import.meta.env.VITE_LOCALES_HASH}`,
        },
      ],
    },
  });

i18n.loadNamespaces(namespacesToPreload);

export default i18n;
