import { FC } from "react";
import { cn } from "utils";
import { ErrorMessage, ErrorMessageProps } from "./ErrorMessage";

type ErrorOverlayProps = ErrorMessageProps & {
  className?: string;
};
export const ErrorOverlay: FC<ErrorOverlayProps> = ({
  className,
  ...errorProps
}) => {
  const { error } = errorProps;
  return error ? (
    <div
      className={cn(
        "absolute top-0 left-0 w-full h-full flex justify-center items-center bg-opacity-90 z-50 select-none",
        className,
      )}
    >
      <ErrorMessage {...errorProps} />
    </div>
  ) : null;
};
