export const removeHtmlTagsAndEntities = (html: string): string => {
  return html
    .replace(/<[/]?p>/g, " ")
    .replace(/<[/]?b>/g, "")
    .replace(/<[/]?i>/g, "")
    .replace(/<[/]?strong>/g, "")
    .replace(/&nbsp;/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&#(\d+);/g, (_, dec: string) =>
      String.fromCharCode(parseInt(dec, 10)),
    )
    .replace(/&#x([a-fA-F0-9]+);/g, (_, hex: string) =>
      String.fromCharCode(parseInt(hex, 16)),
    );
};

export function countWords(text: string) {
  const cleaned = text.replaceAll(/[^A-zÀ-ú0-9-]/gim, " ").trim();

  const words = cleaned
    .split(" ")
    .map((word) => word.trim())
    .filter(Boolean);

  return words.length;
}

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
