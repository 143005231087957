import React, { useEffect } from "react";
import { AuthLayout } from "layouts/AuthLayout";
import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNetworkStatus } from "hooks/useNetworkStatus";
import { RoutePath } from "shared/constants";
import { Login } from "pages/Login";
import { toast } from "utils/toast";
import { AppRoutes } from "./AppRoutes";
import { LoginLinkPage } from "./pages/Login/LoginLink";

const AuthenticatedApp: React.FC = () => {
  const { t } = useTranslation(["general"]);
  const { isOnline } = useNetworkStatus();

  useEffect(() => {
    if (!isOnline) {
      toast.error(t("general:errorNetworkNoInternetConnection"));
    }
  }, [isOnline, t]);

  return (
    <Routes>
      <Route
        path={RoutePath.EMPTY}
        element={<Navigate to={RoutePath.Books} />}
      />

      <Route path={RoutePath.Login} element={<Login />} />
      <Route path={RoutePath.LoginLink} element={<LoginLinkPage />} />

      <Route path="*" element={<AuthLayout />}>
        <Route path="*" element={<AppRoutes />} />
      </Route>
    </Routes>
  );
};

export default AuthenticatedApp;
