/**
 * Context for managing Google Analytics (GA4) events.
 *
 * Goals:
 * -[x] Initialize the GA4 client once the user is authenticated
 * -[x] Ensure authentication context is available before logging events
 * -[x] Avoid state updates causing re-renders as this sits high in the component tree
 * -[x] Handle page views manually (otherwise GA4 does try to handle this automatically)
 * -[x] Handle user id and user properties (organisation name, organisation id)
 * -[x] Handle cleaning up the client when the user logs out
 * -[x] Provide a hook to log events
 * -[x] Provide a hook to set user id and user properties (hidden in context and not exposed)
 * -[x] Handle feature flag to enable/disable analytics
 * Non-goals:
 * -[ ] Handle consent tracking
 */
import {
  Analytics,
  initializeAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { app } from "integrations/firebase/app";

import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { analyticsDisabled } from "shared/constants/Environment";
import { useAuth } from "../AuthContext";
import { AnalyticsEvent } from "./AnalyticsEvents";

export type IAnalyticsContext = {
  gaEvent: (event: AnalyticsEvent) => void;
};

export const AnalyticsContext = createContext<IAnalyticsContext>(
  undefined as never,
);

export const useAnalytics = () => useContext(AnalyticsContext);

const initialiseAnalytics = () => {
  const analytics = initializeAnalytics(app, {
    config: {
      send_page_view: false, // handle page views manually
    },
  });
  setAnalyticsCollectionEnabled(analytics, false);
  return analytics;
};

const resetAnalytics = (analytics?: Analytics) => {
  if (!analytics) {
    return;
  }
  setUserId(analytics, null);
  setUserProperties(analytics, {
    organisation_id: null,
    organisation_name: null,
  });
};

export const AnalyticsContextProvider = ({ children }: PropsWithChildren) => {
  const [analytics, setAnalytics] = useState<Analytics>();
  const location = useLocation();
  const { loading, userData } = useAuth();

  useEffect(() => {
    if (loading) return;
    if (analyticsDisabled) return;

    const client = analytics ?? initialiseAnalytics();

    if (userData === null) {
      resetAnalytics(client);
    } else {
      setUserId(client, userData.userId);
      setUserProperties(client, {
        organisation_id: userData.organisation.id,
        organisation_name: userData.organisation.name,
      });
    }
    setAnalytics(client);
  }, [analytics, loading, location.pathname, userData]);

  const gaEvent = useCallback(
    (event: AnalyticsEvent) => {
      if (!analytics || !userData || analyticsDisabled) {
        return;
      }

      const payload = {
        ...event.payload,
        organisation_id: userData.organisation.id,
        organisation_name: userData.organisation.name,
        organisation: userData.organisation.name,
        user_id: userData.userId,
      };

      logEvent(analytics, event.type as string, payload); // Use `as string` to help TS select the right overloading fn
    },
    [analytics, userData],
  );

  useEffect(() => {
    if (!analytics) {
      return;
    }
    // Disable analytics in development and test environments.
    if (analyticsDisabled) {
      return;
    }

    // Re-enable analytics collection, once the client is initialised with a user id
    setAnalyticsCollectionEnabled(analytics, true);
    // Log the initial page view, that would have been missed due to disabled analytics collection
    gaEvent({
      type: "page_view",
      payload: {
        page_path: location.pathname,
        page_location: window.location.href,
        page_title: document.title,
        search_params: location.search,
      },
    });
  }, [analytics, gaEvent, location.pathname, location.search]);

  // Log page view on each location change
  useEffect(() => {
    if (!analytics) {
      return;
    }

    gaEvent({
      type: "page_view",
      payload: {
        page_path: location.pathname,
        page_location: window.location.href,
        page_title: document.title,
        search_params: location.search,
      },
    });
  }, [analytics, gaEvent, location.pathname, location.search]);

  const value = useMemo(() => {
    return { gaEvent };
  }, [gaEvent]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
