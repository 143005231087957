import { CurrentProductContextProvider } from "contexts/CurrentProductContext";
import { TrendsContextProvider } from "contexts/TrendsContext";
import { ProjectsContextProvider } from "pages/Projects/contexts/ProjectsContext";
import { Layout } from "components/layout";
import { Outlet } from "react-router-dom";
import useAuthRedirect from "hooks/auth/useAuthRedirect";
import { ProfileModal } from "../components/modal/ProfileModal";

export function AuthLayout() {
  useAuthRedirect();

  return (
    <CurrentProductContextProvider>
      <TrendsContextProvider>
        <ProjectsContextProvider>
          <Layout>
            <Outlet />
            <ProfileModal />
          </Layout>
        </ProjectsContextProvider>
      </TrendsContextProvider>
    </CurrentProductContextProvider>
  );
}
