import { Row } from "@tanstack/react-table";
import {
  getProjectsCollection,
  IProject,
} from "integrations/firebase/collections";
import { deleteDoc, doc } from "firebase/firestore";
import { Button } from "components/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { EllipsisVertical, Trash } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "components/ui/dialog";
import { useDialog } from "hooks/useDialog";
import { useProjects } from "pages/Projects/contexts/ProjectsContext";

interface DropdownMenuProps {
  isOwner?: boolean;
  row: Row<IProject>;
}

export const TableMenu = ({ isOwner, row }: DropdownMenuProps) => {
  const { isOpen, onOpen, onClose, onOpenChange } = useDialog();
  const { fetchProjects } = useProjects();

  const handleDelete = async () => {
    await deleteDoc(doc(getProjectsCollection(), row.original.id));
    await fetchProjects();

    onClose();
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={!isOwner}
          data-testid="table-menu-button"
        >
          <div
            className={`flex flex-row items-center gap-2 z-40 ${isOwner ? "text-secondary-500" : "text-secondary-300"}`}
          >
            <EllipsisVertical size={16} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem onClick={onOpen}>
            <Trash className="mr-2 h-3.5 w-3.5 text-system-error" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        onOpenChange={onOpenChange}
        open={isOpen}
        modal
        defaultOpen={false}
      >
        <DialogContent>
          <DialogTitle className="text-left">Delete project</DialogTitle>

          <DialogDescription className="text-left">
            Are you sure you want to delete this project?
          </DialogDescription>

          <DialogFooter className="w-full flex flex-row justify-between">
            <Button variant="tertiary" label="Cancel" onClick={onClose} />
            <Button
              warning
              testId="dialog-confirm"
              label="Delete"
              icon="trash"
              onClick={handleDelete}
            />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
