import { SkeletonKeyword } from "components/skeletons/SkeletonKeyword";

import { useTranslation } from "react-i18next";

export const DatabaseKeywordsEmpty = () => {
  const { t } = useTranslation(["productDetails"]);
  return (
    <div className="relative min-h-44 w-full items-center flex flex-row justify-center gap-4 my-2">
      <div className="flex justify-center flex-col">
        <SkeletonKeyword
          animated={false}
          className="scale-75 relative animate-none opacity-60"
        />
        <SkeletonKeyword
          animated={false}
          primary
          className="scale-75 relative animate-none"
        />
        <SkeletonKeyword
          animated={false}
          className="scale-75 relative animate-none opacity-60"
        />
      </div>
      <div className="flex flex-col gap-3 items-start">
        <p className="font-semibold ">
          {t("productDetails:databaseKeywordsEmpty.title")}
        </p>
        <p className="text-s">
          {t("productDetails:databaseKeywordsEmpty.description")}
        </p>
      </div>
    </div>
  );
};
