import { FC, PropsWithChildren } from "react";
import { AuthHeader } from "./AuthHeader";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="max-h-full flex flex-col">
      <AuthHeader />
      <main className="px-16 py-8 overflow-auto">{children}</main>
    </div>
  );
};
