import { useBacklist } from "contexts/BacklistContext";
import { useCallback, useEffect, useRef } from "react";
import { IFilter } from "pages/Books/components/filter/keywordFilterTypes";
import { useTypesense } from "hooks/useTypesense";
import { getQuery, mapTypesenseToProduct } from "./queries";

export const useBacklistInput = () => {
  const {
    setBacklist,
    dropdownType,
    setIsLoadingSearch,
    noOfOrgBooks,
    setNoOfOrgBooks,
    setResultLength,
    setPagination,
    pagination,
  } = useBacklist();
  const { searchProducts, isLoading, error, data } = useTypesense();
  const prevQuery = useRef<string | string[]>([]);
  const prevFilter = useRef<IFilter | undefined>();

  useEffect(() => {
    setIsLoadingSearch(isLoading);
  }, [isLoading, setIsLoadingSearch]);

  const search = useCallback(
    (query: string | string[], filter: IFilter | undefined) => {
      const searchParameters = getQuery(
        dropdownType,
        query,
        filter,
        pagination,
      );
      if (!searchParameters) {
        return;
      }
      if (prevQuery.current !== query || prevFilter.current !== filter) {
        prevQuery.current = query;
        prevFilter.current = filter;
        setPagination({ ...pagination, pageIndex: 0 });
      }
      searchProducts(searchParameters);
    },
    [dropdownType, pagination, searchProducts, setPagination],
  );
  useEffect(() => {
    if (data) {
      const documents = data.hits?.map((hit) => hit.document) || [];
      const mappedDocuments = documents.map((doc) =>
        mapTypesenseToProduct(doc),
      );
      if (!noOfOrgBooks) {
        setNoOfOrgBooks(data.found || 0);
      }
      setResultLength(data.found || 0);
      setBacklist(mappedDocuments);
    }
  }, [data, noOfOrgBooks, setBacklist, setNoOfOrgBooks, setResultLength]);

  return {
    search,
    data,
    isLoading,
    error,
  };
};
