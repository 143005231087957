import { postSearch, SearchQueryRequest, client } from "__generated__/api";
import { postSearchResponseSchema } from "__generated__/api/validation.gen";
import { parseResult } from "api/parseHelper";

export type PostSearchParams = SearchQueryRequest;

const baseUrl = import.meta.env.VITE_API_URL;
client.setConfig({
  baseUrl,
});

export const callPostSearch = async (body: SearchQueryRequest) => {
  const headers = {
    Authorization: `Bearer ${window.AUTH_TOKEN}`,
  };

  const result = await postSearch({ headers, body });
  const parsedResult = parseResult(postSearchResponseSchema)(result, body);
  return parsedResult?.data;
};
