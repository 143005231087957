import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { cn } from "utils";
import { ElementType } from "react";

interface EditorActionProps {
  onClick(): void;

  isActive?: boolean;
  label: string;
  icon: ElementType;
}

export function EditorAction({
  label,
  onClick,
  isActive,
  icon: Icon,
}: EditorActionProps) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          type="button"
          className={cn(
            "hover:bg-gray-100 rounded-md w-8 h-8 p-0 flex items-center justify-center",
            isActive && "bg-gray-100",
          )}
          onClick={onClick}
        >
          <div className="sr-only">{label}</div>
          <Icon className="w-5 h-5 text-secondary-800" />
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <span className="text-xs">{label}</span>
      </TooltipContent>
    </Tooltip>
  );
}
