import { EmptyListHorizontal } from "./EmptyListHorizontal";
import { EmptyListProps } from "./EmptyListTypes";
import { EmptyListVertical } from "./EmptyListVertical";

export const EmptyList: React.FC<EmptyListProps> = (props) => {
  const { variant } = props;
  if (variant === "horizontal") {
    return <EmptyListHorizontal {...props} />;
  }
  return <EmptyListVertical {...props} />;
};
