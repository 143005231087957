import { GenerationStatus, IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Badge } from "components/ui/badge";
import { AlertCircle } from "lucide-react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { LabelKey, getLabels } from "./labels";

interface GenerationStatusBadgeProps {
  generated?: IProduct["generated"];
}

export function GenerationStatusBadge({
  generated,
}: GenerationStatusBadgeProps) {
  const { t } = useTranslation(["components"]);

  const description = generated?.description?.status;
  const keywords = generated?.keywords?.status;

  const labelKey = useMemo<LabelKey>(() => {
    if (
      description === GenerationStatus.REQUESTED ||
      keywords === GenerationStatus.REQUESTED
    ) {
      return "generating";
    }

    if (
      description === GenerationStatus.ERROR ||
      keywords === GenerationStatus.ERROR
    ) {
      return "error";
    }

    if (
      description === GenerationStatus.COMPLETED &&
      keywords === GenerationStatus.COMPLETED
    ) {
      return "all";
    }

    if (description === GenerationStatus.COMPLETED) {
      return "description";
    }

    if (keywords === GenerationStatus.COMPLETED) {
      return "keywords";
    }

    return "none";
  }, [keywords, description]);

  const label = getLabels(t).find((l) => l.key === labelKey);

  if (!label) {
    return null;
  }

  const badge = (
    <Badge variant={label.type}>
      {labelKey === "error" && <AlertCircle className="w-3 h-3 mr-1" />}
      {label.label}
    </Badge>
  );

  if (labelKey === "error") {
    const message =
      // eslint-disable-next-line no-nested-ternary
      keywords === GenerationStatus.ERROR &&
      description === GenerationStatus.ERROR
        ? t("components:generationStatusBadge.errors.metadata")
        : description === GenerationStatus.ERROR
          ? t("components:generationStatusBadge.errors.description")
          : t("components:generationStatusBadge.errors.keywords");

    return (
      <Tooltip>
        <TooltipTrigger>{badge}</TooltipTrigger>

        <TooltipContent
          side="bottom"
          className="text-center shadow-tooltip text-neutral-600 whitespace-pre z-10 border-none leading-relaxed"
          sideOffset={6}
          data-testid="badge-tooltip"
        >
          <TooltipArrow
            className="fill-white drop-shadow-sm"
            width={12}
            height={6}
          />
          <p>{message}</p>
        </TooltipContent>
      </Tooltip>
    );
  }

  return badge;
}
