import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { Editor } from "@tiptap/react";

interface IEditorContext {
  editor: Editor | null;
}

export const EditorContext = createContext<IEditorContext>(undefined as never);

export const useCurrentEditor = () => useContext(EditorContext);

interface Props {
  editor: Editor | null;
}

export function EditorProvider({ children, editor }: PropsWithChildren<Props>) {
  const context = useMemo(() => ({ editor }), [editor]);

  return (
    <EditorContext.Provider value={context}>{children}</EditorContext.Provider>
  );
}
