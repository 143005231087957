import * as React from "react";
import { useAuth } from "contexts/AuthContext";
import CenteredPageWithHeroIllustrationImageOnBottomRight from "components/page/CenteredPageWithHeroIllustrationImageOnBottomRight";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "components/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";

export const Profile: React.FC = () => {
  const { t } = useTranslation(["general", "profile"]);
  const { authUser } = useAuth();
  const { displayName, email } = authUser || {};
  const location = useLocation();
  const navigate = useNavigate();
  const { prevPath, prevSearch = "" } = location.state || { prevPath: "/" };
  const href = `${prevPath}${prevSearch}`;

  return (
    <CenteredPageWithHeroIllustrationImageOnBottomRight>
      <div className="flex flex-col w-1/4 gap-6 items-start">
        <p className="font-semibold">{t("profile:header")}</p>

        <Label htmlFor="email">Email</Label>
        <Input
          value={email || ""}
          type="email"
          id="email"
          disabled
          placeholder="Email"
        />
        <Label htmlFor="fullName">Name</Label>
        <Input
          value={displayName || ""}
          type="name"
          id="name"
          disabled
          placeholder="Email"
        />
        <Button
          variant="primary"
          label={t("profile:buttons.back")}
          icon="arrow-left"
          iconPosition="left"
          onClick={() => navigate(href)}
          analyticsId="button_back_from_profile_page"
        />
      </div>
    </CenteredPageWithHeroIllustrationImageOnBottomRight>
  );
};
