import { useTranslation } from "react-i18next";
import {
  Grid,
  GridHeader,
  GridTitle,
  GridContent,
  GridBody,
} from "components/ui/grid";

import { useProduct } from "contexts/ProductContext";
import { useMemo } from "react";
import { EmptyList } from "components/EmptyList";
import { SubjectItem } from "./subjectItem";
import { filterQualifiers } from "./utils";

export const QualifiersList = () => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();

  const qualifiers = useMemo(
    () => filterQualifiers(product?.subjects?.database || []),
    [product?.subjects?.database],
  );

  const finalQualifiers = useMemo(
    () => filterQualifiers(product?.subjects?.final || []),
    [product],
  );

  return (
    <Grid className="h-full">
      <GridHeader>
        <GridTitle>{t("thema.headers.qualifiers")}</GridTitle>
      </GridHeader>
      <GridBody className="h-full">
        {qualifiers.length ? (
          <GridContent className="md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
            {qualifiers.map((subject) => (
              <SubjectItem
                key={subject.subjectCode}
                subject={subject}
                testId={`add-qualifier-${subject}-button`}
                isSelected={
                  finalQualifiers?.some((item) => item.id === subject.id) ||
                  false
                }
              />
            ))}
          </GridContent>
        ) : null}
        {!qualifiers.length && (
          <EmptyList
            variant="vertical"
            title={t("thema.sections.noQualifiers.title")}
            description={t("thema.sections.noQualifiers.description")}
          />
        )}
      </GridBody>
    </Grid>
  );
};
