import { useCallback, useMemo } from "react";
import { useProduct } from "contexts/ProductContext";
import { useTranslation } from "react-i18next";
import {
  BaseItem,
  SortableList,
} from "pages/ProductDetails/components/SortableList";
import { FinalHeader } from "components/layout/finalHeader";
import { useThema } from "hooks/useThema";
import { Section } from "./section";
import { FinalSectionEmpty } from "./finalSectionEmpty";
import { filterQualifiers, filterSubjects } from "../utils";

export const FinalThemaList = () => {
  const { t } = useTranslation(["productDetails"]);

  const { product } = useProduct();
  const { handleAddSubject } = useThema();

  const finalSubjects = useMemo(
    () => filterSubjects(product?.subjects?.final || []),
    [product],
  );
  const finalQualifiers = useMemo(
    () => filterQualifiers(product?.subjects?.final || []),
    [product],
  );
  const finalSubjectsListItems = useMemo(
    () =>
      finalSubjects?.map((subject, index) => ({
        id: index + 1,
        value: subject.subjectCode!,
        description: subject.subjectHeadingText,
      })),
    [finalSubjects],
  );
  const finalQualifiersListItems = useMemo(
    () =>
      finalQualifiers?.map((qualifier, index) => ({
        id: index + 1,
        value: qualifier.subjectCode!,
        description: qualifier.subjectHeadingText,
      })),
    [finalQualifiers],
  );

  const handleRemoveFinalSubjectsListItem = useCallback(
    async (item: BaseItem | undefined) => {
      if (!item) {
        return;
      }
      const { value } = item;
      const subjectItem = [...finalSubjects, ...finalQualifiers].find(
        (subject) => subject.subjectCode === value,
      );
      if (subjectItem) {
        handleAddSubject(subjectItem);
      }
    },
    [finalQualifiers, finalSubjects, handleAddSubject],
  );

  const hasSubjects = finalSubjects.length > 0;
  const hasQualifiers = finalQualifiers.length > 0;

  return (
    <div className="rounded-xl border border-secondary-200 overflow-hidden min-w-80 min-h-10 h-full ">
      <FinalHeader label={t("thema.headers.final")} />
      <div className="overflow-y-scroll overflow-x-clip">
        <Section title={t("thema.sections.finalSubjects.section.subjects")}>
          {!hasSubjects && (
            <FinalSectionEmpty
              header={t("thema.sections.finalSubjects.noSubjects.header")}
              description={t(
                "thema.sections.finalSubjects.noSubjects.description",
              )}
            />
          )}
          <SortableList
            items={finalSubjectsListItems as BaseItem[]}
            onChange={() => {}}
            onRemove={handleRemoveFinalSubjectsListItem}
          />
        </Section>
        <Section title={t("thema.sections.finalSubjects.section.qualifiers")}>
          {!hasQualifiers && (
            <FinalSectionEmpty
              description={t(
                "thema.sections.finalSubjects.noQualifiers.description",
              )}
              header={t("thema.sections.finalSubjects.noQualifiers.header")}
            />
          )}
          <SortableList
            items={finalQualifiersListItems as BaseItem[]}
            onChange={() => {}}
            onRemove={handleRemoveFinalSubjectsListItem}
          />
        </Section>
      </div>
    </div>
  );
};
