import { useCallback, useMemo } from "react";
import debounce from "lodash/debounce";
import { GenerationStatus } from "integrations/firebase/collections";
import { useProduct } from "contexts/ProductContext";
import { useBookDescription } from "contexts/BookDescriptionContext";
import { OptimiseDescriptionModal } from "../Details/OptimiseDescriptionModal";
import { GenerateBookDescriptionEmpty } from "./GenerateBookDescriptionEmpty";
import { LoadingGenerateDescription } from "./LoadingGenerateDescription";
import { GenerateHeader } from "./GenerateHeader";
import { Editor } from "../Editor";
import { EditorWordCount } from "../Editor/EditorWordCount";

function DescriptionEditor() {
  const { product } = useProduct();
  const {
    setShowDetailsGenerateBookDescription,
    onUpdateGeneratedDescription,
  } = useBookDescription();

  const keywords = useMemo(
    () => ({
      generated: product?.generated?.keywords?.data,
    }),
    [product?.generated?.keywords],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdate = useCallback(
    debounce((value: string) => onUpdateGeneratedDescription(value), 500),
    [onUpdateGeneratedDescription],
  );

  const status = product?.generated?.description?.status;

  if (status === GenerationStatus.REQUESTED) {
    return <LoadingGenerateDescription />;
  }

  if (product?.generated?.description?.data?.text) {
    return (
      <div className="flex-1 flex flex-col">
        <Editor
          key={product.id}
          initialValue={product.generated.description.data.text}
          keywords={keywords}
          onChange={onUpdate}
        >
          <div data-testid="editor-footer">
            <EditorWordCount />
          </div>
        </Editor>
      </div>
    );
  }

  return (
    <GenerateBookDescriptionEmpty
      onClick={() => setShowDetailsGenerateBookDescription(true)}
    />
  );
}

export const GenerateDescription = () => {
  return (
    <div className="flex flex-col rounded-t-xl overflow-hidden">
      <GenerateHeader />

      <div className="border rounded-b-xl flex-1 flex relative">
        <OptimiseDescriptionModal />
        <DescriptionEditor />
      </div>
    </div>
  );
};
