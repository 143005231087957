import { KeyboardEventHandler, useState } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { LucideX } from "lucide-react";
import { IMultipleSelectChipValue } from "./types";

interface IChipProps {
  onValueChangedCallback: (value: string) => void;
  value: IMultipleSelectChipValue;
  editing: boolean;
  setEditing: () => void;
  onBlur: () => void;
  removeChip: () => void;
  handleKeyDown: KeyboardEventHandler;
  handlePaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  className?: string;
  inputClassName?: string;
}

export const Chip = ({
  onValueChangedCallback,
  value,
  editing,
  setEditing,
  onBlur,
  removeChip,
  handleKeyDown,
  handlePaste,
  className,
  inputClassName,
}: IChipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const showError = !editing && value.error;

  return (
    <div
      className={`
        relative 
        flex
        py-2 px-4 pr-8
        rounded-full
        bg-secondary-50
        mr-2 
        my-1
        ${className}
      `}
    >
      {!editing && (
        <TooltipProvider delayDuration={200}>
          <Tooltip
            open={tooltipOpen}
            onOpenChange={(v: boolean) => setTooltipOpen(showError ? v : false)}
          >
            <TooltipTrigger asChild>
              <button
                type="button"
                onClick={() => setEditing()}
                className={`w-32 overflow-hidden text-ellipsis text-secondary-600 ${showError ? "text-system-error" : ""}`}
              >
                {value.value}
              </button>
            </TooltipTrigger>
            <TooltipContent side="bottom" className="rounded-lg">
              <TooltipArrow className="fill-white shadow-md stroke-secondary-300" />
              {value.error}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}

      {editing && (
        <input
          data-testid="multiple-select-chip-input"
          value={value.value}
          className={`bg-transparent ring-0 outline-none w-32 ${inputClassName}`}
          onChange={(e) => onValueChangedCallback(e.target.value || "")}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={onBlur}
        />
      )}

      <LucideX
        size={16}
        data-testid="remove-chip"
        className={`
          absolute 
          top-3 
          right-3 
          cursor-pointer
          ${showError ? "text-system-error" : ""}
        `}
        onClick={removeChip}
      />
    </div>
  );
};
