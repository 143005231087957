import { FC, ReactNode } from "react";
import { HeroIllustrationImage } from "components/image/HeroIllustrationImage";

interface CenteredPageWithHeroIllustrationImageOnBottomRightProps {
  children: ReactNode;
}

const CenteredPageWithHeroIllustrationImageOnBottomRight: FC<
  CenteredPageWithHeroIllustrationImageOnBottomRightProps
> = ({ children }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      {children}

      <HeroIllustrationImage />
    </div>
  );
};

export default CenteredPageWithHeroIllustrationImageOnBottomRight;
