import { FirestoreError } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ErrorProps {
  errorProductsRead: FirestoreError | undefined;
  errorGenerate: FirestoreError | undefined;
  errorUpdate: Error | undefined;
}

export const useProjectDetailsError = ({
  errorProductsRead,
  errorGenerate,
  errorUpdate,
}: ErrorProps) => {
  const { t } = useTranslation(["projectDetails"]);
  const [error, setError] = useState<{ message: string; title: string }>();
  const resetError = useCallback(() => {
    setError(undefined);
  }, []);

  useEffect(() => {
    if (errorProductsRead) {
      return setError({
        title: t("projectDetails:errorToast.errorProductsRead"),
        message: errorProductsRead.message,
      });
    }
    if (errorGenerate) {
      return setError({
        title: t("projectDetails:errorToast.errorGenerate"),
        message: errorGenerate.message,
      });
    }
    if (errorUpdate) {
      return setError({
        title: t("projectDetails:errorToast.errorUpdate"),
        message: errorUpdate.message,
      });
    }
    setError(undefined);
  }, [errorProductsRead, errorGenerate, errorUpdate, setError, t]);

  return { error, resetError };
};
