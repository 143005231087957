import React from "react";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { Icon } from "components/icon/Icon";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";

export interface DetailsGenerateBookDescriptionProps {
  specialDetails: string | null;
  setSpecialDetails: (specialDetails: string | null) => void;
}

export const AdditionalInfo: React.FC<DetailsGenerateBookDescriptionProps> = ({
  specialDetails,
  setSpecialDetails,
}) => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <div className="grid gap-3">
      <Label className="flex items-center gap-2 text-s">
        {t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.special",
        )}

        <Tooltip>
          <TooltipTrigger className="text-secondary-400">
            <Icon name="info" size="small" />
          </TooltipTrigger>
          <TooltipContent
            side="bottom"
            className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
            sideOffset={6}
          >
            <TooltipArrow
              className="fill-white drop-shadow-sm"
              width={12}
              height={6}
            />
            <p className="whitespace-pre">
              {t(
                "productDetails:bookDescription.tabGenerate.detailsGenerate.specialTooltip",
              )}
            </p>
          </TooltipContent>
        </Tooltip>
      </Label>

      <Textarea
        rows={7}
        className="placeholder:text-secondary-400 placeholder:text-s text-s"
        value={specialDetails || ""}
        placeholder={t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.specialPlaceholder",
        )}
        role="textbox"
        onChange={(event) => setSpecialDetails(event.target.value)}
        data-testid="special-details-textfield"
      />
    </div>
  );
};
