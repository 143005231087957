import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Warning } from "components/icon/Warning";
import { Button } from "components/button";
import { IconProps } from "components/icon/Icon";

interface WarningModalProps {
  id: string;
  open: boolean;
  title: string;
  description: string;
  confirm: string;
  confirmIcon: IconProps["name"];
  cancel: string;

  onOpenChange(open: boolean): void;

  onConfirm(): void;
}

export function WarningModal({
  id,
  open,
  onOpenChange,
  onConfirm,
  title,
  description,
  confirm,
  confirmIcon,
  cancel,
}: WarningModalProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="py-4 px-4 w-auto" data-testid={`${id}-modal`}>
        <DialogHeader className="pb-4">
          <div className="mb-4">
            <Warning className="h-12 w-12 p-3" />
          </div>

          <DialogTitle className="pb-1">{title}</DialogTitle>
          <DialogDescription className="text-m text-secondary-400 font-light">
            {description}
          </DialogDescription>
        </DialogHeader>

        <div className="flex mx-auto items-center justify-center space-x-3">
          <DialogClose asChild>
            <Button
              testId={`${id}-modal-cancel-button`}
              label={cancel}
              variant="tertiary"
              size="small"
            />
          </DialogClose>
          <Button
            testId={`${id}-modal-confirm-button`}
            label={confirm}
            size="small"
            icon={confirmIcon}
            onClick={onConfirm}
            autoFocus
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
