import { useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
  watchUserGenerations,
  Generation,
} from "integrations/firebase/collections";
import { logError } from "shared/services/ErrorReporting";
import { FirestoreError } from "firebase/firestore";
import { useAuth } from "contexts/AuthContext";

export const useGenerations = () => {
  const [generations, setGenerations] = useState<Generation[]>([]);
  const { userData } = useAuth();
  const [isLoadingRead, setIsLoadingRead] = useState(true);

  const [errorRead, setErrorRead] = useState<FirestoreError>();

  const subscribeGenerations = useCallback(() => {
    if (!userData?.id) {
      setIsLoadingRead(false);
      return;
    }
    setIsLoadingRead(true);
    setErrorRead(undefined);

    setGenerations([]);

    const unsubscribeFunctions: (() => void)[] = [];

    const handleError = (err: FirestoreError) => {
      setGenerations(
        (currentGenerations: undefined | Generation[]) =>
          currentGenerations ?? [],
      );
      logError(err);
      setErrorRead(err as FirestoreError);
      setIsLoadingRead(false);
    };

    const unsubscribe = watchUserGenerations(
      userData.id,
      (gens: Generation[]) => {
        setGenerations(gens);
        setIsLoadingRead(false);
      },
      handleError,
    );
    unsubscribeFunctions.push(unsubscribe);

    return () => {
      unsubscribeFunctions.forEach((unsub) => unsub());
    };
  }, [userData?.id]);

  useDeepCompareEffect(() => {
    if (!userData?.id) {
      setIsLoadingRead(false);
      return;
    }

    const unsubscribe = subscribeGenerations();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [subscribeGenerations]);

  return {
    isLoadingRead,
    errorRead,
    generations,
  };
};
