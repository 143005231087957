import { Table } from "@tanstack/react-table";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  selectEnabled?: boolean;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  onSetPage?: (index: number) => void;
}

interface Page {
  page: number | string;
  key: string;
}

export function DataTablePagination<TData>({
  table,
  selectEnabled = false,
  onNextPage,
  onPreviousPage,
  onSetPage,
}: DataTablePaginationProps<TData>) {
  const { t } = useTranslation("general");

  const onPrev = () => {
    if (onPreviousPage) {
      onPreviousPage();
    }
    table.previousPage();
  };

  const onNext = () => {
    if (onNextPage) {
      onNextPage();
    }
    table.nextPage();
  };

  const setTableIndex = (index: number) => {
    if (onSetPage) {
      onSetPage(index);
    }
    table.setPageIndex(index);
  };

  const currentPage = table.getState().pagination.pageIndex;
  const pageCount = table.getPageCount();

  const getPageNumbers = () => {
    const pages: Page[] = [];
    if (pageCount <= 3) {
      for (let i = 0; i < pageCount; i += 1) {
        pages.push({ page: i, key: `page-${i}` });
      }
    } else {
      pages.push({ page: 0, key: "page-0" });
      if (currentPage > 1) {
        pages.push({ page: "...", key: "page-ellipsis-1" });
      }
      if (currentPage > 0 && currentPage < pageCount - 1) {
        pages.push({ page: currentPage, key: `page-${currentPage}` });
      }
      if (currentPage < pageCount - 2) {
        pages.push({ page: "...", key: "page-ellipsis-2" });
      }
      pages.push({ page: pageCount - 1, key: `page-${pageCount - 1}` });
    }
    return pages;
  };

  const uniquePages = (pages: Page[]) =>
    pages.reduce(
      (acc, current) => {
        const keyExists = acc.find((item) => item.key === current.key);
        if (!keyExists) {
          acc.push(current);
        }
        return acc;
      },
      [] as { page: number | string; key: string }[],
    );

  const pageNumbers = uniquePages(getPageNumbers());

  return (
    <div className="flex items-center justify-between px-2">
      {selectEnabled && (
        <div className="flex-1 text-sm text-muted-foreground">
          {`${table.getFilteredSelectedRowModel().rows.length} of ${table.getFilteredRowModel().rows.length} row(s) selected.`}
        </div>
      )}
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <Button
            variant="ghost"
            icon="chevron-left"
            className="text-secondary-500"
            onClick={onPrev}
            disabled={!table.getCanPreviousPage()}
            label={t("buttons.prev")}
            testId="button-pagination-prev"
          />
          {pageNumbers.map((page) =>
            typeof page.page === "string" ? (
              <span key={page.key} className="text-secondary-500">
                ...
              </span>
            ) : (
              <Button
                key={page.key}
                variant="ghost"
                className={`${currentPage === page.page ? "bg-secondary-50" : "bg-transparent"} text-secondary-500 h-8 w-8 p-0 rounded-md`}
                onClick={() => setTableIndex(page.page as number)}
                label={`${(page.page as number) + 1}`}
              />
            ),
          )}
          <Button
            variant="ghost"
            className="text-secondary-500"
            icon="chevron-right"
            iconPosition="right"
            onClick={onNext}
            disabled={!table.getCanNextPage()}
            label={t("buttons.next")}
            testId="button-pagination-next"
          />
        </div>
      </div>
    </div>
  );
}
