import { icons, LucideProps } from "lucide-react";
import { cn } from "utils";
import { type KebabCase } from "utils/types";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import { useMemo } from "react";

export type IconName = keyof typeof icons;

export interface IconProps extends Omit<LucideProps, "ref"> {
  name: KebabCase<IconName>;
  iconClassName?: string;
  size?: IconSizes;
}

const sizeIcons = {
  xsmall: 14,
  small: 16,
  medium: 18,
  large: 26,
};

export type IconSizes = keyof typeof sizeIcons;

export const Icon = ({
  name,
  size = "medium",
  iconClassName,
  ...props
}: IconProps) => {
  const iconName = useMemo(
    () => startCase(camelCase(name)).replace(/ /g, "") as IconName,
    [name],
  );

  if (!iconName) {
    return null;
  }

  const LucideIcon = icons[iconName];

  const iconSize = sizeIcons[size];

  if (!LucideIcon) {
    return (
      <div
        style={{
          background: "transparent",
          width: iconSize,
          height: iconSize,
        }}
      />
    );
  }

  return (
    <LucideIcon
      size={iconSize}
      style={{ width: iconSize }}
      {...props}
      className={cn("grow-0 shrink-0", iconClassName)}
    />
  );
};
