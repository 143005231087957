import * as React from "react";

import { cn } from "utils";
import { onClickWithoutSelection } from "../../utils/events";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto rounded-xl border border-secondary-200 text-s">
    <table
      ref={ref}
      className={cn(
        "w-full caption-bottom text-sm text-secondary-600 ",
        className,
      )}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("bg-secondary-50  [&_tr]:border-b", className)}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className,
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "border-b transition-colors text-secondary-600 border-secondary-50  hover:bg-secondary-25 data-[state=selected]:bg-secondary-25 duration-100",
      className,
    )}
    {...props}
    onClick={onClickWithoutSelection(props.onClick)}
  />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-12 px-4 text-left align-middle font-medium text-secondary-900 [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "h-12 p-4 align-middle font-normal [&:has([role=checkbox])]:pr-0 whitespace-nowrap",
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

interface TablePreHeaderProps extends React.HTMLAttributes<HTMLTableElement> {
  className?: string;
  headerClassName?: string;
  rowClassName?: string;
  headClassName?: string;
  colSpan?: number;
}

const TablePreHeader = React.forwardRef<HTMLTableElement, TablePreHeaderProps>(
  ({ className, headerClassName, rowClassName, colSpan, ...props }, ref) => (
    <TableHeader className={cn("bg-white", headerClassName)}>
      <TableRow className={cn("hover:bg-white", rowClassName)}>
        <TableHead colSpan={colSpan}>
          <div
            ref={ref}
            className={cn(
              "w-full flex flex-row justify-between py-6 px-2",
              className,
            )}
            {...props}
          />
        </TableHead>
      </TableRow>
    </TableHeader>
  ),
);
TablePreHeader.displayName = "TablePreHeader";

export {
  Table,
  TablePreHeader,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
