import { useAuth } from "contexts/AuthContext";
import { useMemo } from "react";

export const useOrganisation = () => {
  const { userData } = useAuth();
  const organisation = useMemo(
    () => userData?.organisation ?? null,
    [userData],
  );

  return organisation;
};
