import { collection, QueryDocumentSnapshot } from "firebase/firestore";

import { db } from "integrations/firebase/firestore";

export interface IOrganisation {
  id: string;
  name: string;
  targets?: string[];
}

const organisationConverter = {
  toFirestore(organisation: IOrganisation) {
    return organisation;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IOrganisation {
    const documentData = snapshot.data();
    const { name, targets } = documentData;

    const organisation = {
      id: snapshot.id,
      name,
      targets,
    };
    return organisation;
  },
};

export const getOrganisationsCollection = () =>
  collection(db, "organisations").withConverter(organisationConverter);
