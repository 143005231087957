/**
 * This class is responsible for handling the clipboard.
 * It's helpful to read about the Clipboard API: https://w3c.github.io/clipboard-apis/
 * and the ClipboardItem interface: https://developer.mozilla.org/en-US/docs/Web/API/ClipboardItem
 */

/**
 * Before it strips all html tags (via DOMParser), we add line breaks.
 * @param textToCopy html text
 * @returns plain text
 */
const toPlainText = (textToCopy: string): string => {
  const html = textToCopy
    .replace(/<p>/g, "")
    .replace(/<\/p>/g, "\n\n")
    .replace(/<br>/g, "\n");
  return (
    new DOMParser().parseFromString(html, "text/html").documentElement
      ?.textContent || ""
  );
};

/**
 * Google Sheets has no stylesheets for <strong> and <em> tags.
 * It's good practice to replace all tags with simpler ones.
 * This function simplifies the html text to be copied to the clipboard.
 * @param textToCopy html text
 * @returns simplified html text
 */
const toHtml = (textToCopy: string): string => {
  const simplifiedTagsForGoogleSheets = textToCopy
    .replace(/<strong>/g, "<b>")
    .replace(/<\/strong>/g, "</b>")
    .replace(/<em>/g, "<i>")
    .replace(/<\/em>/g, "</i>");
  return simplifiedTagsForGoogleSheets;
};

/**
 * This function writes the text to the clipboard, which expects Blob objects.
 * Clipboards' mime types are limited to text/plain and text/html in this case,
 * as CSV would lose formatting and RTF is not supported by the Clipboard API.
 * @param textToCopy text to be copied to the clipboard
 * @returns void
 */
const writeToClipboard = async (textToCopy: string): Promise<void> => {
  const html = toHtml(textToCopy);
  const htmlBlob = new Blob([html], { type: "text/html" });
  const plainText = toPlainText(textToCopy);
  const plainTextBlob = new Blob([plainText], { type: "text/plain" });

  const clipboardItem = new ClipboardItem({
    [htmlBlob.type]: htmlBlob,
    [plainTextBlob.type]: plainTextBlob,
  });

  await navigator.clipboard.write([clipboardItem]);
};

export { writeToClipboard, toPlainText, toHtml };
