import { EditorContent, useEditor } from "@tiptap/react";
import { generateHTML } from "@tiptap/core";

import { PropsWithChildren, useEffect } from "react";
import { extensions } from "./extensions";
import { IKeywords } from "./extensions/Keywords";
import { EditorActions } from "./EditorActions";
import { EditorProvider } from "./EditorProvider";

interface EditorProps {
  initialValue?: string;
  disabled?: boolean;
  keywords?: IKeywords;

  onChange?(value: string): void;
}

export function Editor({
  initialValue,
  disabled,
  keywords,
  onChange,
  children,
}: PropsWithChildren<EditorProps>) {
  const editor = useEditor({
    extensions,
    content: initialValue,
    editable: !disabled,
    async onUpdate(props) {
      if (!props.transaction.docChanged) {
        return;
      }

      const html = generateHTML(props.transaction.doc.toJSON(), extensions);

      onChange?.(html);
    },
  });

  useEffect(() => {
    editor?.commands.setKeywords(keywords);
  }, [keywords, editor]);

  return (
    <div className="flex-1 flex flex-col">
      <EditorProvider editor={editor}>
        <EditorContent
          editor={editor}
          className="flex-1 prose px-4 max-w-none"
          data-testid="editor"
        />
        <EditorActions editor={editor} />
        {children}
      </EditorProvider>
    </div>
  );
}
