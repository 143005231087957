import { Icon, IconSizes } from "./Icon";
import { cn } from "../../utils";

interface WarningIconProps {
  className?: string;
  iconSize?: IconSizes;
  pulse?: boolean;
}

export const Warning = ({
  className,
  iconSize = "large",
  pulse = true,
}: WarningIconProps) => {
  return (
    <div
      className={cn(
        "relative rounded-full flex p-2.5 justify-center items-center bg-icon-warning-background",
        !pulse && "ring-4 ring-system-warning ring-opacity-5",
        pulse && "p-4",
        className,
      )}
    >
      {pulse && (
        <div className="absolute inset-0 bg-system-warning bg-opacity-25 rounded-full animate-ping duration-2000" />
      )}

      <Icon
        className="absolute"
        name="triangle-alert"
        size={iconSize}
        iconClassName="h-full w-full text-system-warning"
      />
    </div>
  );
};
