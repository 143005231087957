import { Skeleton } from "components/ui/skeleton";
import { useTranslation } from "react-i18next";

const getRandomLength = (min: number = 40, max: number = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const LoadingGenerateDescription = () => {
  const { t } = useTranslation(["productDetails"]);
  return (
    <div className="flex-1 flex flex-col items-left justify-start pt-6 h-full px-4 gap-2 relative">
      <Skeleton
        animated={false}
        className="h-4 w-full bg-secondary-200 animate-expand-typing-width"
      />

      <div style={{ width: `${getRandomLength(20, 80)}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-200 animate-expand-typing-width-linear delay-3000"
        />
      </div>
      <div style={{ width: getRandomLength() }}>
        <Skeleton animated={false} className="h-4 w-1 bg-transparent" />
      </div>
      <div style={{ width: `${getRandomLength(90, 100)}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width delay-6000 ease-linear"
        />
      </div>
      <div style={{ width: `${getRandomLength(90, 100)}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width-linear delay-9000"
        />
      </div>
      <div style={{ width: `${getRandomLength()}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width delay-12000"
        />
      </div>
      <div style={{ width: getRandomLength() }}>
        <Skeleton animated={false} className="h-4 w-1 bg-transparent" />
      </div>
      <div style={{ width: `${getRandomLength(90, 100)}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width-linear delay-15000"
        />
      </div>
      <div style={{ width: `${getRandomLength(90, 100)}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width delay-18000"
        />
      </div>
      <div style={{ width: `${getRandomLength()}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width delay-21000"
        />
      </div>
      <div style={{ width: getRandomLength() }}>
        <Skeleton animated={false} className="h-4 w-1 bg-transparent" />
      </div>
      <div style={{ width: `${getRandomLength(90, 100)}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width-linear delay-24000"
        />
      </div>
      <div style={{ width: `${getRandomLength(90, 100)}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width-linear delay-27000"
        />
      </div>
      <div style={{ width: `${getRandomLength()}%` }}>
        <Skeleton
          animated={false}
          className="h-4 w-0 bg-secondary-100 animate-expand-typing-width delay-30000"
        />
      </div>
      <div className="absolute inset-x-0 inset-y-0 flex justify-center items-center">
        <p className="mt-4 text-center text-secondary-500 -translate-y-6">
          {t("bookDescription.tabGenerate.loadingGenerateDescription")}
        </p>
      </div>
    </div>
  );
};
