import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import uniq from "lodash/uniq";
import { IProduct } from "integrations/firebase/collections";
import {
  doc,
  FieldValue,
  FirestoreDataConverter,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../integrations/firebase/firestore";

interface ProductContextType {
  product?: IProduct;
  loading?: boolean;
  error?: string;

  onUpdateKeywords(keywords: string[] | FieldValue): Promise<void>;
}

export const ProductContext = createContext<ProductContextType>(
  undefined as never,
);

interface ProductProviderProps {
  id: string;
}

const productConverter: FirestoreDataConverter<IProduct> = {
  fromFirestore(snapshot) {
    const data = snapshot.data() as IProduct;
    return {
      ...data,
      id: snapshot.id,
      isbn: snapshot.id,
    };
  },
  toFirestore: (data) => data,
};

export function ProductProvider({
  id,
  children,
}: PropsWithChildren<ProductProviderProps>) {
  const [product, loading, error, snapshot] = useDocumentData(
    doc(db, "productsNew", id).withConverter(productConverter),
  );

  const onUpdateKeywords = useCallback(
    async (keywords: string[] | FieldValue) => {
      if (!snapshot?.ref) {
        throw new Error("Unable to find product");
      }

      await updateDoc(snapshot.ref, {
        finalKeywords: Array.isArray(keywords) ? uniq(keywords) : keywords,
        updatedAt: serverTimestamp(),
      });
    },
    [snapshot?.ref],
  );

  const value = useMemo(
    () => ({
      product,
      loading,
      error: error?.message,
      onUpdateKeywords,
    }),
    [product, loading, error?.message, onUpdateKeywords],
  );

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
}

export const useProduct = () => useContext(ProductContext);
