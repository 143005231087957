import { collection, QueryDocumentSnapshot } from "firebase/firestore";

import { db } from "integrations/firebase/firestore";
import { IOrganisation } from "./organisations";

interface IUserSettings {
  visitedSearchTermsPage?: boolean;
}

export interface IUser {
  id: string;
  userId: string;
  email: string;
  emailVerified: boolean;
  familyName: string;
  givenName: string;
  organisation: IOrganisation;
  settings?: IUserSettings;
}

const userConverter = {
  toFirestore(user: IUser) {
    console.info("toFirestore user", user);
    return user;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IUser {
    const documentData = snapshot.data();
    const {
      userId,
      email,
      emailVerified,
      familyName,
      givenName,
      organisation,
      settings,
    } = documentData;

    const user = {
      id: snapshot.id,
      userId,
      email,
      emailVerified,
      familyName,
      givenName,
      organisation,
      settings,
    };
    return user;
  },
};

export const getUsersCollection = () =>
  collection(db, "users").withConverter(userConverter);
