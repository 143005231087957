import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { IProduct } from "integrations/firebase/collections";
import { formatFirestoreTimestamp } from "shared/utils";
import { logError } from "./ErrorReporting";

enum ExportProductColumns {
  Isbn = "ISBN",
  Title = "Title",
  Author = "Author",
  Publisher = "Publisher",
  PublishedDate = "Published date",
  Genre = "Genre",
  Form = "Form",
  DatabaseDescription = "Database Description",
  GeneratedDescription = "Generated Description",
  DatabaseKeywords = "Database Keywords",
  GeneratedKeywords = "Generated Keywords",
  FinalList = "Final List",
}

const pipeTransformations = (
  value: IProduct,
  functions: Array<(arg: any) => any>,
): any => {
  try {
    const result = functions.reduce((currentValue, currentFunction) => {
      const transformedValue = currentFunction(currentValue);

      if (transformedValue === null || transformedValue === undefined) {
        throw new Error("Value is null or undefined");
      }

      return transformedValue;
    }, value);

    return result;
  } catch (e) {
    logError(e);
    return "";
  }
};

const formatProducts = (products: IProduct[]) => {
  const EMPTY_SPACE = " ";

  return products.map((product) => ({
    [ExportProductColumns.Isbn]: pipeTransformations(product, [
      (p: IProduct) => p.isbn ?? EMPTY_SPACE,
    ]),
    [ExportProductColumns.Title]: pipeTransformations(product, [
      (p: IProduct) => p.title ?? EMPTY_SPACE,
    ]),
    [ExportProductColumns.Author]: pipeTransformations(product, [
      (p: IProduct) => p.author ?? [EMPTY_SPACE],
      (author: string[]) => (author.length ? author.join(";") : EMPTY_SPACE),
    ]),
    [ExportProductColumns.Publisher]: pipeTransformations(product, [
      (p: IProduct) => p.publisher ?? EMPTY_SPACE,
    ]),
    [ExportProductColumns.PublishedDate]: pipeTransformations(product, [
      (p: IProduct) => formatFirestoreTimestamp(p.publishedAt) ?? EMPTY_SPACE,
    ]),
    [ExportProductColumns.Genre]: pipeTransformations(product, [
      (p: IProduct) => (p.genre?.length ? p.genre.join(";") : EMPTY_SPACE),
    ]),
    [ExportProductColumns.Form]: pipeTransformations(product, [
      (p: IProduct) => p.productGroupDescription ?? EMPTY_SPACE,
    ]),
    [ExportProductColumns.DatabaseDescription]: pipeTransformations(product, [
      (p: IProduct) => p.summary || EMPTY_SPACE,
    ]),
    [ExportProductColumns.GeneratedDescription]: pipeTransformations(product, [
      (p: IProduct) => p.generated?.description?.data.text || EMPTY_SPACE,
    ]),
    [ExportProductColumns.DatabaseKeywords]: pipeTransformations(product, [
      (p: IProduct) =>
        p.keywords?.length ? p.keywords.join(";") : EMPTY_SPACE,
    ]),
    [ExportProductColumns.GeneratedKeywords]: pipeTransformations(product, [
      (p: IProduct) =>
        p.generated?.keywords?.data?.length
          ? p.generated?.keywords?.data.join(";")
          : EMPTY_SPACE,
    ]),
    [ExportProductColumns.FinalList]: pipeTransformations(product, [
      (p: IProduct) =>
        p.finalKeywords?.length ? p.finalKeywords.join(";") : EMPTY_SPACE,
    ]),
  }));
};

export const SaveProductsAsExcel = async (
  products: IProduct[],
  filename: string,
) => {
  const headers = [
    ExportProductColumns.Isbn,
    ExportProductColumns.Title,
    ExportProductColumns.Author,
    ExportProductColumns.Publisher,
    ExportProductColumns.PublishedDate,
    ExportProductColumns.Genre,
    ExportProductColumns.Form,
    ExportProductColumns.DatabaseDescription,
    ExportProductColumns.GeneratedDescription,
    ExportProductColumns.DatabaseKeywords,
    ExportProductColumns.GeneratedKeywords,
    ExportProductColumns.FinalList,
  ];

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportData = formatProducts(products);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("data");
  const headerRow = worksheet.addRow(headers);

  headerRow.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.font = { bold: true };
  });

  exportData.forEach((data) => {
    const row = headers.map((header) => data[header]);
    worksheet.addRow(row);
  });

  const excelBuffer = await workbook.xlsx.writeBuffer();
  const dataBlob = new Blob([excelBuffer], { type: fileType });

  saveAs(dataBlob, filename + fileExtension);
};
