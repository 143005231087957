export const getErrorMessage = (e: unknown): string => {
  if (!e) {
    return "An unknown error occurred";
  }

  if (typeof e === "string") {
    return e;
  }
  if (typeof e !== "object") {
    return "An unknown error occurred";
  }
  if ("message" in e && typeof e.message === "string") {
    return e.message;
  }

  if ("data" in e) {
    return getErrorMessage(e.data);
  }

  if ("toString" in e && typeof e.toString === "function") {
    return e.toString();
  }

  return "An unknown error occurred";
};
