import { ExternalToast, toast as sonner } from "sonner";
import { XIcon } from "lucide-react";

type Options = ExternalToast & {
  onAction?(): void;
};

const DEFAULT_DURATION = 4000;

export const toast = {
  success(message: string, options?: Options) {
    const id = sonner.success(message, {
      duration: DEFAULT_DURATION,
      action: {
        label: <XIcon />,
        onClick: options?.onAction || (() => sonner.dismiss(id)),
      },
      ...options,
    });
  },
  warning(message: string, options?: Options) {
    const id = sonner.warning(message, {
      duration: DEFAULT_DURATION,
      action: {
        label: <XIcon className="w-5 h-5" />,
        onClick: options?.onAction || (() => sonner.dismiss(id)),
      },
      ...options,
    });
  },
  error(message: string, options?: Options) {
    const id = sonner.error(message, {
      duration: DEFAULT_DURATION,
      action: {
        label: <XIcon className="w-5 h-5" />,
        onClick: options?.onAction || (() => sonner.dismiss(id)),
      },
      ...options,
    });
  },
  info(message: string, options?: ExternalToast) {
    const id = sonner.info(message, {
      duration: DEFAULT_DURATION,
      cancel: {
        label: <XIcon className="w-5 h-5" />,
        onClick() {
          sonner.dismiss(id);
        },
      },
      ...options,
    });
  },
};
