import { Badge } from "components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { formatFirestoreTimestamp } from "shared/utils";
import { useProduct } from "contexts/ProductContext";

export const DetailsDropdown = () => {
  const { product } = useProduct();
  const { publisher, publishedAt, updatedAt, genre } = product ?? {};
  const [firstGenre] = genre ?? [];

  const { t } = useTranslation(["productDetails"]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger data-testid="details-dropdown-trigger">
        <div className="ui-btn-size-small py-1 px-1 cursor-pointer text-sm items-center font-medium whitespace-nowrap flex-shrink-0 rounded-lg border border-secondary-200 bg-white text-secondary-600 shadow-sm hover:bg-secondary-25 hover:border-secondary-300 focus:shadow-outline-secondary">
          <ChevronDown size={16} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className="p-2">
          <span className="flex flex-row items-center gap-1">
            <p className="text-s font-medium">
              {`${t("productDetails:productSummary.publisher")}:`}
            </p>
            <Badge className="text-s">{publisher}</Badge>
          </span>
          <span className="flex flex-row items-center gap-1 mt-2">
            <p className="text-s font-medium">
              {`${t("productDetails:productSummary.publishedDate")}:`}
            </p>
            <p className="text-s">{formatFirestoreTimestamp(publishedAt)}</p>
          </span>
          <span className="flex flex-row items-center gap-1 mt-2">
            <p className="text-s font-medium">
              {`${t("productDetails:productSummary.updatedDate")}:`}
            </p>
            <p className="text-s">{formatFirestoreTimestamp(updatedAt)}</p>
          </span>
          <span className="flex flex-row items-center gap-1 mt-2">
            <p className="text-s font-medium">
              {`${t("productDetails:productSummary.genre")}:`}
            </p>
            <p className="text-s">{firstGenre}</p>
          </span>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
