import React, { SetStateAction, Dispatch } from "react";
import {
  IsbnMultipleSelectChip,
  IMultipleSelectChipValue,
} from "components/select/IsbnMultipleSelectChip";
import ModalOverlayLoadingSpinner from "components/loading/ModalOverlayLoadingSpinner";
import { useTranslation } from "react-i18next";
import { transformIsbn } from "shared/services/IsbnService";

type ProjectFormProps = {
  setSelectedIsbns: Dispatch<SetStateAction<string[]>>;
  loading: boolean;
  selectedIsbns: string[];
};

export const ProjectForm: React.FC<ProjectFormProps> = ({
  loading,
  selectedIsbns,
  setSelectedIsbns,
}) => {
  const { t } = useTranslation(["general", "projects"]);

  const onValueChange = (values: IMultipleSelectChipValue[]) => {
    setSelectedIsbns(values.map((sv) => transformIsbn(sv.value)));
  };

  return (
    <>
      <ModalOverlayLoadingSpinner
        textTop={t("projects:isbnProjectForm.searching")}
        isLoading={loading}
      />
      <IsbnMultipleSelectChip
        values={selectedIsbns}
        onValuesChangedCallback={onValueChange}
        showErrors
        chipInputClassName="!h-6"
      />
    </>
  );
};
