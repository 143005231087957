import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useLaunchDarkly } from "integrations/launch-darkly/LaunchDarklyContext";
import { FeatureFlags } from "integrations/launch-darkly/utils";
import { useAuth } from "./AuthContext";

const clientSideID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID;
const isTesting = import.meta.env.TEST;
if (!clientSideID && !isTesting) {
  console.warn(
    "VITE_LAUNCH_DARKLY_CLIENT_ID is not set, feature flags will not work",
  );
}

interface IFeatureFlagContext {
  features: FeatureFlags;
}

export const FeatureFlagContext = createContext<IFeatureFlagContext>(
  undefined as never,
);

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const { authUser, organisation } = useAuth();
  const { features, setContext, clearContext } = useLaunchDarkly();

  useEffect(() => {
    if (!authUser) {
      clearContext();
      return;
    }

    setContext("user", {
      key: authUser.uid,
      email: authUser.email ?? undefined,
      name: authUser.displayName ?? undefined,
      avatar: authUser.photoURL ?? undefined,
    });
  }, [authUser, organisation, setContext, clearContext]);

  useEffect(() => {
    if (!organisation) {
      setContext("organisation", {
        anonymous: true,
      });

      return;
    }
    setContext("organisation", {
      key: organisation.id,
      name: organisation.name,
    });
  }, [organisation, setContext]);

  const value = useMemo(() => ({ features }), [features]);

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const ctxt = useContext(FeatureFlagContext);
  if (!ctxt) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagProvider",
    );
  }
  return ctxt;
};
