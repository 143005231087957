import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "contexts/AnalyticsContext";
import { useProductActions } from "hooks/useProductActions";
import { logError } from "shared/services/ErrorReporting";
import { toast } from "../utils/toast";
import { useProduct } from "./ProductContext";

interface BookDescriptionContextType {
  isRegenerateBookDescriptionModalOpen: boolean;
  infoToast: string | undefined;
  showDetailsGenerateBookDescription: boolean;
  selectedTarget: string | null;
  specialDetails: string | null;
  generateBookDescription: () => Promise<void>;
  onCloseRegenerateBookDescriptionModal: () => void;
  setSelectedTarget: (selectedTarget: string | null) => void;
  setSpecialDetails: (specialDetails: string | null) => void;
  handleRegenerateBookDescriptionOpenModal: () => void;
  handleCopyDoneDatabaseBookDescription: () => void;
  handleCopyDoneGeneratedBookDescription: () => void;
  onToastClose: () => void;
  onUpdateGeneratedDescription: (text: string) => Promise<void>;
  setShowDetailsGenerateBookDescription: (
    showDetailsGenerateBookDescription: boolean,
  ) => void;
}

export const BookDescriptionContext = createContext<BookDescriptionContextType>(
  undefined as never,
);

export const useBookDescription = () => useContext(BookDescriptionContext);

export const BookDescriptionProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation(["general", "products"]);
  const { product } = useProduct();
  const { gaEvent } = useAnalytics();
  const [
    isRegenerateBookDescriptionModalOpen,
    setIsRegenerateBookDescriptionModalOpen,
  ] = useState(false);
  const [infoToast, setInfoToast] = useState<string>();
  const [
    showDetailsGenerateBookDescription,
    setShowDetailsGenerateBookDescription,
  ] = useState(false);
  const { generateProductDescription, setProductDescription } =
    useProductActions();
  const onCloseRegenerateBookDescriptionModal = useCallback(() => {
    setIsRegenerateBookDescriptionModalOpen(false);
  }, []);
  const [selectedTarget, setSelectedTarget] = useState<string | null>(
    product?.generated?.description?.data.target || null,
  );
  const [specialDetails, setSpecialDetails] = useState<string | null>(
    product?.generated?.description?.data.details || null,
  );

  useEffect(() => {
    setSelectedTarget(product?.generated?.description?.data.target || null);
    setSpecialDetails(product?.generated?.description?.data.details || null);
  }, [
    product?.generated?.description?.data.details,
    product?.generated?.description?.data.target,
  ]);

  const generateBookDescription = useCallback(async () => {
    setShowDetailsGenerateBookDescription(false);
    if (!product) {
      logError("Product not found while generating description");
      return;
    }
    await generateProductDescription(
      product,
      selectedTarget || "",
      specialDetails || "",
    );
  }, [product, selectedTarget, specialDetails, generateProductDescription]);

  const handleRegenerateBookDescriptionOpenModal = useCallback(async () => {
    setIsRegenerateBookDescriptionModalOpen(true);
    setSelectedTarget(product?.generated?.description?.data.target || null);
    setSpecialDetails(product?.generated?.description?.data.details || null);
  }, [
    product?.generated?.description?.data.details,
    product?.generated?.description?.data.target,
  ]);

  const handleCopyDoneDatabaseBookDescription = useCallback(() => {
    toast.success(
      t(
        "products:bookDescription.tabDatabase.toasts.info.copyDoneDatabaseBookDescription",
      ),
    );
  }, [t]);

  const handleCopyDoneGeneratedBookDescription = useCallback(() => {
    toast.success(
      t(
        "products:bookDescription.tabGenerate.toasts.info.copyDoneGeneratedBookDescription",
      ),
    );
    gaEvent({
      type: "generated_description_copied_to_clipboard",
      payload: {
        description: product?.generated?.description?.data.text || "",
        item_id: product?.isbn || "",
        item_name: product?.title || "",
        book: product?.isbn || "",
      },
    });
  }, [t, product, gaEvent]);

  const onToastClose = useCallback(() => {
    setInfoToast(undefined);
  }, []);

  const onUpdateGeneratedDescription = useCallback(
    async (text: string) => {
      if (!product?.id) {
        logError("Product not found while updating description");
        return;
      }

      await setProductDescription(product.id, text);
      gaEvent({
        type: "book_description_manually_edited",
        payload: {
          item_id: product.isbn,
          item_name: product.title,
          book: product.isbn,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product?.id, setProductDescription, gaEvent],
  );

  const authProviderValue = useMemo(
    () => ({
      isRegenerateBookDescriptionModalOpen,
      infoToast,
      showDetailsGenerateBookDescription,
      selectedTarget,
      specialDetails,
      generateBookDescription,
      onCloseRegenerateBookDescriptionModal,
      setSelectedTarget,
      setSpecialDetails,
      handleRegenerateBookDescriptionOpenModal,
      handleCopyDoneDatabaseBookDescription,
      handleCopyDoneGeneratedBookDescription,
      onToastClose,
      onUpdateGeneratedDescription,
      setShowDetailsGenerateBookDescription,
    }),
    [
      isRegenerateBookDescriptionModalOpen,
      infoToast,
      showDetailsGenerateBookDescription,
      selectedTarget,
      specialDetails,
      generateBookDescription,
      onCloseRegenerateBookDescriptionModal,
      setSelectedTarget,
      setSpecialDetails,
      handleRegenerateBookDescriptionOpenModal,
      handleCopyDoneDatabaseBookDescription,
      handleCopyDoneGeneratedBookDescription,
      onToastClose,
      onUpdateGeneratedDescription,
      setShowDetailsGenerateBookDescription,
    ],
  );
  return (
    <BookDescriptionContext.Provider value={authProviderValue}>
      {children}
    </BookDescriptionContext.Provider>
  );
};
