import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
} from "components/ui/popover";

import { Button } from "components/button";
import { usePopover } from "hooks/usePopover";
import { Badge } from "components/ui/badge";
import { ChevronDown, X } from "lucide-react";
import { FilterType, ComparisonOperator, IFilter } from "./keywordFilterTypes";
import { getKeyByValue } from "../../utils";
import { KeywordDropdown } from "./KeywordDropdown";

const FILTER_TYPE = "KEYWORDS";

export const KeywordFilter = ({
  setFilter,
  filter,
}: {
  setFilter: (filter: IFilter | undefined) => void;
  filter: IFilter | undefined;
}) => {
  const { t } = useTranslation(["books"]);
  const [filterValue, setFilterValue] = useState<number>(0);
  const [filterComparisonOperator, setFilterComparisonOperator] =
    useState<FilterType>(FilterType.GREATER);
  const { isOpen: popoverIsOpen, onToggle: popoverOnToggle } = usePopover();

  const applyKeywordFilter = useMemo(() => {
    return () => {
      const newFilter = {
        type: FILTER_TYPE,
        value: filterValue,
        comparisonOperator: getKeyByValue(FilterType, filterComparisonOperator),
        variable: "keywordCount",
      };

      setFilter(newFilter);
    };
  }, [filterValue, filterComparisonOperator, setFilter]);

  const clearKeywordFilter = () => {
    setFilter(undefined);
  };

  const buttonLabel = filter
    ? ` ${ComparisonOperator[
        getKeyByValue(FilterType, filterComparisonOperator)
      ].slice(0, 1)}${filter.value}`
    : "";

  return (
    <div className="flex flex-row items-end mr-2">
      <Popover open={popoverIsOpen} onOpenChange={popoverOnToggle}>
        <PopoverTrigger>
          <div
            className={`ui-btn-size-medium py-3 px-3 text-s items-center font-normal whitespace-nowrap cursor-pointer flex-shrink-0 rounded-lg border border-secondary-300 ${filter ? "bg-secondary-50" : "bg-white"} text-secondary-800 shadow-sm hover:bg-secondary-25 hover:border-secondary-300 focus:shadow-outline-secondary`}
          >
            <div className="flex flex-row gap-2 items-center justify-between">
              <p>{t("books:filter.keywords")}</p>
              {buttonLabel && (
                <Badge
                  className="rounded-md letter px-2 tracking-[0.005em] border-secondary-300 bg-white"
                  variant="outline"
                >
                  {buttonLabel}
                </Badge>
              )}
              <div>
                {popoverIsOpen ? <X size={16} /> : <ChevronDown size={16} />}
              </div>
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent sideOffset={5} align="start">
          <span className="w-full flex flex-row">
            <KeywordDropdown
              setFilterComparisonOperator={setFilterComparisonOperator}
              filterComparisonOperator={filterComparisonOperator}
            />
            <Input
              value={(filterValue && Number(filterValue).toString()) || 0}
              type="number"
              className="border-l-0 rounded-l-none text-sm text-center"
              data-testid="keyword-filter-input"
              onChange={(e) => {
                const value = e.target.value.replace(/^0+/, "");
                setFilterValue(value === "" ? 0 : Number(value));
              }}
            />
          </span>

          <PopoverClose className="w-full mt-2" asChild>
            <span className="flex flex-row w-full justify-end gap-2">
              <Button
                variant="tertiary"
                size="small"
                label={t("books:filter.clear")}
                onClick={clearKeywordFilter}
                analyticsId="button_clear_filter"
              />
              <Button
                size="small"
                label={t("books:filter.apply")}
                onClick={applyKeywordFilter}
                analyticsId="button_apply_filter"
              />
            </span>
          </PopoverClose>
        </PopoverContent>
      </Popover>
    </div>
  );
};
