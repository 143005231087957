import { useAuth } from "contexts/AuthContext";
import { RoutePath } from "shared/constants";
import { useTranslation } from "react-i18next";

import { NavigationBar } from "./NavigationBar";
import { ProfileMenu } from "./profileMenu";
import { Header } from "./Header";

export function AuthHeader() {
  const { t } = useTranslation(["general", "components"]);

  const { authUser } = useAuth();

  const navigationItems = [
    { label: t("components:authHeader.books"), path: RoutePath.Books },
    {
      label: t("components:authHeader.searchTerms"),
      path: RoutePath.SearchTerms,
    },
    { label: t("components:authHeader.projects"), path: RoutePath.Projects },
  ];

  return (
    <Header>
      {authUser && <NavigationBar items={navigationItems} />}
      <ProfileMenu />
    </Header>
  );
}
