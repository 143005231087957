import { useCallback, FC } from "react";
import { validateIsbn } from "shared/services/IsbnService";
import { useTranslation } from "react-i18next";
import { MultipleSelectChip } from "./MultipleSelectChip";
import { IMultipleSelectChipValue } from "./types";

export type { IMultipleSelectChipValue };

interface ChipStyleProps {
  values?: string[];
  onValuesChangedCallback: (values: IMultipleSelectChipValue[]) => void;
  className?: string;
  chipClassName?: string;
  chipInputClassName?: string;
  showErrors?: boolean;
}

export const IsbnMultipleSelectChip: FC<ChipStyleProps> = ({
  values = [],
  onValuesChangedCallback,
  className = "",
  chipClassName = "",
  chipInputClassName = "",
  showErrors = false,
}) => {
  const { t } = useTranslation();
  const validate = useCallback(
    (value: string, all: string[]) => {
      if (!validateIsbn(value))
        return t("components:IsbnMultipleSelectChip.lengthError");
      if (all.filter((val) => val === value).length > 1) {
        return t("components:IsbnMultipleSelectChip.uniqueError");
      }
    },
    [t],
  );

  return (
    <MultipleSelectChip
      values={values}
      validate={validate}
      onValuesChangedCallback={onValuesChangedCallback}
      placeholder={t("components:IsbnMultipleSelectChip.placeholder")}
      className={className}
      chipClassName={chipClassName}
      chipInputClassName={chipInputClassName}
      showErrors={showErrors}
    />
  );
};
