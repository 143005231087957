import { Skeleton } from "components/ui/skeleton";
import { cn } from "utils";

interface SkeletonDescriptionProps {
  primary?: boolean;
  className?: string;
  animated?: boolean;
}

export const SkeletonDescription = ({
  primary = false,
  className,
  animated = true,
}: SkeletonDescriptionProps) => {
  return (
    <div
      className={cn(
        `transition-colors border-[1.15px] rounded-lg p-4 bg-white ${primary ? "border-primary-300 shadow-[0px_6px_12px_0px_#528BFF14]" : "border-secondary-200"} flex flex-col items-center gap-1`,
        className,
      )}
    >
      <Skeleton animated={animated} className="h-4 w-60 bg-secondary-100" />
      <Skeleton animated={false} className="h-2 w-60 bg-transparent" />

      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={false} className="h-2 w-60 bg-transparent" />

      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={false} className="h-2 w-60 bg-transparent" />

      <div className="flex flex-row justify-end w-full">
        <Skeleton animated={animated} className="h-3 w-10 bg-secondary-100" />
      </div>
    </div>
  );
};
