const Google = ({ disabled = false }) => {
  if (disabled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 -10 70 70"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.8989 35.3585C49.7505 35.1508 49.6008 34.9474 49.452 34.7452C48.2422 33.1009 47.0891 31.5337 47.0891 28.3922V16.681C47.0891 16.2739 47.0914 15.8695 47.0938 15.4682C47.1201 10.978 47.1441 6.88068 43.8227 3.74618C40.9746 0.977014 36.2433 0 32.6249 0C25.5481 0 17.6536 2.66866 15.9981 11.5118C15.8228 12.4511 16.4976 12.9428 17.1093 13.081L24.3153 13.8695C24.9901 13.8348 25.4774 13.1642 25.608 12.4888C26.2259 9.44156 28.7498 7.97766 31.5858 7.97766C33.1153 7.97766 34.8521 8.54312 35.7575 9.93011C36.6909 11.313 36.6761 13.1596 36.6628 14.8108C36.6613 15.0032 36.6598 15.1929 36.6598 15.3789V16.3606C35.9911 16.4361 35.2905 16.5077 34.5689 16.5815C30.6391 16.9832 26.0856 17.4487 22.6753 18.9601C18.0194 20.9989 14.75 25.1472 14.75 31.2447C14.75 39.059 19.615 42.9654 25.8787 42.9654C31.1648 42.9654 34.0576 41.704 38.1355 37.5009C38.3392 37.7994 38.5222 38.0745 38.6947 38.3338C39.6675 39.7961 40.3024 40.7505 42.3979 42.513C42.9512 42.8115 43.6831 42.8003 44.1733 42.3512C45.6581 41.0162 48.3542 38.6475 49.8744 37.3596C50.4815 36.8554 50.3739 36.0401 49.8989 35.3585ZM35.2688 31.9814C34.0884 34.1019 32.2084 35.3993 30.1195 35.3993C27.2712 35.3993 25.5988 33.2002 25.5988 29.9472C25.5988 23.5434 31.283 22.3811 36.6583 22.3811C36.6583 22.7643 36.6618 23.1495 36.6653 23.5358C36.6917 26.4347 36.7185 29.3911 35.2688 31.9814Z"
          fill="#71717A"
        />
        <path
          d="M58.7797 50.0958C51.6618 55.4624 41.3393 58.3177 32.4495 58.3177C19.995 58.3177 8.77641 53.6136 0.287518 45.782C-0.378348 45.1662 0.215974 44.3253 1.01595 44.8034C10.176 50.2502 21.5018 53.5289 33.1992 53.5289C41.0911 53.5289 49.7647 51.8569 57.7454 48.3956C58.9494 47.8753 59.9583 49.2083 58.7797 50.0958Z"
          fill="#71717A"
        />
        <path
          d="M53.4298 46.358C55.7216 46.0751 60.8319 45.4491 61.7417 46.6389C62.6526 47.8278 60.7394 52.7167 59.8772 54.9201L59.8709 54.9363C59.6113 55.6007 60.1694 55.8659 60.757 55.3623C64.5769 52.0993 65.5638 45.2593 64.7812 44.2681C64.0045 43.2905 57.3269 42.4441 53.2518 45.3688C52.6255 45.8224 52.7327 46.4406 53.4298 46.358Z"
          fill="#71717A"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 -10 70 70"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8989 35.3585C49.7505 35.1508 49.6008 34.9474 49.452 34.7452C48.2422 33.1009 47.0891 31.5337 47.0891 28.3922V16.681C47.0891 16.2739 47.0914 15.8695 47.0938 15.4682C47.1201 10.978 47.1441 6.88068 43.8227 3.74618C40.9746 0.977014 36.2433 0 32.6249 0C25.5481 0 17.6536 2.66866 15.9981 11.5118C15.8228 12.4511 16.4976 12.9428 17.1093 13.081L24.3153 13.8695C24.9901 13.8348 25.4774 13.1642 25.608 12.4888C26.2259 9.44156 28.7498 7.97766 31.5858 7.97766C33.1153 7.97766 34.8521 8.54312 35.7575 9.93011C36.6909 11.313 36.6761 13.1596 36.6628 14.8108C36.6613 15.0032 36.6598 15.1929 36.6598 15.3789V16.3606C35.9911 16.4361 35.2905 16.5077 34.5689 16.5815C30.6391 16.9832 26.0856 17.4487 22.6753 18.9601C18.0194 20.9989 14.75 25.1472 14.75 31.2447C14.75 39.059 19.615 42.9654 25.8787 42.9654C31.1648 42.9654 34.0576 41.704 38.1355 37.5009C38.3392 37.7994 38.5222 38.0745 38.6947 38.3338C39.6675 39.7961 40.3024 40.7505 42.3979 42.513C42.9512 42.8115 43.6831 42.8003 44.1733 42.3512C45.6581 41.0162 48.3542 38.6475 49.8744 37.3596C50.4815 36.8554 50.3739 36.0401 49.8989 35.3585ZM35.2688 31.9814C34.0884 34.1019 32.2084 35.3993 30.1195 35.3993C27.2712 35.3993 25.5988 33.2002 25.5988 29.9472C25.5988 23.5434 31.283 22.3811 36.6583 22.3811C36.6583 22.7643 36.6618 23.1495 36.6653 23.5358C36.6917 26.4347 36.7185 29.3911 35.2688 31.9814Z"
        fill="#3F3F46"
      />
      <path
        d="M58.7797 50.0958C51.6618 55.4624 41.3393 58.3177 32.4495 58.3177C19.995 58.3177 8.77641 53.6136 0.287518 45.782C-0.378348 45.1662 0.215974 44.3253 1.01595 44.8034C10.176 50.2502 21.5018 53.5289 33.1992 53.5289C41.0911 53.5289 49.7647 51.8569 57.7454 48.3956C58.9494 47.8753 59.9583 49.2083 58.7797 50.0958Z"
        fill="#FFA700"
      />
      <path
        d="M53.4298 46.358C55.7216 46.0751 60.8319 45.4491 61.7417 46.6389C62.6526 47.8278 60.7394 52.7167 59.8772 54.9201L59.8709 54.9363C59.6113 55.6007 60.1694 55.8659 60.757 55.3623C64.5769 52.0993 65.5638 45.2593 64.7812 44.2681C64.0045 43.2905 57.3269 42.4441 53.2518 45.3688C52.6255 45.8224 52.7327 46.4406 53.4298 46.358Z"
        fill="#FFA700"
      />
    </svg>
  );
};

export default Google;
