import * as Sentry from "@sentry/browser";
import { isDev } from "shared/constants/Environment";

const dsn = import.meta.env.VITE_SENTRY_DSN;

if (!isDev) {
  Sentry.init({
    dsn,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ["localhost"],
  });
}

export const logError: typeof Sentry.captureException = (
  exception,
  captureContext,
) => {
  console.error(exception);
  return Sentry.captureException(exception, captureContext);
};

export const logMessage: typeof Sentry.captureMessage = (
  message,
  captureContext,
) => {
  console.info(message);
  return Sentry.captureMessage(message, captureContext);
};
