import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
  createContext,
  PropsWithChildren,
} from "react";
import {
  getProductsByIsbns,
  getProductsByTrendForCountry,
  IProduct,
  IProductsByTrend,
  TRENDS_TYPE,
} from "integrations/firebase/collections";
import { useAuth } from "contexts/AuthContext";
import { logError } from "shared/services/ErrorReporting";
import { Country, Countries } from "shared/constants";

export interface ITrendWithProducts {
  id?: string;
  term: string;
  productsIsbns: string[];
  productsCount: number;
  products: IProduct[] | null;
  projectId?: string | null;
  rank?: number;
}

const trendTypes = [TRENDS_TYPE.GOOGLE, TRENDS_TYPE.AMAZON];
const top3CountryCodes = ["DE", "GB", "US"];

interface ITrendsContext {
  top3CountryCodes: string[];
  countries: Country[];
  selectedCountryCode: string;
  setSelectedCountryCode: (value: string) => void;
  trendTypes: TRENDS_TYPE[];
  selectedTrendsType: TRENDS_TYPE;
  setSelectedTrendsType: (type: TRENDS_TYPE) => void;
  indexCurrentTrendWithProducts: number;
  setIndexCurrentTrendWithProducts: (value: number) => void;
  currentTrendWithProducts: ITrendWithProducts | undefined;
  setCurrentTrendWithProducts: (value: ITrendWithProducts | undefined) => void;
  isLoadingProductsForCurrentTrendWithProducts: boolean;
  canGoNext: boolean;
  goNext: () => void;
  canGoBack: boolean;
  goBack: () => void;
  selectedTrendsWithProducts: ITrendWithProducts[];
  setSelectedTrendsWithProducts: (
    trendsWithProducts: ITrendWithProducts[],
  ) => void;
  trendsWithProducts: ITrendWithProducts[];
  errorTrends: Error | undefined;
  trendsThatHaveProducts: ITrendWithProducts[];
  trends: IProductsByTrend[];
  isLoadingTrends: boolean;
}

export const TrendsContext = createContext<ITrendsContext>(undefined as never);

export const TrendsContextProvider = ({ children }: PropsWithChildren) => {
  const { userData } = useAuth();

  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(
    top3CountryCodes[0],
  );
  const [selectedTrendsType, setSelectedTrendsType] = useState<TRENDS_TYPE>(
    TRENDS_TYPE.GOOGLE,
  );
  const [currentTrendWithProducts, setCurrentTrendWithProducts] =
    useState<ITrendWithProducts>();
  const [
    isLoadingProductsForCurrentTrendWithProducts,
    setIsLoadingProductsForCurrentTrendWithProducts,
  ] = useState(false);
  const [indexCurrentTrendWithProducts, setIndexCurrentTrendWithProducts] =
    useState<number>(0);
  const [canGoBack, setCanGoBack] = useState<boolean>(false);
  const [canGoNext, setCanGoNext] = useState<boolean>(false);
  const [selectedTrendsWithProducts, setSelectedTrendsWithProducts] = useState<
    ITrendWithProducts[]
  >([]);
  const [trendsWithProducts, setTrendsWithProducts] = useState<
    ITrendWithProducts[]
  >([]);
  const [trendsThatHaveProducts, setTrendsThatHaveProducts] = useState<
    ITrendWithProducts[]
  >([]);
  const [trends, setTrends] = useState<IProductsByTrend[]>([]);

  const [isLoadingTrends, setIsLoadingTrends] = useState(false);
  const [errorTrends, setErrorTrends] = useState<Error>();

  const organisation = useMemo(
    () => userData?.organisation ?? null,
    [userData],
  );

  const getCountryList = () => Countries;

  const getTrendsOfCountry = useCallback(async () => {
    if (!organisation) {
      console.warn("organisation not set ... waiting to load.");
      return;
    }

    if (selectedTrendsType === TRENDS_TYPE.GOOGLE && !selectedCountryCode) {
      return;
    }
    setIsLoadingTrends(true);
    setErrorTrends(undefined);
    try {
      const loadedTrends = await getProductsByTrendForCountry(
        organisation.id,
        selectedTrendsType,
        selectedCountryCode,
      );
      setTrends(loadedTrends);
    } catch (err) {
      logError(err);
      setErrorTrends(err as Error);
    }
    setIsLoadingTrends(false);
  }, [selectedCountryCode, selectedTrendsType, organisation]);

  useEffect(() => {
    setTrends([]);
    setSelectedTrendsWithProducts([]);
    getTrendsOfCountry();
  }, [getTrendsOfCountry]);

  useEffect(() => {
    setCountries(getCountryList());
  }, []);

  useEffect(() => {
    setTrendsThatHaveProducts(
      trendsWithProducts.filter((x) => x.productsCount > 0),
    );
  }, [trendsWithProducts]);

  useEffect(() => {
    setTrendsWithProducts(
      trends.map(({ trend, products }) => {
        return {
          ...trend,
          id: trend.uuid,
          productsIsbns: products,
          productsCount: products.length,
          products: null,
        };
      }),
    );
  }, [trends, selectedTrendsType]);

  useEffect(() => {
    setCurrentTrendWithProducts(
      selectedTrendsWithProducts[indexCurrentTrendWithProducts],
    );
    setCanGoBack(
      !isLoadingProductsForCurrentTrendWithProducts &&
        indexCurrentTrendWithProducts > 0,
    );
    setCanGoNext(
      !isLoadingProductsForCurrentTrendWithProducts &&
        indexCurrentTrendWithProducts < selectedTrendsWithProducts.length - 1,
    );
  }, [
    indexCurrentTrendWithProducts,
    selectedTrendsWithProducts,
    isLoadingProductsForCurrentTrendWithProducts,
  ]);

  useEffect(() => {
    const fetchProductsForCurrentTrendWithProducts = async () => {
      if (!organisation) {
        console.warn("organisation not set ... waiting to load.");
        return;
      }

      setIsLoadingProductsForCurrentTrendWithProducts(true);
      const isbnChunks: string[][] = [];
      const isbns = currentTrendWithProducts?.productsIsbns ?? [];
      isbns.forEach((_, i) => {
        if (i % 10 === 0) isbnChunks.push(isbns.slice(i, i + 10));
      });

      let products: IProduct[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const isbnChunk of isbnChunks) {
        // eslint-disable-next-line no-await-in-loop
        const newProducts = await getProductsByIsbns(
          organisation.id,
          isbnChunk,
        );
        products = [...products, ...newProducts];
      }
      currentTrendWithProducts!.products = products;
      setCurrentTrendWithProducts({
        ...currentTrendWithProducts!,
      });
      setIsLoadingProductsForCurrentTrendWithProducts(false);
    };

    if (
      !organisation ||
      !currentTrendWithProducts ||
      currentTrendWithProducts?.products !== null
    ) {
      return;
    }

    fetchProductsForCurrentTrendWithProducts();
  }, [organisation, currentTrendWithProducts]);

  const goNext = useCallback(() => {
    if (canGoNext) {
      setIndexCurrentTrendWithProducts((current) => current + 1);
    }
  }, [canGoNext]);

  const goBack = useCallback(() => {
    if (canGoBack) {
      setIndexCurrentTrendWithProducts((current) => current - 1);
    }
  }, [canGoBack]);

  const trendsContextProviderValue = useMemo(
    () => ({
      top3CountryCodes,
      countries,
      selectedCountryCode,
      setSelectedCountryCode,
      trendTypes,
      selectedTrendsType,
      setSelectedTrendsType,
      indexCurrentTrendWithProducts,
      setIndexCurrentTrendWithProducts,
      currentTrendWithProducts,
      setCurrentTrendWithProducts,
      isLoadingProductsForCurrentTrendWithProducts,
      canGoNext,
      goNext,
      canGoBack,
      goBack,
      selectedTrendsWithProducts,
      setSelectedTrendsWithProducts,
      trendsWithProducts,
      errorTrends,
      trendsThatHaveProducts,
      trends,
      isLoadingTrends,
    }),
    [
      countries,
      selectedCountryCode,
      setSelectedCountryCode,
      selectedTrendsType,
      setSelectedTrendsType,
      indexCurrentTrendWithProducts,
      setIndexCurrentTrendWithProducts,
      currentTrendWithProducts,
      setCurrentTrendWithProducts,
      isLoadingProductsForCurrentTrendWithProducts,
      canGoNext,
      goNext,
      canGoBack,
      goBack,
      selectedTrendsWithProducts,
      setSelectedTrendsWithProducts,
      trendsWithProducts,
      errorTrends,
      trendsThatHaveProducts,
      trends,
      isLoadingTrends,
    ],
  );

  return (
    <TrendsContext.Provider value={trendsContextProviderValue}>
      {children}
    </TrendsContext.Provider>
  );
};

export const useTrends = () => {
  const ctxt = useContext(TrendsContext);
  if (!ctxt) {
    throw new Error("useTrends must be used within a TrendsContextProvider");
  }
  return ctxt;
};
