import { useState, useEffect } from "react";

import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { IProject } from "integrations/firebase/collections";

export const useProject = (idProject: string) => {
  const projectsHook = useProjects();
  const { getProject } = projectsHook;
  const [errorProjectRead, setErrorProjectRead] = useState<string>();

  const [project, setProject] = useState<IProject>();
  useEffect(() => {
    const loadProject = async () => {
      try {
        setErrorProjectRead(undefined);
        const loadedProject = await getProject(idProject);
        setProject(loadedProject);
      } catch (e) {
        setErrorProjectRead("Cannot load project");
      }
    };
    loadProject();
  }, [getProject, idProject]);

  return {
    project,
    errorProjectRead,
    ...projectsHook,
  };
};
