import { Timestamp } from "firebase/firestore";

export function formatFirestoreTimestamp(timestamp?: Timestamp | null): string {
  if (!timestamp?.toDate?.()) {
    return " ";
  }

  const date = timestamp.toDate();
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

interface FormattedDateTime {
  date: string;
  time: string;
}

export function formatFirebaseTimestampDayTime(
  timestamp: Timestamp,
): FormattedDateTime {
  const dateObj = timestamp?.toDate();
  if (!dateObj) {
    return {
      date: " ",
      time: " ",
    };
  }
  const formattedDate = dateObj.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const formattedTime = dateObj.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });

  return {
    date: formattedDate,
    time: formattedTime,
  };
}

export const areArraysEqualIgnoreOrder = (
  a: string[],
  b: string[],
): boolean => {
  return (
    new Set(a).size === new Set(b).size &&
    [...new Set(a)].every((value) => new Set(b).has(value))
  );
};

export const sleep = async (ms: number = 1000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
