import { FC, useEffect, useState, KeyboardEvent } from "react";

import uniqueId from "lodash/uniqueId";
import { Button } from "../button/Button";
import { IMultipleSelectChipValue } from "./types";
import { Chip } from "./Chip";

export interface IMultipleSelectChipProps {
  validate: (value: string, values: string[]) => string | undefined;
  onValuesChangedCallback: (values: IMultipleSelectChipValue[]) => void;
  values: string[];
  placeholder?: string;
  className?: string;
  chipClassName?: string;
  chipInputClassName?: string;
  showErrors?: boolean;
}

export const MultipleSelectChip: FC<IMultipleSelectChipProps> = ({
  validate,
  onValuesChangedCallback,
  values,
  placeholder = "+ Add ISBN",
  className = "",
  chipClassName = "",
  chipInputClassName = "",
  showErrors = false,
}) => {
  const [chips, setChips] = useState<IMultipleSelectChipValue[]>([]);
  const [editIdx, setEditIdx] = useState<number | null>(null);

  useEffect(() => {
    const res: IMultipleSelectChipValue[] = [];
    for (let i = 0; i < values.length; i += 1) {
      res.push({
        id: i.toString(),
        value: values[i],
        error: validate(values[i], values),
      });
    }

    setChips(res);
  }, [values, validate]);

  const updateChip = (v: string, i: number) => {
    setChips(
      chips.map((chip, idx) => {
        if (idx === i) {
          return {
            ...chip,
            value: v,
            error: validate(
              v,
              chips.map((c) => c.value),
            ),
          };
        }
        return chip;
      }),
    );
  };

  const addEmptyChip = () => {
    setEditIdx(chips.length);
    onValuesChangedCallback([
      ...chips,
      { id: uniqueId(), value: "", error: "" },
    ]);
  };

  const removeChip = (i: number) => {
    chips.splice(i, 1);
    onValuesChangedCallback(chips);
    setEditIdx(null);
  };

  const updateState = () => {
    onValuesChangedCallback(chips.filter((c) => c.value !== ""));
    setEditIdx(null);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.code === "Enter" ||
      event.code === "Space" ||
      event.code === "Semicolon" ||
      event.code === "Comma"
    ) {
      event.preventDefault();
      if (editIdx !== null && chips[editIdx].value !== "") {
        setEditIdx(chips.length);
        addEmptyChip();
      }

      if (event.code === "Enter") {
        event.stopPropagation();
      }
    }

    if (event.code === "Escape") {
      updateState();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text");
    const pasteList = pasteData
      .split(/[ ,;\n]+/)
      .map((x) => x.replaceAll(/\s+/g, ""))
      .filter((x) => x !== "");

    setEditIdx(null);

    onValuesChangedCallback([
      ...chips.filter((c) => c.value !== ""),
      ...pasteList.map((v: string) => ({
        id: uniqueId(),
        value: v,
        error: "",
      })),
    ]);
  };

  const [chipsErrors, setChipsErrors] = useState<{
    [error: string]: string[];
  } | null>(null);

  useEffect(() => {
    if (editIdx || !showErrors) return;

    const res: { [error: string]: string[] } = {};

    chips.forEach((c) => {
      if (c.error) {
        if (!res[c.error]) res[c.error] = [];
        res[c.error].push(c.value);
      }
    });

    setChipsErrors(Object.keys(res).length > 0 ? res : null);
  }, [chips, editIdx, showErrors]);

  return (
    <div className="w-full h-auto">
      <div className={`flex flex-wrap ${className}`}>
        {chips.map((c, i) => (
          <Chip
            key={`${c.id}`}
            value={c}
            onValueChangedCallback={(v: string) => {
              updateChip(v, i);
            }}
            editing={editIdx === i}
            setEditing={() => setEditIdx(i)}
            removeChip={() => removeChip(i)}
            handleKeyDown={handleKeyDown}
            handlePaste={handlePaste}
            onBlur={() => {
              updateState();
              setEditIdx(null);
            }}
            className={chipClassName}
            inputClassName={chipInputClassName}
          />
        ))}

        {(!chips.length || editIdx === null) && (
          <Button
            variant="ghost"
            onClick={addEmptyChip}
            label={placeholder}
            className="whitespace-nowrap pb-2 pt-2"
          />
        )}
      </div>

      {chipsErrors && (
        <div className="text-sm mt-5">
          <b>Errors</b> <br />
          {Object.keys(chipsErrors).map((errorKey) => (
            <span key={errorKey} className="">
              {errorKey} <small>({chipsErrors[errorKey].join(", ")})</small>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
