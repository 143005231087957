export enum RoutePath {
  EMPTY = "/",
  Login = "/login",
  LoginLink = "/login-link",
  Profile = "/profile",

  Books = "/books",

  SearchTerms = "/search-terms",
  SelectedSearchTerms = "/search-terms/selected-books",

  Projects = "/projects",
  ProjectDetails = "/projects/:idProject",
  ProductDetails = "/projects/:idProject/product/:idProduct",
}
