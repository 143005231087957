import { useAuth } from "contexts/AuthContext";
import {
  onSnapshot,
  query,
  where,
  DocumentData,
  QuerySnapshot,
} from "firebase/firestore";
import {
  IProduct,
  getProductsCollection,
} from "integrations/firebase/collections";
import { useEffect, useMemo, useState } from "react";

export interface IGeneratingProducts {
  keywords: IProduct[];
  description: IProduct[];
}

export const useGeneratingWatcher = () => {
  const [generatingProducts, setGeneratingProducts] =
    useState<IGeneratingProducts>({ keywords: [], description: [] });
  const { userData } = useAuth();

  const organisationId = useMemo(
    () => userData?.organisation?.id ?? null,
    [userData],
  );

  useEffect(() => {
    if (!organisationId) {
      return;
    }

    const keywordsQuery = query(
      getProductsCollection(),
      where("organisation.id", "==", organisationId),
      where("generated.keywords.status", "==", "REQUESTED"),
    );

    const keywordsUnsubscribe = onSnapshot(
      keywordsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const products =
          snapshot.docs?.map((doc) => doc.data() as IProduct) || [];

        setGeneratingProducts((currentProducts) => {
          const updatedKeywordProducts = products.filter((product) =>
            currentProducts.keywords.some(
              (currentProduct) => product.isbn === currentProduct.isbn,
            ),
          );
          const newProducts = products.filter(
            (product) =>
              !currentProducts.keywords.some(
                (currentProduct) => currentProduct.isbn === product.isbn,
              ),
          );

          return {
            keywords: [...updatedKeywordProducts, ...newProducts],
            description: currentProducts.description,
          };
        });
      },
    );

    const descriptionQuery = query(
      getProductsCollection(),
      where("organisation.id", "==", organisationId),
      where("generated.description.status", "==", "REQUESTED"),
    );

    const descriptionUnsubscribe = onSnapshot(
      descriptionQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const products =
          snapshot.docs?.map((doc) => doc.data() as IProduct) || [];

        setGeneratingProducts((currentProducts) => {
          const updatedDescriptionProducts = products.filter((product) =>
            currentProducts.description.some(
              (currentProduct) => product.isbn === currentProduct.isbn,
            ),
          );

          const newProducts = products.filter(
            (product) =>
              !currentProducts.description.some(
                (currentProduct) => currentProduct.isbn === product.isbn,
              ),
          );

          return {
            keywords: currentProducts.keywords,
            description: [...updatedDescriptionProducts, ...newProducts],
          };
        });
      },
    );

    return () => {
      keywordsUnsubscribe();
      descriptionUnsubscribe();
    };
  }, [organisationId]);

  return {
    generatingProducts,
  };
};
