import {
  collection,
  getDocs,
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import { IOrganisation } from "./organisations";
import { ITrend, TRENDS_TYPE } from "./trends";

export interface IProductsByTrend {
  id: string;
  organisation: IOrganisation;
  trend: ITrend;
  products: string[];
}

const productsByTrendConverter = {
  toFirestore(project: IProductsByTrend) {
    return project;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IProductsByTrend {
    const documentData = {
      ...snapshot.data(),
      id: snapshot.id,
      products: snapshot.data().newProducts || [],
    };
    return documentData as IProductsByTrend;
  },
};

export const getProductsByTrendCollection = () =>
  collection(db, "productsByTrend").withConverter(productsByTrendConverter);

export const getProductsByTrendForCountry = async (
  organisationId: string,
  trendsType: TRENDS_TYPE,
  countryCode: string,
) => {
  const queryTrendsGoogleOrAmazon = query(
    getProductsByTrendCollection(),
    where("organisation.id", "==", organisationId),
    where("trend.trend_type", "==", trendsType),
    ...(trendsType === TRENDS_TYPE.GOOGLE
      ? [where("trend.region", "==", countryCode)]
      : []),
    orderBy("trend.rank", "asc"),
  );

  const snapshot = await getDocs(queryTrendsGoogleOrAmazon);
  const loadedTrends = snapshot.docs.map((document) => ({
    ...document.data(),
    id: document.id,
  })) as IProductsByTrend[];
  return loadedTrends;
};
