import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Label } from "components/ui/label";

export interface DetailsGenerateBookDescriptionProps {
  selectedTarget: string | null;
  setSelectedTarget: (selectedTarget: string | null) => void;
}

export const SelectedTargetsSelect: React.FC<
  DetailsGenerateBookDescriptionProps
> = ({ selectedTarget, setSelectedTarget }) => {
  const { t } = useTranslation(["productDetails"]);
  const { organisation } = useAuth();
  if (!organisation?.targets) {
    return;
  }
  return (
    <div className="grid gap-3">
      <Label className="text-s">
        {t(
          "productDetails:bookDescription.tabGenerate.detailsGenerate.targetGroup",
        )}
      </Label>
      <Select onValueChange={setSelectedTarget} value={selectedTarget || ""}>
        <SelectTrigger ariaLabel="target-groups">
          <SelectValue
            placeholder={t(
              "productDetails:bookDescription.tabGenerate.detailsGenerate.targetGroupPlaceholder",
            )}
          />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {organisation.targets.map((trend) => {
              return (
                <SelectItem key={trend} value={trend}>
                  {trend}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
