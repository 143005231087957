export const transformIsbn = (value: string) => {
  return value.replace(/^\[(.+)\]$/, "$1").replace(/-/g, "");
};

export const transformMultipleIsbn = (value: string[]) =>
  value.map(transformIsbn);

// We only support rudementary ISBN validation for now, to not lose focus on finding PMF.
// For example, an invalid ISBN would be "978-3-16-148410-1" as the parity check digit is wrong.
// See https://en.wikipedia.org/wiki/International_Standard_Book_Number#Check_digits
// With different reasons for validations not turning out right, user feedback would need to go into i18n.
// We only support ISBN-13 for now.
export const validateIsbn = (value: string) => {
  const regexNumbersOnly = /^\d{13}$/;
  return regexNumbersOnly.test(transformIsbn(value));
};
