import { EditorContent, useEditor } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { cn } from "utils";
import { Button } from "components/button";
import { useEffect } from "react";

interface TitleEditorProps {
  value: string;
  autoFocus?: boolean;
  disabled?: boolean;

  onChange?(value: string): void;
}

export function TitleEditor({
  value,
  disabled,
  onChange,
  autoFocus,
}: TitleEditorProps) {
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph.extend({
        addKeyboardShortcuts() {
          return {
            Enter(props) {
              return props.editor.chain().blur().run();
            },
            Escape(props) {
              props.editor.commands.setContent(value);

              return props.editor.chain().blur().run();
            },
          };
        },
      }),
      Text,
    ],
    content: `${value}`,
    editable: !disabled,
  });

  useEffect(() => {
    if (!editor || !onChange) {
      return;
    }

    const onBlur = () => {
      const text = editor.getText().trim() || "Untitled";

      editor.commands.setContent(text);

      onChange(text || "Untitled");
    };

    editor.on("blur", onBlur);

    return () => {
      editor.off("blur", onBlur);
    };
  }, [editor, onChange]);

  useEffect(() => {
    if (autoFocus) {
      editor?.chain().focus("end").run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    editor?.commands.setContent(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="flex items-center">
      <EditorContent
        editor={editor}
        className={cn(
          "text-4xl font-medium transition px-2 -mx-2",
          editor?.isFocused && "bg-primary-25",
        )}
      />

      {!editor?.isFocused && !disabled && (
        <Button
          label=""
          variant="ghost"
          iconClassName="text-secondary-400"
          testId="button-edit-project-name"
          className="px-3"
          icon="square-pen"
          onClick={() => {
            editor?.chain().focus("end").run();

            if (value === "Untitled") {
              editor?.commands.selectAll();
            }
          }}
        />
      )}
    </div>
  );
}
