import { Icon } from "components/icon/Icon";
import { Skeleton } from "components/ui/skeleton";
import { cn } from "utils";

interface SkeletonSubjectProps {
  primary?: boolean;
  className?: string;
}

export const SkeletonFinalSubject = ({
  primary = false,
  className,
}: SkeletonSubjectProps) => {
  return (
    <div
      className={cn(
        "transition-colors border-[1.15px] rounded-md p-4 bg-white flex items-center space-x-6",
        primary
          ? "border-primary-300 shadow-final-item"
          : "border-secondary-200",
        className,
      )}
    >
      <Skeleton animated={false} className="h-4 w-40 bg-secondary-100" />
      <Icon name="circle-check" className="text-primary-600" size="small" />
    </div>
  );
};
