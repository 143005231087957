const Google = ({ disabled = false }) => {
  if (disabled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4001 13.2911C25.4001 12.3897 25.3245 11.5204 25.1822 10.6885H13.2511V15.6182H20.0847C19.7912 17.2251 18.9039 18.5924 17.5579 19.508L21.6363 22.7097C24.0242 20.4804 25.4001 17.1861 25.4001 13.2911Z"
          fill="#71717A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2519 25.8003C16.6661 25.8003 19.5407 24.6661 21.637 22.7105L17.5587 19.5088C16.4235 20.2821 14.9616 20.7337 13.2519 20.7337C9.95199 20.7337 7.15109 18.483 6.14951 15.4502L1.94812 18.7479C4.03035 22.9292 8.309 25.8003 13.2519 25.8003Z"
          fill="#71717A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.14904 15.4502C5.89252 14.6769 5.75191 13.8519 5.75191 13.0004C5.75191 12.1489 5.89252 11.324 6.14904 10.5507L1.94771 7.25293C1.08671 8.9809 0.599976 10.9329 0.599976 13.0004C0.599976 15.0679 1.08671 17.0201 1.94771 18.7479L6.14904 15.4502Z"
          fill="#71717A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2519 5.26682C15.1162 5.26682 16.7873 5.91565 18.1051 7.1868V7.18858L21.7161 3.53527C19.5232 1.46951 16.6643 0.200195 13.2519 0.200195C8.309 0.200195 4.03035 3.07128 1.94812 7.25256L6.14951 10.5503C7.15109 7.51744 9.95199 5.26682 13.2519 5.26682Z"
          fill="#71717A"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4001 13.2911C25.4001 12.3897 25.3245 11.5204 25.1822 10.6885H13.2511V15.6182H20.0847C19.7912 17.2251 18.9039 18.5924 17.5579 19.508L21.6363 22.7097C24.0242 20.4804 25.4001 17.1861 25.4001 13.2911Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2519 25.8003C16.6661 25.8003 19.5407 24.6661 21.637 22.7105L17.5587 19.5088C16.4235 20.2821 14.9616 20.7337 13.2519 20.7337C9.95199 20.7337 7.15109 18.483 6.14951 15.4502L1.94812 18.7479C4.03035 22.9292 8.309 25.8003 13.2519 25.8003Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.14904 15.4502C5.89252 14.6769 5.75191 13.8519 5.75191 13.0004C5.75191 12.1489 5.89252 11.324 6.14904 10.5507L1.94771 7.25293C1.08671 8.9809 0.599976 10.9329 0.599976 13.0004C0.599976 15.0679 1.08671 17.0201 1.94771 18.7479L6.14904 15.4502Z"
        fill="#FABB05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2519 5.26682C15.1162 5.26682 16.7873 5.91565 18.1051 7.1868V7.18858L21.7161 3.53527C19.5232 1.46951 16.6643 0.200195 13.2519 0.200195C8.309 0.200195 4.03035 3.07128 1.94812 7.25256L6.14951 10.5503C7.15109 7.51744 9.95199 5.26682 13.2519 5.26682Z"
        fill="#E94235"
      />
    </svg>
  );
};

export default Google;
