import { ErrorMessageWithImage } from "components/errors/ErrorMessageWithImage";
import { useTranslation } from "react-i18next";

interface ErrorMessageProps {
  onBack: () => void;
}
export const ErrorMessage = ({ onBack }: ErrorMessageProps) => {
  const { t } = useTranslation(["projectDetails"]);
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <ErrorMessageWithImage
        title={
          <>
            {t("projectDetails:notFound.title.one")}
            <br />
            {t("projectDetails:notFound.title.two")}
          </>
        }
        message={
          <>
            {t("projectDetails:notFound.message.one")}
            <br />
            {t("projectDetails:notFound.message.two")}
          </>
        }
        onButtonClick={onBack}
      />
    </div>
  );
};
