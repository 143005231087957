import { Header } from "./header";
import { SelectedTable } from "./components/table/SelectedTable";

export const SelectedSearchTermsPage = () => {
  return (
    <>
      <Header />
      <SelectedTable />
    </>
  );
};
