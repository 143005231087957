import { SkeletonDescription } from "components/skeletons/SkeletonDescription";
import { useTranslation } from "react-i18next";

export const DatabaseBookDescriptionEmpty = () => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <div className="flex flex-col items-center justify-center space-y-6 py-40">
      <div className="relative mt-4">
        <SkeletonDescription
          animated={false}
          className="scale-90 -top-6 absolute -z-10"
        />
        <SkeletonDescription
          animated={false}
          className="scale-95 -top-3 absolute -z-10"
        />
        <SkeletonDescription
          animated={false}
          className="border-secondary-400"
        />
      </div>

      <div className="text-center space-y-2">
        <p className="text-secondary-900">
          {t("productDetails:bookDescription.tabDatabase.empty.header")}
        </p>

        <p className="text-s text-secondary-500">
          {t("productDetails:bookDescription.tabDatabase.empty.message")}
        </p>
      </div>
    </div>
  );
};
