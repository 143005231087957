import { IProject } from "integrations/firebase/collections";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RoutePath } from "shared/constants";
import { useAnalytics } from "contexts/AnalyticsContext";
import { TabHeader } from "pages/TabBar";
import { ProjectsTable } from "./components/table/ProjectsTable";

export const ProjectsPage = () => {
  const { t } = useTranslation("projects");
  const navigate = useNavigate();
  const { gaEvent } = useAnalytics();

  const handleTabProjectsGoToDetails = (row: IProject) => {
    navigate(RoutePath.ProjectDetails.replace(":idProject", row.id!));
    gaEvent({
      type: "view_item_list",
      payload: {
        item_list_name: row.name,
        item_list_id: row.id,
        item_category: "project",
      },
    });
  };

  return (
    <div>
      <TabHeader
        title={t("projects:projects.header.title")}
        subtitle={t("projects:projects.header.subtitle")}
      />
      <ProjectsTable onRowClicked={handleTabProjectsGoToDetails} />
    </div>
  );
};
