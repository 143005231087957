import { useState, useCallback } from "react";
import { useAuth } from "contexts/AuthContext";
import { useQuery } from "react-query";
import { callPostSearch, PostSearchParams } from "api/typesense/productsApi";

const initialSearchParams: PostSearchParams = {
  model: "products",
  q: "",
  query_by: "title,author,biography",
  sort_by: "publishedAt:desc",
  filter_by: "",
  page: 1,
  per_page: 10,
};

export const useTypesense = () => {
  const { authUser } = useAuth();

  const [params, setParams] = useState<Partial<PostSearchParams>>();

  const finalParams = { ...initialSearchParams, ...params };
  const { data, error, isLoading } = useQuery(
    ["productsSearch", ...Object.values(finalParams)],
    () => callPostSearch(finalParams),
    {
      enabled: !!params && !!authUser,
    },
  );

  const searchProducts = useCallback(
    (searchParams: Partial<PostSearchParams>) => {
      setParams(searchParams);
    },
    [],
  );

  return { data, error, isLoading, searchProducts };
};
