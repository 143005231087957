import React from "react";

export function Loader() {
  return (
    <div className="relative">
      <div className="rounded-full border-5 border-primary-600 h-14 w-14 animate-loader opacity-0" />
      <div className="absolute inset-0 rounded-full border-5 border-primary-600 h-14 w-14 animate-loader opacity-0 delay-1000" />
    </div>
  );
}
