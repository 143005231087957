import { FC } from "react";

const LoadingFrame1: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="99"
      height="99"
      viewBox="0 0 99 99"
      fill="none"
    >
      <path
        d="M85.5 49.5C85.5 69.3823 69.3823 85.5 49.5 85.5C29.6177 85.5 13.5 69.3823 13.5 49.5C13.5 29.6177 29.6177 13.5 49.5 13.5C69.3823 13.5 85.5 29.6177 85.5 49.5ZM20.5714 49.5C20.5714 65.4768 33.5232 78.4286 49.5 78.4286C65.4768 78.4286 78.4286 65.4768 78.4286 49.5C78.4286 33.5232 65.4768 20.5714 49.5 20.5714C33.5232 20.5714 20.5714 33.5232 20.5714 49.5Z"
        fill="url(#paint0_angular_1209_27386)"
      />
      <defs>
        <radialGradient id="paint0_angular_1209_27386" cx="0" cy="0" r="1">
          <stop stopColor="#2563EB" />
          <stop offset="1" stopColor="#B0D3FF" stopOpacity="0.21" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default LoadingFrame1;
