import { ColumnDef } from "@tanstack/react-table";

import { Checkbox } from "components/ui/checkbox";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";

export const columns: ColumnDef<any>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <div className="w-10">
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      </div>
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
  },
  {
    accessorKey: "term",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Search term" />
    ),
    cell: ({ row }) => (
      <div className="text-secondary-900">{row.getValue("term")}</div>
    ),
  },
  {
    accessorKey: "productsCount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Books" />
    ),
    cell: ({ row }) => (
      <span className="truncate">
        {`${row.getValue("productsCount")} Books`}
      </span>
    ),
  },
];
