export interface LaunchDarklyFeatureFlags {
  trendProjects: boolean;
  bookDescription: boolean;
  manualTrends: boolean;
  backlist: boolean;
  themaCodes: boolean;
  loginLink: boolean;
  loginAgreement: boolean;
}

export function parseFeatureFlags(flags: Partial<LaunchDarklyFeatureFlags>) {
  return {
    trendProjects: flags.trendProjects ?? false,
    bookDescription: flags.bookDescription ?? false,
    manualTrends: flags.manualTrends ?? false,
    backlist: flags.backlist ?? false,
    themaCodes: flags.themaCodes ?? false,
    login: {
      link: flags.loginLink ?? false,
      agreement: flags.loginAgreement ?? false,
    },
  };
}

export type FeatureFlags = ReturnType<typeof parseFeatureFlags>;
