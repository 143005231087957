import { FC, PropsWithChildren } from "react";
import LoadingFrame1 from "components/svg/loading/LoadingFrame1";

interface ILoadingSpinnerProps {
  size: string;
}

const StyledSvgIcon = ({
  size,
  children,
}: PropsWithChildren<{ size: string }>) => (
  <div
    className={`flex flex-col items-center w-[${size}] h-[${size}] animate-spin`}
  >
    {children}
  </div>
);

export const LoadingSpinner: FC<ILoadingSpinnerProps> = ({ size }) => (
  <StyledSvgIcon size={size}>
    <LoadingFrame1 />
  </StyledSvgIcon>
);

export default LoadingSpinner;
