import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { Table } from "@tanstack/react-table";
import { IProduct, PROJECT_TYPE } from "integrations/firebase/collections";
import { buildProjectUrl, openInNewTab } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import entries from "lodash/entries";
import { Button } from "components/button";
import { toast } from "utils/toast";
import { BacklistInput } from "../components/input/BacklistInput";

interface PreHeaderProps {
  table: Table<IProduct>;
}

export const PreHeader = ({ table }: PreHeaderProps) => {
  const { createProject } = useProjects();
  const { t } = useTranslation(["books"]);

  const selection = table.getState().rowSelection;

  const selectedIsbns = useMemo(() => {
    const selected = entries(selection).filter(([, checked]) => checked);
    return selected.map(([id]) => id);
  }, [selection]);

  const handleSaveProject = async () => {
    const project = await createProject(
      "New Project",
      selectedIsbns,
      PROJECT_TYPE.ISBNS,
    );

    openInNewTab(`${buildProjectUrl(project!)}?newProject=true`);

    toast.success(t("books:selectedBooks.toasts.info.createProjectDone"));
  };
  return (
    <>
      <div className="flex flex-row gap-4 w-3/4 items-center">
        <BacklistInput />
      </div>
      <div className="flex flex-row items-start gap-4">
        <Button
          label={t("books:selectedBooks.saveAsProject")}
          onClick={handleSaveProject}
          icon="folder-plus"
          disabled={!selectedIsbns.length}
        />
      </div>
    </>
  );
};
