import React from "react";
import { useTranslation } from "react-i18next";
import { HeroIllustrationImage } from "components/image/HeroIllustrationImage";
import { Button } from "components/button";

export type ErrorMessageProps = {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  error?: Error;
  buttonText?: string;
  Icon?: React.ReactNode;
  titleImage?: React.ReactNode | null;
  mainImage?: React.ReactNode | null;
  onButtonClick: () => void;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  title,
  message,
  error,
  buttonText,
  Icon,
  titleImage = null,
  mainImage = <HeroIllustrationImage />,
  onButtonClick,
}) => {
  const { t } = useTranslation(["general"]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col items-center min-w-[500px] max-w-[800px] text-primary-700">
        {titleImage}
        <p className="text-center">{title}</p>
        <p className="text-center mt-8 text-l">{message}</p>
        {error && (
          <p className="text-center mt-8 text-l">
            {t("general:errorDetails")}:
            <span className="text-l text-system-error">{error.message}</span>
          </p>
        )}
        <Button
          icon={Icon ? "arrow-left" : undefined}
          className="mt-10"
          label={buttonText ?? t("general:buttons.back")}
          onClick={onButtonClick}
          analyticsId="button_back_from_error_message"
        />
        {mainImage}
      </div>
    </div>
  );
};
