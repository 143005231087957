import React from "react";
import { NavLink } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { Button } from "components/button";
import { cn } from "../../utils";
import { ProjectActivityIndicator } from "./ProjectActivityIndicator";

interface NavigationBarProps {
  items: { label: string; path: string }[];
}

export const NavigationBar: React.FC<NavigationBarProps> = ({ items }) => {
  return (
    <nav className="relative">
      <div className="flex space-x-2 relative">
        {items.map((item) => {
          return (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) => cn("group", isActive && "active")}
            >
              {item.path === RoutePath.Projects && <ProjectActivityIndicator />}

              <Button
                variant="link"
                label={item.label}
                className={cn(
                  "text-s transition duration-300 ease-in-out group-[.active]:text-primary-600",
                )}
              />
            </NavLink>
          );
        })}
      </div>
    </nav>
  );
};
