import { flexRender } from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePreHeader,
  TableRow,
} from "components/ui/table";
import { useTrends } from "contexts/TrendsContext";
import { useTable } from "hooks/useTable";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";
import { PreHeader } from "./preHeader";

export const SearchTermsTable = () => {
  const { t } = useTranslation(["searchTerms"]);
  const { trendsWithProducts = [], isLoadingTrends } = useTrends();
  const { table } = useTable({
    columns,
    data: trendsWithProducts,
    enableRowSelection: true,
    totalRecords: trendsWithProducts?.length || 0,
  });

  if (isLoadingTrends) {
    return;
  }

  return (
    <div className="space-y-4 text-left">
      <Table>
        <TablePreHeader colSpan={table.getHeaderGroups()[0].headers.length}>
          <PreHeader table={table} />
        </TablePreHeader>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => row.toggleSelected(!row.getIsSelected())}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {t("searchTerms:noResults")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
