import { SignoutModal } from "components/modal/SignoutModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useAuth } from "contexts/AuthContext";
import { User } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { ProfilePicture } from "../user/ProfilePicture";

export const ProfileMenu = () => {
  const { t } = useTranslation(["components"]);
  const { authUser, userData } = useAuth();
  const navigate = useNavigate();

  const handleClickProfile = () => {
    const path = window.location.pathname;
    const { search } = window.location;

    navigate(RoutePath.Profile, {
      state: { prevPath: path, prevSearch: search },
    });
  };

  if (!authUser) return;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div data-testid="account-menu" className="px-2">
          <ProfilePicture
            name={userData?.givenName ?? userData?.email}
            photoUrl={authUser?.photoURL}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56"
        side="bottom"
        align="end"
        sideOffset={30}
      >
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={handleClickProfile}>
            <User className="mr-2 h-4 w-4" />
            <p className="text-secondary-600">
              {t("components:header.profile")}
            </p>
          </DropdownMenuItem>
        </DropdownMenuGroup>

        <DropdownMenuSeparator />

        <DropdownMenuItem asChild>
          <SignoutModal />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
