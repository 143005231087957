import {
  useMemo,
  useState,
  createContext,
  useContext,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

import { IProduct } from "integrations/firebase/collections";
import { IFilter } from "pages/Books/components/filter/keywordFilterTypes";
import { BacklistInputType } from "pages/Books/table/types";
import { PaginationState } from "@tanstack/react-table";
import { useAuth } from "./AuthContext";

interface IBacklistContext {
  backlist: IProduct[];
  isLoadingSearch: boolean;
  setIsLoadingSearch: Dispatch<SetStateAction<boolean>>;
  errorRead?: Error;
  setBacklist: Dispatch<SetStateAction<IProduct[]>>;
  setFilter: (filter: IFilter | undefined) => void;
  filter: IFilter | undefined;
  searchInputValue: string | string[];
  setSearchInputValue: Dispatch<SetStateAction<string | string[]>>;
  dropdownType: BacklistInputType;
  setDropdownType: (type: BacklistInputType) => void;
  noOfOrgBooks: number | null;
  setNoOfOrgBooks: Dispatch<SetStateAction<number | null>>;
  resultLength: number | null;
  setResultLength: Dispatch<SetStateAction<number | null>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}
const DEFAULT_PAGE_SIZE = 10;

export const BacklistContext = createContext<IBacklistContext>(
  undefined as never,
);

export const BacklistContextProvider = ({ children }: PropsWithChildren) => {
  const [searchInputValue, setSearchInputValue] = useState<string | string[]>(
    "",
  );

  const [dropdownType, setDropdownType] = useState<BacklistInputType>(
    BacklistInputType.SEMANTIC,
  );
  const { authUser } = useAuth();
  const [backlist, setBacklist] = useState<IProduct[]>([]);
  const [filter, setFilter] = useState<IFilter>();
  const [isLoadingSearch, setIsLoadingSearch] = useState(true);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [noOfOrgBooks, setNoOfOrgBooks] = useState<number | null>(null);
  const [resultLength, setResultLength] = useState<number | null>(null);

  // Temporary effects for typesense flag
  useEffect(() => {
    setDropdownType(BacklistInputType.QUICK);
  }, []);

  useEffect(() => {
    if (!authUser) {
      setBacklist([]);
      setFilter(undefined);
      setSearchInputValue("");
      setDropdownType(BacklistInputType.QUICK);
      setNoOfOrgBooks(null);
      setResultLength(null);
      setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGE_SIZE });
    }
  }, [authUser]);

  const backlistContextProviderValue = useMemo(
    () => ({
      backlist,
      isLoadingSearch,
      setBacklist,
      setFilter,
      filter,
      setIsLoadingSearch,
      searchInputValue,
      setSearchInputValue,
      dropdownType,
      setDropdownType,
      noOfOrgBooks,
      setNoOfOrgBooks,
      resultLength,
      setResultLength,
      pagination,
      setPagination,
    }),
    [
      backlist,
      isLoadingSearch,
      setIsLoadingSearch,
      setBacklist,
      filter,
      searchInputValue,
      setSearchInputValue,
      dropdownType,
      setDropdownType,
      noOfOrgBooks,
      setNoOfOrgBooks,
      resultLength,
      setResultLength,
      pagination,
      setPagination,
    ],
  );

  return (
    <BacklistContext.Provider value={backlistContextProviderValue}>
      {children}
    </BacklistContext.Provider>
  );
};

export const useBacklist = () => {
  const ctxt = useContext(BacklistContext);
  if (!ctxt) {
    throw new Error(
      "useBacklist must be used within a BacklistContextProvider",
    );
  }
  return ctxt;
};
