import { BookDescriptionProvider } from "contexts/BookDescriptionContext";
import { DatabaseDescription } from "./components/DatabaseDescription/DatabaseDescription";
import { GenerateDescription } from "./components/GenerateDescription/GenerateDescription";

export const BookDescriptionTab = () => {
  return (
    <BookDescriptionProvider>
      <div className="grid grid-cols-2 gap-4">
        <DatabaseDescription />
        <GenerateDescription />
      </div>
    </BookDescriptionProvider>
  );
};
