import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import HardBreak from "@tiptap/extension-hard-break";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import History from "@tiptap/extension-history";
import Keywords from "./Keywords";

export const extensions = [
  Bold,
  Italic,
  HardBreak.extend({
    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          return editor.commands.setHardBreak();
        },
      };
    },
  }),
  Document,
  Paragraph,
  Text,
  History,
  Keywords,
];
