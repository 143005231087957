import { Outlet, useParams } from "react-router-dom";
import { ProjectProvider } from "contexts/ProjectContext";

export function ProjectLayout() {
  const { idProject } = useParams();

  return (
    <ProjectProvider id={idProject!}>
      <Outlet />
    </ProjectProvider>
  );
}
