import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTrends } from "contexts/TrendsContext";

export const CountrySelect = () => {
  const { countries, setSelectedCountryCode, selectedCountryCode } =
    useTrends();
  const onValueChange = (value: string) => {
    setSelectedCountryCode(value);
  };

  return (
    <Select onValueChange={onValueChange} value={selectedCountryCode}>
      <SelectTrigger className="w-[180px] bg-secondary-50">
        <SelectValue placeholder="Country" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {countries.map((country) => (
            <SelectItem key={country.country_code} value={country.country_code}>
              {country.country_name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
