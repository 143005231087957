import { MouseEvent, MouseEventHandler } from "react";

export function onClickWithoutSelection<T>(
  handler?: (event: MouseEvent<T>) => void,
): MouseEventHandler<T> {
  return (event: MouseEvent<T>) => {
    const selection = window.getSelection?.()?.type === "Range";

    if (selection) {
      return event.stopPropagation();
    }

    handler?.(event);
  };
}
