import { flexRender, Table } from "@tanstack/react-table";
import { Skeleton } from "components/ui/skeleton";
import { TableCell, TableRow } from "components/ui/table";
import { useBacklist } from "contexts/BacklistContext";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { createArray, getRandomNumber } from "./utils";

interface BooksTableBodyProps {
  table: Table<IProduct>;
  columns: any[];
}

export const BooksTableBody = ({ table, columns }: BooksTableBodyProps) => {
  const { t } = useTranslation("projects");
  const { isLoadingSearch } = useBacklist();

  if (isLoadingSearch) {
    const skeletonItemsArr = createArray(10);
    return (
      <>
        {skeletonItemsArr.map((i) => (
          <TableRow key={i.toString()}>
            {columns.map((column) => {
              let width;
              switch (column.accessorKey) {
                case undefined:
                  width = 0;
                  break;
                case "isbn":
                  width = 7;
                  break;
                case "publishedAt":
                  width = 10;
                  break;
                case "title":
                  width = getRandomNumber(10, 20);
                  break;
                case "author":
                  width = getRandomNumber(5, 10);
                  break;
                case "productGroupDescription":
                  width = getRandomNumber(5, 10);
                  break;
                default:
                  width = 5;
                  break;
              }
              return (
                <TableCell key={`${i}-${column.accessorKey}`}>
                  <Skeleton
                    style={{ width: `${width}rem` }}
                    className="h-[23px]"
                  />
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </>
    );
  }

  if (table.getRowModel().rows?.length) {
    return (
      <>
        {table.getRowModel().rows.map((row) => (
          <TableRow
            onClick={() => row.toggleSelected(!row.getIsSelected())}
            key={row.id}
            data-book-id={row.id}
            data-state={row.getIsSelected() && "selected"}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <TableRow>
      <TableCell colSpan={columns.length} className="h-24 text-center">
        {t("projects:booktable.noResults")}
      </TableCell>
    </TableRow>
  );
};
