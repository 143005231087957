import { ProjectDetailsContextProvider } from "./contexts/ProjectDetailsContext";
import { ProjectDetails as ProjectDetailsInternal } from "./ProjectDetails";

const ProjectDetails = () => (
  <ProjectDetailsContextProvider>
    <ProjectDetailsInternal />
  </ProjectDetailsContextProvider>
);

export { ProjectDetails };
