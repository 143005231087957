import { SkeletonKeyword } from "./SkeletonKeyword";

export const KeywordsLoading = () => {
  return (
    <div className="relative">
      <SkeletonKeyword className="animate-scale-75" />
      <SkeletonKeyword className="animate-scale-80" />
      <SkeletonKeyword className="animate-scale-85" />
      <SkeletonKeyword className="animate-scale-90" />
      <SkeletonKeyword primary className="animate-slide-item" />
    </div>
  );
};
