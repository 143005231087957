import { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { ITrendWithProducts, useTrends } from "contexts/TrendsContext";
import { RoutePath } from "shared/constants";
import { TRENDS_TYPE } from "integrations/firebase/collections";
import entries from "lodash/entries";
import { CountrySelect } from "./CountrySelect";
import { CountryToggleGroup } from "./CountryToggleGroup";
import { SourceSelect } from "./SourceSelect";

interface PreHeaderProps {
  table: Table<ITrendWithProducts>;
}

export const PreHeader = ({ table }: PreHeaderProps) => {
  const { t } = useTranslation(["searchTerms"]);
  const navigate = useNavigate();
  const [navigateToSelected, setNavigateToSelected] = useState(false);

  const {
    setIndexCurrentTrendWithProducts,
    setSelectedTrendsWithProducts,
    selectedTrendsType,
    trendsWithProducts,
  } = useTrends();

  const { rowSelection } = table.getState();

  const selectedIds = useMemo(() => {
    const selected = entries(rowSelection).filter(([, checked]) => checked);

    return selected.map(([id]) => id);
  }, [rowSelection]);

  const handleTabTrendsSeeBooks = useCallback(() => {
    const trends = trendsWithProducts.filter(
      (trend) => trend.id && selectedIds.includes(trend.id),
    );

    setSelectedTrendsWithProducts(trends);
    setIndexCurrentTrendWithProducts(0);
    setNavigateToSelected(true);
  }, [
    setIndexCurrentTrendWithProducts,
    setSelectedTrendsWithProducts,
    selectedIds,
    trendsWithProducts,
  ]);

  useEffect(() => {
    if (navigateToSelected) {
      navigate(RoutePath.SelectedSearchTerms);
      setNavigateToSelected(false);
    }
  }, [navigate, navigateToSelected]);

  return (
    <div className="flex flex-row w-full items-center justify-between">
      <div className="flex flex-row items-center flex-start gap-2">
        <SourceSelect />
        {selectedTrendsType === TRENDS_TYPE.GOOGLE && (
          <div className="flex flex-row items-center flex-start gap-2">
            <CountryToggleGroup />
            <CountrySelect />
          </div>
        )}
      </div>
      <Button
        variant="primary"
        label={t("searchTerms:searchTermsList.buttons.seeBooks")}
        icon="arrow-right"
        iconPosition="right"
        onClick={handleTabTrendsSeeBooks}
        disabled={!selectedIds.length}
        analyticsId="button_see_books"
        testId="see-books-button"
      />
    </div>
  );
};
