import { cn } from "utils";

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  animated?: boolean;
}

function Skeleton({ className, animated = true, ...props }: SkeletonProps) {
  return (
    <div
      className={cn(
        `${animated ? "animate-pulse" : ""} rounded-md bg-secondary-200`,
        className,
      )}
      {...props}
    />
  );
}

export { Skeleton };
