import React from "react";
import { useCurrentProduct } from "../../contexts/CurrentProductContext";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { ActivityIndicator } from "./activityIndicator";

export function ProjectActivityIndicator() {
  const { generatingProducts } = useCurrentProduct();

  return (
    <Tooltip>
      <TooltipTrigger>
        <ActivityIndicator
          show={
            !!generatingProducts?.keywords.length ||
            !!generatingProducts.description.length
          }
        />
      </TooltipTrigger>
      <TooltipContent>
        {generatingProducts?.keywords.length ? (
          <div>
            <p className="text-xs font-semibold">Generating keywords</p>
            {generatingProducts.keywords.map((product, i) => (
              <p key={product.id} className="text-xs">
                {`${i + 1}. ${product.title}`}
              </p>
            ))}
          </div>
        ) : null}
        {generatingProducts?.description.length ? (
          <div>
            <p className="text-xs font-semibold">Generating description</p>
            {generatingProducts.description.map((product, i) => (
              <p key={product.id} className="text-xs">
                {`${i + 1}. ${product.title}`}
              </p>
            ))}
          </div>
        ) : null}
      </TooltipContent>
    </Tooltip>
  );
}
