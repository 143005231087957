import { useTheme } from "next-themes";
import { Toaster as Sonner, ToasterProps } from "sonner";
import React from "react";
import {
  CircleAlertIcon,
  CircleCheckIcon,
  CircleXIcon,
  LoaderIcon,
} from "lucide-react";

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      position="bottom-center"
      icons={{
        success: <LoaderIcon className="w-5 h-5" />,
        warning: <CircleAlertIcon className="w-5 h-5" />,
        error: <CircleXIcon className="w-5 h-5" />,
        info: <CircleCheckIcon className="w-5 h-5" />,
      }}
      style={
        {
          "--width": "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        } as React.CSSProperties
      }
      className="bg-red-500"
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            "mx-5 text-white min-h-12 rounded-lg grid grid-cols-[auto,1fr,auto,auto] pl-2 overflow-hidden",
          content: "py-2 pr-4 text-s flex items-center justify-center",
          icon: "h-full flex items-center justify-center ml-3 mr-3",

          // Buttons
          actionButton:
            "w-12 flex items-center justify-center border-l border-white/20 hover:bg-black/10",
          cancelButton:
            "w-12 flex items-center justify-center border-l border-white/20 hover:bg-black/10",

          // Copy
          title: "leading-6 font-normal",
          description: "leading-6 font-normal",

          // Toast colors
          success: "bg-primary-500",
          warning: "bg-system-warning",
          error: "bg-system-error",
          info: "bg-secondary-600",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
