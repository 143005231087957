import React from "react";
import { logError } from "shared/services/ErrorReporting";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";

import { writeToClipboard } from "./handlers/ClipboardHandler";
import { Button } from "./Button";

interface ButtonCopyTextToClipboardProps {
  textToCopy: string;
  tooltipText: string;
  buttonText?: string;

  onDone?: (content: string) => void;
}

export const ButtonCopyTextToClipboard: React.FC<
  ButtonCopyTextToClipboardProps
> = ({ textToCopy, tooltipText, buttonText, onDone }) => {
  if (!textToCopy) {
    return null;
  }

  const handleCopyToClipboard = async () => {
    try {
      await writeToClipboard(textToCopy);
      // TODO: analytics?
      if (onDone) onDone(textToCopy);
    } catch (err) {
      logError(err);
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        {textToCopy ? (
          <Button
            variant="ghost"
            onClick={handleCopyToClipboard}
            data-testid="button-copy-to-clipboard"
            icon="copy"
            className="px-2"
            label={buttonText || ""}
          />
        ) : null}
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltipText}</p>
      </TooltipContent>
    </Tooltip>
  );
};
