import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { useDialog } from "hooks/useDialog";
import { Generate } from "components/svg/icons/Generate";
import { useProductActions } from "hooks/useProductActions";
import { useErrorToast } from "hooks/useErrorToast";
import { useProduct } from "contexts/ProductContext";
import { WarningModal } from "components/modal/WarningModal";

export const RegenerateKeywordsDialogContent = () => {
  const { onOpenChange, isOpen, onOpen } = useDialog("regenerate-keywords");
  const { product } = useProduct();
  const { t } = useTranslation(["general", "productDetails"]);
  const { generateProductKeywords, errorGenerate, errorUpdate } =
    useProductActions();

  const getErrorMessage = () => {
    if (errorGenerate) {
      return t("productDetails:generatedKeywords.errorToast.errorGenerate");
    }
    if (errorUpdate) {
      return t("productDetails:generatedKeywords.errorToast.errorUpdate");
    }
    return undefined;
  };
  useErrorToast(getErrorMessage());

  const handleRegenerateAllKeywordsModal = () => {
    if (product) {
      generateProductKeywords(product);
    }
  };

  return (
    <>
      <Button
        label={
          <div className="flex flex-row items-center">
            <Generate className="w-4 h-4 mr-2" size={14} />
            <p className="text-s">
              {t("productDetails:generatedKeywords.actions.regenerate")}
            </p>
          </div>
        }
        onClick={onOpen}
        variant="ghost"
        testId="button-regenerate-keywords"
        analyticsId="button_regenerate_keywords"
        analyticsData={{ book: product?.isbn ?? "" }}
      />

      <WarningModal
        id="regenerate-keywords"
        open={isOpen}
        title={t(
          "productDetails:generatedKeywords.regenerateKeywordsModal.header",
        )}
        description={t(
          "productDetails:generatedKeywords.regenerateKeywordsModal.message",
        )}
        confirm={t(
          "productDetails:generatedKeywords.regenerateKeywordsModal.buttons.regenerateAll",
        )}
        confirmIcon="loader"
        cancel={t("general:buttons.cancel")}
        onOpenChange={onOpenChange}
        onConfirm={handleRegenerateAllKeywordsModal}
      />
    </>
  );
};
