import { useEffect, useState } from "react";
import { toast } from "../utils/toast";

type ErrorMessage = string | Error | null | undefined | unknown;
export const useErrorToast = (error: ErrorMessage) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const resetError = () => setErrorMessage(undefined);

  useEffect(() => {
    if (error) setErrorMessage(error.toString());
  }, [error]);

  const handleError = (newError: ErrorMessage) => {
    setErrorMessage(newError?.toString());
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { onAction: resetError });
    }
  }, [errorMessage]);

  return { handleError };
};
