import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { useDialog } from "hooks/useDialog";
import { Generate } from "components/svg/icons/Generate";
import { useProductActions } from "hooks/useProductActions";
import { useProduct } from "contexts/ProductContext";
import { WarningModal } from "components/modal/WarningModal";

export const RegenerateSubjectsDialogContent = () => {
  const { onOpenChange, isOpen, onOpen } = useDialog("regenerate-subjects");
  const { product } = useProduct();
  const { t } = useTranslation(["productDetails"]);
  const { generateProductSubjects } = useProductActions();

  const handleRegenerateAllSubjectsModal = () => {
    if (product) {
      generateProductSubjects(product.id);
    }
  };

  return (
    <>
      <Button
        label={
          <div className="flex flex-row items-center">
            <Generate className="w-4 h-4 mr-2" size={14} />
            <p className="text-s">{t("thema.regenerate.regenerate")}</p>
          </div>
        }
        onClick={onOpen}
        variant="ghost"
        testId="button-regenerate-subjects"
        analyticsId="button_regenerate_subjects"
        analyticsData={{ book: product?.isbn ?? "" }}
      />

      <WarningModal
        id="regenerate-subjects"
        open={isOpen}
        title={t("thema.regenerate.header")}
        description={t("thema.regenerate.message")}
        confirm={t("thema.regenerate.regenerateAll")}
        confirmIcon="loader"
        cancel={t("thema.regenerate.cancel")}
        onOpenChange={onOpenChange}
        onConfirm={handleRegenerateAllSubjectsModal}
      />
    </>
  );
};
