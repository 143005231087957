import { useTranslation } from "react-i18next";
import {
  Grid,
  GridHeader,
  GridLoading,
  GridTitle,
  GridContent,
  GridBody,
  GridButtonContainer,
} from "components/ui/grid";
import { KeywordsLoading } from "components/skeletons/KeywordsLoading";

import { useProductActions } from "hooks/useProductActions";
import { useErrorToast } from "hooks/useErrorToast";
import { useProduct } from "contexts/ProductContext";
import { useMemo } from "react";
import { useGenerations } from "hooks/useGenerations";
import { LoadingSpinner } from "components/loading/LoadingSpinner";
import { logError } from "shared/services/ErrorReporting";
import { EmptyList } from "components/EmptyList";
import { Generate } from "components/svg/icons/Generate";
import { SubjectItem } from "./subjectItem";
import { filterSubjects } from "./utils";
import { RegenerateSubjectsDialogContent } from "./regenerateSubjectsDialogContent";

interface SubjectsListProps {
  variant?: "database" | "generated";
}

export const SubjectsList = ({ variant = "generated" }: SubjectsListProps) => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();

  const { generateProductSubjects, errorGenerate } = useProductActions();

  const { generations, isLoadingRead } = useGenerations();

  const isGeneratingProduct =
    variant === "generated" &&
    generations.some(
      (generation) =>
        (generation.status === "REQUESTED" ||
          generation.status === "GENERATING") &&
        generation.productId === product?.id,
    );

  useErrorToast(errorGenerate ? t("thema.error.generate") : undefined);

  const handleGenerate = () => {
    if (!product) {
      logError("Product not found while generating thema");
      return;
    }
    generateProductSubjects(product.id);
  };

  const databaseSubjects = useMemo(
    () => filterSubjects(product?.subjects?.database || []),
    [product],
  );

  const generatedSubjects = useMemo(
    () => filterSubjects(product?.subjects?.generated || []),
    [product],
  );

  const finalSubjects = useMemo(
    () => filterSubjects(product?.subjects?.final || []),
    [product],
  );

  const isEmptyList =
    variant === "database"
      ? !databaseSubjects.length
      : !generatedSubjects.length;

  const selectedSubjects = useMemo(
    () => (variant === "database" ? databaseSubjects : generatedSubjects),
    [databaseSubjects, generatedSubjects, variant],
  );

  if (isLoadingRead) {
    <LoadingSpinner size="99px" />;
  }

  return (
    <Grid className="h-full">
      <GridHeader>
        <GridTitle>{t("thema.headers.subjects")}</GridTitle>
        <GridButtonContainer>
          {variant === "generated" &&
            !isGeneratingProduct &&
            selectedSubjects.length && <RegenerateSubjectsDialogContent />}
        </GridButtonContainer>
      </GridHeader>
      <GridBody className="h-full">
        <GridLoading className=" w-full h-full" loading={isGeneratingProduct}>
          <div className="relative w-80 ml-20 align-middle">
            <div className="absolute -left-64">
              <KeywordsLoading />
            </div>
            <p className="text-center text-sm font-medium">
              {t("thema.loading")}
            </p>
          </div>
        </GridLoading>
        {!isGeneratingProduct && !isEmptyList ? (
          <GridContent className="md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
            {selectedSubjects.map((subject) => (
              <SubjectItem
                key={subject.id}
                subject={subject}
                testId={`add-subject-${subject.id}-button`}
                isSelected={
                  finalSubjects?.some(
                    (item) => item.subjectCode === subject.subjectCode,
                  ) || false
                }
              />
            ))}
          </GridContent>
        ) : null}
        {!isGeneratingProduct && isEmptyList ? (
          <EmptyList
            variant={variant === "database" ? "vertical" : "horizontal"}
            onClick={handleGenerate}
            title={
              variant === "database"
                ? t("thema.sections.noSubjects.database.title")
                : t("thema.sections.noSubjects.generated.title")
            }
            description={
              variant === "database"
                ? t("thema.sections.noSubjects.database.description")
                : t("thema.sections.noSubjects.generated.description")
            }
            label={
              variant === "database"
                ? undefined
                : t("thema.sections.noSubjects.generated.generate")
            }
            icon={
              variant === "database" ? undefined : (
                <Generate className="w-5 h-5 mr-2" size={18} strokeWidth={3} />
              )
            }
          />
        ) : null}
      </GridBody>
    </Grid>
  );
};
