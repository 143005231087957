import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";

export enum PROJECT_TYPE {
  ISBNS = "isbns",
  GOOGLE_TRENDS = "google-trends",
  AMAZON_TRENDS = "amazon-trends",
}
export interface IProject {
  id?: string;
  type: PROJECT_TYPE;
  name: string;
  isbns: string[];
  createdAt: Timestamp;
  user: {
    uid: string;
    photoURL: string | null;
    displayName: string | null;
    email: string | null;
  };
  organisation: {
    id: string;
    name: string;
  };
  searchOptions: Record<string, string>;
}

const ProjectConverter = {
  toFirestore(project: IProject) {
    return project;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IProject {
    const documentData = snapshot.data();
    const project = {
      ...documentData,
      id: snapshot.id,
    } as IProject;
    return project;
  },
};

export const getProjectsCollection = () =>
  collection(db, "projects").withConverter(ProjectConverter);

type PaginationOptions = {
  pageSize: number;
  currentPage: number;
};

export const getPaginatedProjects = async (
  organisationId: string,
  userId: string | null,
  { pageSize, currentPage }: PaginationOptions,
) => {
  const baseQuery = query(
    getProjectsCollection(),
    where("organisation.id", "==", organisationId),
    ...(userId ? [where("user.uid", "==", userId)] : []),
    orderBy("createdAt", "desc"),
    limit(pageSize * (currentPage + 3)),
  );

  const snapshot = await getDocs(baseQuery);
  const currentPageProjects = snapshot.docs.map((document) => ({
    ...document.data(),
    id: document.id,
  })) as IProject[];
  return currentPageProjects;
};

export const getProject = async (projectId: string) => {
  const projectDoc = await getDoc(doc(getProjectsCollection(), projectId));
  if (!projectDoc.exists()) {
    throw new Error("Project not found");
  }
  return {
    ...projectDoc.data(),
    id: projectDoc.id,
  } as IProject;
};

export const addProject = async (project: IProject) =>
  addDoc(getProjectsCollection(), project);

export type UpdateProjectFields = Partial<{
  isbns: string[];
  name: string;
}>;

export const updateProject = async (
  projectId: string,
  projectFields: UpdateProjectFields,
) => {
  const projectRef = doc(getProjectsCollection(), projectId);
  await updateDoc(projectRef, projectFields);
};
