import { useState } from "react";

interface PopoverProps {
  isOpen: boolean;
  onToggle: () => void;
  data: any;
  setData: any;
}

export const usePopover = (): PopoverProps => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return { isOpen, onToggle, data, setData };
};
