import { Outlet, useParams } from "react-router-dom";
import { ProductProvider } from "contexts/ProductContext";

export function ProductLayout() {
  const { idProduct } = useParams();

  return (
    <ProductProvider id={idProduct!}>
      <Outlet />
    </ProductProvider>
  );
}
