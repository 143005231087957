import { useProduct } from "contexts/ProductContext";
import { FinalKeywords } from "./components/FinalKeywords/FinalKeywords";
import { GeneratedKeywords } from "./components/GeneratedKeywords/GeneratedKeywords";
import { DatabaseKeywords } from "./components/DatabaseKeywords";

export const KeywordsTab = () => {
  const { product } = useProduct();

  if (!product) {
    return;
  }

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col w-full">
        <div className="min-h-96">
          <GeneratedKeywords />
        </div>

        <div className="mt-10 min-h-40">
          <DatabaseKeywords />
        </div>
      </div>

      <div className="max-w-80 min-h-36 ">
        <FinalKeywords />
      </div>
    </div>
  );
};
