import { cn } from "utils";

interface ActivityIndicatorProps {
  show: boolean;
  className?: string;
}

export const ActivityIndicator = ({
  show,
  className,
}: ActivityIndicatorProps) => {
  if (!show) {
    return null;
  }
  return (
    <span
      className={cn(
        "animate-in absolute flex h-3 w-3 top-[35%] items-center justify-center",
        className,
      )}
    >
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75" />
      <span className="relative inline-flex rounded-full h-2 w-2 bg-primary-400" />
    </span>
  );
};
