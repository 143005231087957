import { useState } from "react";

interface DialogProps {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onOpenChange: (open: boolean) => void;
  data?: any;
  setData?: any;
  open(): void;
  close(): void;
}

export const useDialog = (id?: string): DialogProps => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return {
    id,
    isOpen,
    onOpen,
    onClose,
    data,
    setData,
    onOpenChange: setIsOpen,
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    },
  };
};
