import React from "react";
import { useTranslation } from "react-i18next";
import { HeroIllustrationImage } from "components/image/HeroIllustrationImage";
import { Button } from "components/button";
import { Icon } from "components/icon/Icon";

export type ErrorMessageProps = {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  error?: Error;
  buttonText?: string;
  icon?: React.ReactNode;
  onButtonClick: () => void;
};

export const ErrorMessageWithImage: React.FC<ErrorMessageProps> = ({
  title,
  message,
  error,
  buttonText,
  icon,
  onButtonClick,
}) => {
  const { t } = useTranslation(["general"]);

  return (
    <div className="flex-1 flex flex-col items-center justify-center text-primary-700">
      <HeroIllustrationImage />

      <p className="text-xl font-semibold text-center">{title}</p>
      <p className="text-l-regular mt-8 text-center">{message}</p>

      <div className="mt-4 flex items-center">
        <Icon name="arrow-big-left" size="large" />
        <p className="text-l font-semibold">{t("general:chaptrEmail")}</p>
      </div>

      {error && (
        <p className="text-l-regular mt-8 text-center">
          {t("general:errorDetails")}:
          <p className="text-l-regular text-system-error">{error.message}</p>
        </p>
      )}

      <Button
        icon={icon ? "arrow-left" : undefined}
        className="mt-8"
        label={buttonText ?? t("general:buttons.back")}
        onClick={onButtonClick}
        analyticsId="button_back_from_error_message"
        testId="error-message-back-button"
      />
    </div>
  );
};
