import { Table } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { IProduct, PROJECT_TYPE } from "integrations/firebase/collections";
import { useTrends } from "contexts/TrendsContext";
import { useState } from "react";
import { toast } from "utils/toast";
import { logError } from "shared/services/ErrorReporting";
import { ExportModal } from "components/ExportModal";
import { SaveAsProjectDialog } from "../dialogs";

interface PreHeaderProps {
  table: Table<IProduct>;
}

export const PreHeader = ({ table }: PreHeaderProps) => {
  const { t } = useTranslation(["selectedSearchTerms"]);
  const { currentTrendWithProducts } = useTrends();
  const { createProject } = useProjects();
  const [projectSaved, setProjectSaved] = useState("");

  const handleSaveProject = async (title: string) => {
    try {
      const project = (await createProject(
        title || currentTrendWithProducts?.term || "Untitled Project",
        table.getCoreRowModel().flatRows.map((row) => row.original.isbn),
        PROJECT_TYPE.GOOGLE_TRENDS,
      )) as string;
      setProjectSaved(project);
      toast.success(
        t("selectedSearchTerms:selectedBooks.toasts.info.createProjectDone"),
      );
    } catch (error) {
      logError(error);
      toast.error(
        t("selectedSearchTerms:selectedBooks.toasts.error.createProjectError"),
      );
    }
  };

  return (
    <div className="flex flex-row w-full justify-between">
      <div />
      <div className="flex flex-row items-end gap-4">
        <ExportModal
          products={table.getCoreRowModel().rows.map((row) => row.original)}
        />
        <SaveAsProjectDialog
          handleSaveProject={handleSaveProject}
          table={table}
          hasSelectionEnabled={false}
          projectSaved={projectSaved}
        />
      </div>
    </div>
  );
};
