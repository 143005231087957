import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

interface ProfilePictureProps {
  className?: string;
  name?: string | null;
  photoUrl?: string | null;
}

export function ProfilePicture({
  name,
  photoUrl,
  className,
}: ProfilePictureProps) {
  return (
    <Avatar className={className}>
      <AvatarImage src={photoUrl ?? ""} />
      <AvatarFallback className="uppercase font-semibold">
        {name?.[0]}
      </AvatarFallback>
    </Avatar>
  );
}
