import { FC } from "react";
import LoadingSpinner from "./LoadingSpinner";

export const GlobalLoading: FC = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        zIndex: 9999,
        userSelect: "none",
      }}
      data-testid="global-loading"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LoadingSpinner size="99px" />
      </div>
    </div>
  );
};
