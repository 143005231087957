import React from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import SmallCircle, {
  SmallCircleTypeColor,
} from "components/svg/icons/SmallCircle";

type CircleColorWarningCounterProps = {
  count: number;
  maxCount: number;
  endLabelWord: string;
  tooltipTileOk: string;
  tooltipDescriptionLine1Ok: string;
  tooltipDescriptionLine2Ok: string;
  tooltipTileWarning: string;
  tooltipDescriptionLine1Warning: string;
  tooltipDescriptionLine2Warning: string;
};

export const CircleColorWarningCounter: React.FC<
  CircleColorWarningCounterProps
> = ({
  count,
  maxCount,
  endLabelWord,
  tooltipTileOk,
  tooltipDescriptionLine1Ok,
  tooltipDescriptionLine2Ok,
  tooltipTileWarning,
  tooltipDescriptionLine1Warning,
  tooltipDescriptionLine2Warning,
}) => {
  const type =
    count <= maxCount ? SmallCircleTypeColor.Ok : SmallCircleTypeColor.Warning;
  const tooltipTile =
    type === SmallCircleTypeColor.Ok ? tooltipTileOk : tooltipTileWarning;
  const tooltipDescriptionLine1 =
    type === SmallCircleTypeColor.Ok
      ? tooltipDescriptionLine1Ok
      : tooltipDescriptionLine1Warning;
  const tooltipDescriptionLine2 =
    type === SmallCircleTypeColor.Ok
      ? tooltipDescriptionLine2Ok
      : tooltipDescriptionLine2Warning;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipContent>
          <div
            className="flex flex-col items-start"
            data-testid="counter-tooltip"
          >
            <p className="mb-3 text-secondary-500 text-xs font-semibold">
              {tooltipTile}
            </p>
            <p className="text-xs text-secondary-500">
              {tooltipDescriptionLine1}
            </p>
            <p className="text-xs text-secondary-500">
              {tooltipDescriptionLine2}
            </p>
          </div>
        </TooltipContent>
        <TooltipTrigger>
          <div
            className="cursor-pointer flex flex-row items-center"
            data-testid="label-count-warning"
          >
            <SmallCircle type={type} />
            <p className="text-xs font-medium text-secondary-400 ml-1">
              {count}/{maxCount} {endLabelWord}
            </p>
          </div>
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
};
