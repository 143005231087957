import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import type { Active, UniqueIdentifier } from "@dnd-kit/core";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Separator } from "components/ui/separator";

import { SortableItem } from "./SortableItem";
import { SortableOverlay } from "./SortableOverlay";

export type BaseItem = {
  id: UniqueIdentifier;
  value: string;
  description?: string;
};

type SortableListProps = {
  items: BaseItem[];
  onChange: (items: BaseItem[]) => void;
  onRemove: (item: BaseItem | undefined) => void;
};

export const SortableList: FC<SortableListProps> = ({
  items: baseItems,
  onChange,
  onRemove,
}) => {
  const [items, setItems] = useState(baseItems);

  useEffect(() => {
    setItems(baseItems);
  }, [baseItems]);

  const [activeElement, setActiveElement] = useState<Active>();
  const activeItem = useMemo(
    () => items.find((item) => item.id === activeElement?.id),
    [activeElement, items],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleRemoveItem = useCallback(
    async (id: UniqueIdentifier) => {
      onRemove(items.find((item) => item.id === id));
      onChange(items.filter((item) => item.id !== id));
    },
    [items, onChange, onRemove],
  );

  const handleChangeItem = useCallback(
    async (id: UniqueIdentifier, value: string) => {
      onChange(
        items.map((item) => {
          if (item.id !== id) {
            return item;
          }

          return {
            ...item,
            value,
          };
        }),
      );
    },
    [items, onChange],
  );

  const renderItem = (index: number, item: BaseItem) => {
    return (
      <SortableItem
        index={index}
        {...item}
        onRemove={() => handleRemoveItem(item.id)}
        onChange={(value) => handleChangeItem(item.id, value)}
      />
    );
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => setActiveElement(active)}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          const updated = arrayMove(items, activeIndex, overIndex);

          setItems(updated);

          // Wait until dragging animation has finished before saving
          setTimeout(() => onChange(updated), 100);
        }

        setActiveElement(undefined);
      }}
      onDragCancel={() => setActiveElement(undefined)}
    >
      <SortableContext items={items}>
        <ul
          className="flex w-full flex-col m-0 p-0 list-none items-start justify-start"
          data-testid="sortable-list"
        >
          {items.map((item, index) => (
            <Fragment key={item.id}>
              {renderItem(index, item)}
              <Separator />
            </Fragment>
          ))}
        </ul>
      </SortableContext>

      <SortableOverlay>
        {activeItem && renderItem(-1, activeItem)}
      </SortableOverlay>
    </DndContext>
  );
};
