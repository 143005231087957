import React, { useEffect } from "react";
import { auth } from "integrations/firebase/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSignInWithEmailLink } from "react-firebase-hooks/auth";
import { toast } from "utils/toast";
import { useAnalytics } from "contexts/AnalyticsContext";
import { Loader } from "components/loading/Loader";
import { Header } from "components/layout";
import { LoginLinkError } from "./components/LoginLinkError";

export function LoginLink() {
  const navigate = useNavigate();
  const { gaEvent } = useAnalytics();
  const { t } = useTranslation("login");

  const [signInWithEmailLink, user, , error] = useSignInWithEmailLink(auth);

  useEffect(() => {
    const email = window.localStorage.getItem("emailForSignIn");

    signInWithEmailLink(email!).then();
  }, [signInWithEmailLink]);

  useEffect(() => {
    if (!user) {
      return;
    }

    gaEvent({ type: "login_request", payload: { method: "link" } });

    toast.success(t("login:success.toast.title"));

    navigate("/");
  }, [user, navigate, gaEvent, t]);

  if (error) {
    return <LoginLinkError error={error} />;
  }

  return (
    <div className="flex flex-col space-y-8 h-full items-center justify-center">
      <Loader />
      <div className="font-semibold text-2xl">{t("login:link.loading")}</div>
    </div>
  );
}

export function LoginLinkPage() {
  return (
    <div className="h-full flex w-full flex-col">
      <Header className="fixed" />

      <div className="flex-1">
        <LoginLink />
      </div>
    </div>
  );
}
