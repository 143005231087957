import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "components/ui/select";
import { FilterType } from "./keywordFilterTypes";

interface IKeywordDropdownProps {
  setFilterComparisonOperator: (value: FilterType) => void;
  filterComparisonOperator: FilterType;
}

export const KeywordDropdown = ({
  setFilterComparisonOperator,
  filterComparisonOperator,
}: IKeywordDropdownProps) => {
  const { t } = useTranslation(["books"]);
  const comparisonTypeTranslations = useMemo(
    () => ({
      [FilterType.GREATER]: t("books:filter.keywordTypes.greater"),
      [FilterType.LESS]: t("books:filter.keywordTypes.less"),
      [FilterType.EQUALS]: t("books:filter.keywordTypes.equals"),
    }),
    [t],
  );
  return (
    <span className="whitespace-nowrap">
      <Select
        value={filterComparisonOperator}
        onValueChange={(v) => setFilterComparisonOperator(v as FilterType)}
      >
        <SelectTrigger className="h-full border-secondary-300 rounded-e-none bg-secondary-50 min-w-32">
          <div className="w-full text-s">
            <p>{comparisonTypeTranslations[filterComparisonOperator]}</p>
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {Object.entries(FilterType).map(([key, value]) => (
              <SelectItem
                key={key}
                value={value}
                analytics-id="button_keyword_filter_type"
                className="text-s"
              >
                {comparisonTypeTranslations[value]}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </span>
  );
};
