import { useMemo } from "react";
import { ButtonCopyTextToClipboard } from "components/button/ButtonCopyTextToClipboard";
import { useProduct } from "contexts/ProductContext";
import { useTranslation } from "react-i18next";
import { useBookDescription } from "contexts/BookDescriptionContext";
import { DatabaseBookDescriptionEmpty } from "./DatabaseBookDescriptionEmpty";
import { Editor } from "../Editor";

export const DatabaseDescription = () => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();
  const bookDescription = product?.summary;
  const { handleCopyDoneDatabaseBookDescription } = useBookDescription();

  const keywords = useMemo(
    () => ({
      generated: product?.generated?.keywords?.data,
    }),
    [product?.generated?.keywords],
  );

  return (
    <div className="flex flex-col w-full rounded-t-xl overflow-hidden">
      <div className="flex items-center justify-between h-12 pl-5 pr-3 bg-secondary-50">
        <p className="font-medium">
          {t(
            "productDetails:bookDescription.tabDatabase.databaseBookDescription",
          )}
        </p>

        {bookDescription && (
          <ButtonCopyTextToClipboard
            textToCopy={bookDescription}
            tooltipText={t("general:tooltips.copyToClipboard")}
            onDone={handleCopyDoneDatabaseBookDescription}
            buttonText={`${t("general:buttons.copy")}`}
          />
        )}
      </div>

      <div className="border rounded-b-xl flex-1">
        {bookDescription && (
          <Editor
            key={product?.id}
            initialValue={bookDescription}
            disabled
            keywords={keywords}
          />
        )}
        {!bookDescription && <DatabaseBookDescriptionEmpty />}
      </div>
    </div>
  );
};
