import { useProducts } from "hooks/useProducts";
import { useProject } from "hooks/useProject";
import {
  Generation,
  IProduct,
  IProject,
  PROJECT_TYPE,
} from "integrations/firebase/collections";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { useAnalytics } from "contexts/AnalyticsContext";
import { useProductActions } from "hooks/useProductActions";
import { useGenerations } from "hooks/useGenerations";
import { logError } from "shared/services/ErrorReporting";
import { useProjectDetailsError } from "pages/ProjectDetails/hooks/useProjectDetailsError";

interface IProjectDetailsContext {
  exportModalOpen: boolean;
  setExportModalOpen: Dispatch<SetStateAction<boolean>>;
  productsToExport: IProduct[];
  setProductsToExport: (prodToExport: IProduct[]) => void;
  handleBack: () => void;
  isTrendProject: boolean | undefined;
  onRowClickCallback: (row: IProduct) => void;
  isLoadingRead: boolean;
  isLoadingUpdate: boolean;
  errorRead: Error | undefined;
  errorUpdate: Error | undefined;
  updateProjectName: (idProject: string, name: string) => void;
  products: IProduct[] | undefined;
  generateAllProductsKeywords: () => Promise<void>;
  generateAllProductsDescriptions: () => Promise<void>;
  isLoadingGenerate: boolean;
  error: { message: string; title: string } | undefined;
  resetError: () => void;
  isLoadingProducts: boolean;
  errorProductsRead: Error | undefined;
  errorProjectRead: string | undefined;
  project: IProject | undefined;
  idProject: string;
  product: IProduct | null;
  generations: Generation[];
  isLoadingGenerations: boolean;
}

export const ProjectDetailsContext = createContext<IProjectDetailsContext>(
  undefined as never,
);

export const useProjectDetails = () => useContext(ProjectDetailsContext);

export const ProjectDetailsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [product, setProduct] = useState<IProduct | null>(null);
  const { gaEvent } = useAnalytics();
  const { idProject = "0", idProduct = "0" } = useParams();
  const [productsToExport, setProductsToExport] = useState([] as IProduct[]);

  const navigate = useNavigate();

  const {
    project,
    isLoadingRead,
    isLoadingUpdate,
    errorProjectRead,
    errorRead,
    errorUpdate,
    updateProjectName,
  } = useProject(idProject);

  const {
    products,
    isLoadingRead: isLoadingProducts,
    errorRead: errorProductsRead,
  } = useProducts(project?.isbns);

  const {
    generations,
    isLoadingRead: isLoadingGenerations,
    errorRead: errorGenerationsRead,
  } = useGenerations();

  const {
    generateKeywords,
    generateDescriptions,
    isLoadingGenerate,
    errorGenerate,
  } = useProductActions();

  const { error, resetError } = useProjectDetailsError({
    errorProductsRead,
    errorGenerate,
    errorUpdate,
  });

  useEffect(() => {
    const paramProduct =
      products?.find((prod) => prod.id === idProduct) ?? null;
    if (!paramProduct) {
      setProduct(null);
    }
    if (product?.isbn !== paramProduct?.isbn) {
      setProduct(paramProduct);
    }
  }, [idProduct, product?.isbn, products]);

  const handleBack = useCallback(() => {
    navigate(RoutePath.Projects);
    gaEvent({
      type: "view_item_list",
      payload: {
        item_list_name: "projects",
        item_list_id: project?.id,
        item_list_category: project?.type,
      },
    });
  }, [gaEvent, navigate, project?.id, project?.type]);

  const isTrendProject =
    project?.type &&
    project?.type in [PROJECT_TYPE.GOOGLE_TRENDS, PROJECT_TYPE.AMAZON_TRENDS];

  const onRowClickCallback = useCallback(
    (row: IProduct) => {
      if (!idProject) {
        return;
      }

      navigate(
        RoutePath.ProductDetails.replace(":idProject", idProject).replace(
          ":idProduct",
          row.id,
        ),
      );
      gaEvent({
        type: "view_item",
        payload: {
          items: [
            {
              item_id: row.id,
              item_name: row.title,
              item_category: "product",
              author: row.author,
              isbn: row.isbn,
              title: row.title,
              publisher: row.publisher,
              genre: row.genre,
              productGroupDescription: row.productGroupDescription,
              keywords: row.keywords,
              generated: row.generated,
              finalKeywords: row.finalKeywords,
              createdAt: row.createdAt,
              updatedAt: row.updatedAt,
              publishedAt: row.publishedAt,
              summary: row.summary,
            },
          ],
        },
      });
    },
    [gaEvent, idProject, navigate],
  );

  const changeExports = useCallback(
    (prodToExport: IProduct[]) => setProductsToExport(prodToExport),
    [],
  );

  const generateAllProductsKeywords = useCallback(async () => {
    if (!products) {
      logError("Products not found while generating keywords");
      return;
    }

    return generateKeywords(products);
  }, [generateKeywords, products]);

  const generateAllProductsDescriptions = useCallback(async () => {
    if (!products?.length) {
      logError("Products not found while generating descriptions");
      return;
    }

    return generateDescriptions(products);
  }, [generateDescriptions, products]);

  const contextValue: IProjectDetailsContext = useMemo(
    () => ({
      exportModalOpen,
      setExportModalOpen,
      productsToExport,
      setProductsToExport: changeExports,
      handleBack,
      isTrendProject,
      onRowClickCallback,
      isLoadingRead,
      isLoadingUpdate,
      errorRead,
      errorUpdate,
      updateProjectName,
      products,
      generateAllProductsKeywords,
      generateAllProductsDescriptions,
      isLoadingGenerate,
      error,
      resetError,
      isLoadingProducts,
      errorProductsRead,
      errorProjectRead,
      project,
      idProject,
      product,
      generations,
      isLoadingGenerations,
      errorGenerationsRead,
    }),
    [
      exportModalOpen,
      productsToExport,
      changeExports,
      handleBack,
      isTrendProject,
      onRowClickCallback,
      isLoadingRead,
      isLoadingUpdate,
      errorRead,
      errorUpdate,
      updateProjectName,
      products,
      generateAllProductsKeywords,
      generateAllProductsDescriptions,
      isLoadingGenerate,
      error,
      resetError,
      isLoadingProducts,
      errorProjectRead,
      errorProductsRead,
      project,
      idProject,
      product,
      generations,
      isLoadingGenerations,
      errorGenerationsRead,
    ],
  );

  return (
    <ProjectDetailsContext.Provider value={contextValue}>
      {children}
    </ProjectDetailsContext.Provider>
  );
};
