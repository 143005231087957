import { Button } from "components/button";
import { SkeletonKeyword } from "components/skeletons/SkeletonKeyword";
import { EmptyListProps } from "./EmptyListTypes";

export const EmptyListVertical: React.FC<EmptyListProps> = ({
  onClick,
  title,
  description,
  label,
  icon,
  errorMessage,
}) => {
  const showButton = label || icon;
  return (
    <div className="relative h-full w-full items-center self-center flex flex-col justify-center gap-2 my-2">
      <div className="flex justify-center flex-col">
        <SkeletonKeyword
          animated={false}
          className="scale-75 relative animate-none opacity-60"
        />
        <SkeletonKeyword
          animated={false}
          primary
          className="scale-75 relative animate-none"
        />
        <SkeletonKeyword
          animated={false}
          className="scale-75 relative animate-none opacity-60"
        />
      </div>
      <div className="flex flex-col my-4 gap-2 items-start max-w-64">
        <p className="font-medium text-m text-left text-secondary-900">
          {title}
        </p>
        <p className="text-s font-normal text-secondary-500">{description}</p>
        {errorMessage && (
          <p className="text-s font-normal text-red-400">{errorMessage}</p>
        )}

        {showButton && (
          <Button
            label={
              <div className="flex flex-row items-center">
                {icon}
                <p className="text-s">{label}</p>
              </div>
            }
            onClick={onClick}
            size="small"
            analyticsId="button_regenerate_keywords"
          />
        )}
      </div>
    </div>
  );
};
