import Google from "components/svg/icons/Google";
import Amazon from "components/svg/icons/Amazon";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTrends } from "contexts/TrendsContext";
import { TRENDS_TYPE } from "integrations/firebase/collections";
import { capitalizeFirstLetter } from "utils/helpers";

const icons = {
  [TRENDS_TYPE.GOOGLE]: <Google />,
  [TRENDS_TYPE.AMAZON]: <Amazon />,
};
export const SourceSelect = () => {
  const { trendTypes, setSelectedTrendsType, selectedTrendsType } = useTrends();

  const onValueChange = (value: TRENDS_TYPE) => {
    setSelectedTrendsType(value);
  };

  return (
    <Select onValueChange={onValueChange} value={selectedTrendsType}>
      <SelectTrigger testId="trends-type-select" className="w-[180px]">
        <SelectValue placeholder="Trend type" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {trendTypes.map((type) => (
            <SelectItem
              key={type}
              value={type}
              testId={`trend-menu-item-${type.toLowerCase()}`}
            >
              <div className="flex flex-row gap-2 items-center">
                {icons[type]}
                <p className="text-s">{capitalizeFirstLetter(type)}</p>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
