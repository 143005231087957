import { FC } from "react";

import LoadingSpinner from "./LoadingSpinner";

interface OverlayLoadingSpinnerProps {
  isLoading?: boolean;
  textTop?: string;
}

const ModalOverlayLoadingSpinner: FC<OverlayLoadingSpinnerProps> = ({
  isLoading,
  textTop,
}) => {
  return isLoading ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
        zIndex: 9999,
        userSelect: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {textTop && (
          <p className="font-semibold mt-5 text-secondary-700">{textTop}</p>
        )}
        <LoadingSpinner size="99px" />
      </div>
    </div>
  ) : null;
};

export default ModalOverlayLoadingSpinner;
