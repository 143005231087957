import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useProject } from "hooks/useProject";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Button } from "components/button";
import { Icon } from "components/icon/Icon";
import { useDialog } from "hooks/useDialog";
import { transformMultipleIsbn } from "shared/services/IsbnService";
import { toast } from "utils/toast";
import { ProjectForm } from "./ProjectForm";

export const ProjectEditISBNDialog: React.FC = () => {
  const { t } = useTranslation(["general", "projectDetails"]);
  const { idProject = "0" } = useParams();
  const { isOpen, onClose, onOpen } = useDialog();
  const { project, isLoadingRead, errorRead, errorUpdate, updateProjectIsbns } =
    useProject(idProject);

  const [selectedIsbns, setSelectedIsbns] = useState<string[]>(
    project?.isbns ? transformMultipleIsbn(project.isbns) : [],
  );

  const filterIsbnsOnDialogueClose = useCallback(() => {
    const duplicatesRemoved = Array.from(new Set(selectedIsbns));
    setSelectedIsbns(duplicatesRemoved);
    return duplicatesRemoved;
  }, [selectedIsbns]);

  useEffect(() => {
    setSelectedIsbns(
      project?.isbns ? transformMultipleIsbn(project.isbns) : [],
    );
  }, [project]);

  const handleSave = useCallback(() => {
    if (!idProject) {
      return;
    }
    const isbns = filterIsbnsOnDialogueClose();
    updateProjectIsbns(idProject, isbns);
    onClose();
  }, [idProject, updateProjectIsbns, onClose, filterIsbnsOnDialogueClose]);

  const getErrorText = useCallback(() => {
    if (errorRead) {
      return `${t("projectDetails:errorToast.errorReadProject")}: ${
        errorRead.message
      }`;
    }
    if (errorUpdate) {
      return `${t("projectDetails:errorToast.errorUpdateProject")}: ${
        errorUpdate.message
      }`;
    }
  }, [errorRead, errorUpdate, t]);

  useEffect(() => {
    if (errorRead || errorUpdate) {
      toast.error(getErrorText() || "");
    }
  }, [errorRead, errorUpdate, getErrorText, onClose]);

  const onDialogueOpenChanged = (isDialogueOpen: boolean) => {
    if (!isDialogueOpen) {
      onClose();
      filterIsbnsOnDialogueClose();
    }
  };

  return (
    <Dialog
      onOpenChange={onDialogueOpenChanged}
      open={isOpen}
      modal
      defaultOpen={false}
    >
      <DialogTrigger asChild>
        <Button
          variant="tertiary"
          icon="square-pen"
          spanClassName="text-s text-secondary-600"
          onClick={onOpen}
          label={t("projectDetails:buttons.editBooks")}
        />
      </DialogTrigger>
      <DialogContent className="max-w-[90%]">
        <DialogHeader>
          <DialogTitle className="text-left w-full mb-0">
            {t("projectDetails:editISBN.title")}
          </DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <ProjectForm
          selectedIsbns={selectedIsbns}
          setSelectedIsbns={setSelectedIsbns}
          loading={isLoadingRead}
        />
        <DialogFixedFooter>
          <div className="flex flex-row justify-between w-full items-center pl-2">
            <span className="text-secondary-500 text-s flex-row flex items-center gap-1">
              <Icon name="info" size="small" />
              <p>{t("projectDetails:editISBN.info")}</p>
            </span>

            <Button
              size="small"
              label={t("general:buttons.save")}
              onClick={handleSave}
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
