import { useTranslation } from "react-i18next";
import {
  Grid,
  GridBody,
  GridContent,
  GridHeader,
  GridTitle,
} from "components/ui/grid";
import { useProduct } from "contexts/ProductContext";
import { useMemo } from "react";
import { KeywordItem } from "../KeywordItem";
import { DatabaseKeywordsEmpty } from "./DatabaseKeywordsEmpty";

export const DatabaseKeywords = () => {
  const { t } = useTranslation(["general", "productDetails"]);

  const { product } = useProduct();

  const { finalKeywords, databaseKeywords } = useMemo(
    () => ({
      finalKeywords: product?.finalKeywords ?? [],
      databaseKeywords: product?.keywords ?? [],
    }),
    [product],
  );

  return (
    <Grid>
      <GridHeader>
        <GridTitle>{t("productDetails:databaseKeywords.header")}</GridTitle>
      </GridHeader>
      <GridBody>
        <GridContent>
          {databaseKeywords.map((keyword) => (
            <KeywordItem
              key={keyword}
              keyword={keyword}
              testId="database-keyword"
              isSelected={finalKeywords.includes(keyword)}
            />
          ))}
        </GridContent>

        {!databaseKeywords.length && (
          <div className="w-full flex justify-center">
            <DatabaseKeywordsEmpty />
          </div>
        )}
      </GridBody>
    </Grid>
  );
};
