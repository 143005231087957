import {
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
} from "firebase/firestore";
import { app } from "./app";

export const db: Firestore = getFirestore(app);

const useEmulator = import.meta.env.VITE_USE_FIREBASE_EMULATOR === "true";
if (useEmulator) {
  connectFirestoreEmulator(
    db,
    `${import.meta.env.VITE_FIREBASE_EMULATOR_HOST}`,
    parseInt(import.meta.env.VITE_FIREBASE_EMULATOR_FIRESTORE_PORT, 10),
  );
}
