/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import ReedyLogo from "assets/svg/reedy-logo.svg?react";
import { cn } from "../../utils";

interface HeaderProps extends PropsWithChildren {
  className?: string;
}

export function Header({ className, children }: HeaderProps) {
  return (
    <header
      className={cn(
        "sticky top-0 left-0 right-0 shadow-header p-2 bg-white z-[60] pointer-events-auto",
        className,
      )}
    >
      <div className="flex items-center justify-between">
        <Link className="cursor-pointer" to="/">
          <ReedyLogo />
        </Link>
        {children}
      </div>
    </header>
  );
}
